import cx from 'classnames'
import get from 'lodash/get'
import { useFormContext } from 'react-hook-form'
import { Input } from 'reactstrap'
import ErrorMessage from 'components/ErrorMessage'
import styles from './styles.module.scss'

interface IUploaderProps {
  className?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Uploader = (props: IUploaderProps) => {
  const { className, onChange } = props
  const { register, watch, errors } = useFormContext()

  const profilePictureUrl = watch('profilePictureUrl')
  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.wrapper}>
        <Input
          type="file"
          name="profilePicture"
          accept="image/png, image/jpeg"
          multiple={false}
          onChange={onChange}
          innerRef={register({
            // eslint-disable-next-line consistent-return
            validate: (value) => {
              if (!profilePictureUrl) {
                return value.length > 0
              }
            },
          })}
        />
      </div>
      {get(errors, 'profilePicture', null) && <ErrorMessage error={'Picture is empty'} />}
    </div>
  )
}

export default Uploader
