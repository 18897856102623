import Tabs, { ITabContent, ITabHeader } from 'components/Tabs'
import ExclusiveSpecial from './components/ExclusiveSpecial'
import WeeklySpecial from './components/WeeklySpecial'
import styles from './specialTabs.module.scss'

const SpecialTabs = () => {
  const headers: ITabHeader[] = [
    {
      label: 'EXCLUSIVE SPECIALS',
      tabId: 'exclusive-specials',
    },
    {
      label: 'WEEKLY SPECIALS',
      tabId: 'weekly-specials',
    },
  ]

  const contents: ITabContent[] = [
    {
      tabId: 'exclusive-specials',
      content: <ExclusiveSpecial />,
    },
    {
      tabId: 'weekly-specials',
      content: <WeeklySpecial />,
    },
  ]

  return (
    <div className={styles.container}>
      <Tabs headers={headers} contents={contents} />
    </div>
  )
}

export default SpecialTabs
