import get from 'lodash/get'
import { useFormContext } from 'react-hook-form'
import { Col, Row } from 'reactstrap'
import ErrorMessage from 'components/ErrorMessage'
import InputRadio from 'components/InputRadio'
import { PriceType } from 'constants/enums/special'

interface IPriceTypeSelectionProps {
  isEditing: boolean
}

const PriceTypeSelection = ({ isEditing }: IPriceTypeSelectionProps) => {
  const { register, errors } = useFormContext()
  return (
    <>
      <Row>
        <Col md={3}>
          <InputRadio
            disabled={isEditing}
            label="$"
            name="priceType"
            value={`${PriceType.NORMAL}`}
            innerRef={register}
          />
        </Col>
        <Col md={3}>
          <InputRadio
            label="% Off"
            name="priceType"
            disabled={isEditing}
            value={`${PriceType.OFF}`}
            innerRef={register({ required: 'This field is required' })}
          />
        </Col>
        <Col md={3}>
          <InputRadio
            label="BOGO"
            disabled={isEditing}
            name="priceType"
            value={`${PriceType.BOGO}`}
            innerRef={register}
          />
        </Col>
        <Col md={3}>
          <InputRadio
            label="Free"
            disabled={isEditing}
            name="priceType"
            value={`${PriceType.FREE}`}
            innerRef={register}
          />
        </Col>
      </Row>
      <ErrorMessage error={get(errors, 'priceType.message', '') as string} />
    </>
  )
}

export default PriceTypeSelection
