import get from 'lodash/get'
import { useFormContext } from 'react-hook-form'
import { Col, Row } from 'reactstrap'
import ErrorMessage from 'components/ErrorMessage'
import InputCheckBox from 'components/InputCheckbox'
import Title from 'components/Title'
import { CategoryType } from 'constants/enums/special'

const TypeSelection = () => {
  const { register, errors } = useFormContext()
  return (
    <Row>
      <Col md={12}>
        <Title smallMargin isRequired small>
          Dine-in and/or Takeout
        </Title>
        <Row>
          <Col md={3}>
            <InputCheckBox name="type" label="Dine-in" value={`${CategoryType.DINEIN}`} innerRef={register} />
          </Col>
          <Col md={3}>
            <InputCheckBox name="type" label="Takeout" value={`${CategoryType.TAKEOUT}`} innerRef={register} />
          </Col>
        </Row>
        <ErrorMessage error={get(errors, 'type.message', '')} />
      </Col>
    </Row>
  )
}

export default TypeSelection
