import AnalyticsStore from './analyticsStore'
import AuthStore from './authStore'
import BusinessInfoStore from './businessInfoStore'
import CampaignStore from './campaignStore'
import CommonStore from './commonStore'
import EstablishmentStore from './establishmentStore'
import EventStore from './eventStore'
import MyAccountStore from './myAccountStore'
import SpecialStore from './specialStore'
import SpinnerStore from './spinnerStore'

export class RootStore {
  spinnerStore: SpinnerStore
  authStore: AuthStore
  analyticsStore: AnalyticsStore
  specialStore: SpecialStore
  businessInfoStore: BusinessInfoStore
  myAccountStore: MyAccountStore
  establishmentStore: EstablishmentStore
  eventStore: EventStore
  commonStore: CommonStore
  campaignStore: CampaignStore

  constructor() {
    this.spinnerStore = new SpinnerStore(this)
    this.authStore = new AuthStore(this)
    this.analyticsStore = new AnalyticsStore(this)
    this.specialStore = new SpecialStore(this)
    this.businessInfoStore = new BusinessInfoStore(this)
    this.myAccountStore = new MyAccountStore(this)
    this.establishmentStore = new EstablishmentStore(this)
    this.eventStore = new EventStore(this)
    this.commonStore = new CommonStore(this)
    this.campaignStore = new CampaignStore(this)
  }
}

export const rootStore = new RootStore()
