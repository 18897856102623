export enum MyAccountSectionsEnum {
  ACCOUNT_SETTING = 'Account Setting',
  CHANGE_PASSWORD = 'Change Password',
  ACCOUNT_MANAGERS = 'Account Managers',
}

export enum ErrorType {
  IS_EXISTED = 'isExisted',
  MAX_LENGTH = 'maxLength',
  MIN_LENGTH = 'minLength',
  PATTERN = 'pattern',
}
