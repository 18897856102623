import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import Table from 'components/Table'
import { getHeaderList } from './utils'
import styles from './managerTable.module.scss'

interface IExpandableCellProps {
  row: any
  value: unknown
}

const ExpandableCell = (props: IExpandableCellProps) => {
  const { row, value } = props

  return (
    <span {...row.getToggleRowExpandedProps()}>
      {typeof value !== 'undefined' ? (
        value
      ) : (
        <></>
        // INFO: enable later
        // <Icon
        //   className={styles.iconActions}
        //   icon="trash-alt"
        //
        //   onClick={onDeleteManagerClick}
        // />
      )}
    </span>
  )
}

const ManagerTable = () => {
  const { myAccountStore } = useStore()
  const { addManager } = myAccountStore
  console.log(addManager)
  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <Table headerList={getHeaderList(ExpandableCell)} tableData={addManager} hasNoSort={false} />
      </div>
    </div>
  )
}

export default observer(ManagerTable)
