export interface IOption {
  id?: string
  name?: string
  label?: string
  value?: any
}

export const mapToOptions = (array: Array<any>): Array<IOption> => {
  return array.map((value) => {
    return {
      label: value.name,
      value: value.establishment_id,
    }
  })
}
