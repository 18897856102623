import { useState } from 'react'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Row as ReactTableRow } from 'react-table'
import { Row, Col } from 'reactstrap'
import { deleteEvent } from 'Api/event'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import Icon from 'components/Icon'
import Table from 'components/Table'
import Title from 'components/Title'
import { getValidArray } from 'utils/common'
import DateTimeBlock from '../../../DateTimeBlock'
import SpecialList from '../../../SpecialList'
import { getHeaderList } from '../../../TodayEvent/constant'
import CreateEventModal from '../CreateOneTimeEventModal'
import ReviewEventModal from '../ReviewOneTimeEventModal'
import styles from './oneTimeEvent.module.scss'

interface ExpandableCellProps {
  row: any
  value: unknown
}

const ExpandableCell = (props: ExpandableCellProps) => {
  const { row, value } = props
  return <span {...row.getToggleRowExpandedProps()}>{value}</span>
}

const OneTimeEvent = () => {
  const [isOpening, setIsOpening] = useState(false)
  const [isReviewing, setIsReviewing] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [targetEventId, setTargetEventId] = useState(0)
  const { eventStore } = useStore()
  const { oneTimeEvents } = eventStore

  const dataInTable = getValidArray(oneTimeEvents).map((event) => ({
    ...event,
    title: <div>{event.title}</div>,
    detail: <div>{event.details}</div>,
    specialCount: <div>{event?.specials?.length ?? 0}</div>,
    time: <DateTimeBlock startTime={event?.start_time} endTime={event?.end_time} day={-1} />,
    actions: (
      <div className={styles.column}>
        <Icon onClick={() => handleEdit(event?.event_id ?? 0)} icon="pen" className={styles.editIcon} />
        <Icon
          icon="trash-alt"
          className={styles.trashIcon}
          onClick={() => {
            setTargetEventId(event?.event_id ?? 0)
            toggleModal()
          }}
        />
      </div>
    ),
  }))

  function handleReview(): void {
    setIsReviewing(true)
    setIsOpening(false)
  }

  async function handleEdit(eventId: number) {
    await eventStore.fetchEventDetail(eventId)
    setIsReviewing(false)
    setIsOpening(true)
  }

  function toggleReview(): void {
    setIsReviewing(!isReviewing)
  }

  function toggleEdit(): void {
    setIsOpening(!isOpening)
  }

  function toggleModal() {
    isOpenModal && eventStore.unsetEditingEvents()
    setIsOpenModal(!isOpenModal)
  }

  function handleCreate(): void {
    eventStore.unsetEditingEvents()
    setIsOpening(true)
  }

  async function handleAccept() {
    await deleteEvent(targetEventId)
    toggleModal()
    await eventStore.rootStore.establishmentStore.fetchEstablishmentEvent()
    eventStore.unsetEditingEvents()
  }

  return (
    <Row className={styles.container}>
      <Col md={12} className={styles.tableContainer}>
        <div className={styles.warning}>Limit 1 event per week</div>
        <div className={styles.controlSection}>
          <div className={styles.buttonSection}>
            <Button onClick={handleCreate} color="primary">
              <Icon icon="plus" className={styles.iconStyle} />
              Create New
            </Button>
          </div>
        </div>
        <Table
          headerList={getHeaderList(ExpandableCell)}
          tableData={dataInTable}
          hasNoSort={false}
          hasDivWrapper
          isScrollAble
          subComponent={(row: ReactTableRow, index: number) => (
            <SpecialList specials={get(row, 'original.specials', [])} key={`onetime-${index}`} />
          )}
        />
        <Row>
          <Col md={12} className={styles.info}>
            <Title small>
              <Icon icon="info-circle" />
              Click table row to see more information
            </Title>
          </Col>
        </Row>
        <CreateEventModal isOpening={isOpening} toggleShow={toggleEdit} handleReview={handleReview} />
        {isReviewing && <ReviewEventModal isOpening={isReviewing} toggleShow={toggleReview} />}
        <ConfirmModal
          isOpen={isOpenModal}
          onClickClose={toggleModal}
          onClickCancel={toggleModal}
          onClickAccept={handleAccept}
        />
      </Col>
    </Row>
  )
}

export default observer(OneTimeEvent)
