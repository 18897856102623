import ResetPasswordPage from 'components/Pages/ResetPasswordPage'
import PublicLayout from 'components/PublicLayout'
import styles from './loginPage.module.scss'

const ResetPasswordLayout = () => {
  return (
    <PublicLayout>
      <div className={styles.container}>
        <ResetPasswordPage title="Reset Password" />
      </div>
    </PublicLayout>
  )
}

export default ResetPasswordLayout
