import cx from 'classnames'
import isNaN from 'lodash/isNaN'
import min from 'lodash/min'
import Icon, { IconGroupPrefix } from 'components/Icon'
import { IPagination } from 'components/Table'
import { truncatePagination } from './utils'
import styles from './pagination.module.scss'

export interface IPaginationProps {
  pagination: IPagination
}

export const LIMIT_PAGE_BREAK = 10

const Pagination = (props: IPaginationProps) => {
  const { pagination } = props
  const { gotoPage, pageIndex, tableLength } = pagination

  const startPointTable = LIMIT_PAGE_BREAK * (pageIndex - 1) + 1
  const endPointTable = min([LIMIT_PAGE_BREAK * pageIndex, tableLength])

  const numberOfPages = Math.ceil(tableLength / LIMIT_PAGE_BREAK)
  const truncatedPagination = truncatePagination(Number(pageIndex), Number(numberOfPages))

  return (
    <div className={styles.container}>
      <div className={styles.paginationInfo}>
        {tableLength === 0
          ? `display 0 of ${tableLength}`
          : `display ${startPointTable} - ${endPointTable} of ${tableLength}`}
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={cx(styles.button, {
            [styles.paginationArrowTextStyle]: true,
          })}
          onClick={() => gotoPage(1)}
          disabled={pageIndex === 1 ? true : false}
        >
          <Icon icon="angle-double-left" group={IconGroupPrefix.LAICON} />
        </button>
        <button
          className={cx(styles.button, {
            [styles.paginationArrowTextStyle]: true,
          })}
          onClick={() => gotoPage(pageIndex - 1)}
          disabled={pageIndex === 1 ? true : false}
        >
          <Icon icon="angle-left" group={IconGroupPrefix.LAICON} />
        </button>
        {Array.isArray(truncatedPagination) &&
          truncatedPagination.map((item: string | number, index: number) => {
            const isActive = pageIndex === item
            if (isNaN(item)) {
              return (
                <div className={styles.paginationTextStyle} key={index}>
                  {item}
                </div>
              )
            }
            return (
              <div
                className={cx(styles.button, {
                  [styles.buttonStyleActive]: isActive,
                })}
                key={`pagination-${index}`}
                onClick={() => gotoPage(Number(item))}
              >
                <span
                  className={cx(styles.text, {
                    [styles.textStyleActive]: isActive,
                  })}
                >
                  {item}
                </span>
              </div>
            )
          })}
        <button
          className={cx(styles.button, {
            [styles.paginationArrowTextStyle]: true,
          })}
          onClick={() => gotoPage(pageIndex + 1)}
          disabled={pageIndex === numberOfPages || tableLength === 0 ? true : false}
        >
          <Icon icon="angle-right" group={IconGroupPrefix.LAICON} />
        </button>
        <button
          className={cx(styles.button, {
            [styles.paginationArrowTextStyle]: true,
          })}
          onClick={() => gotoPage(numberOfPages)}
          disabled={pageIndex === numberOfPages || tableLength === 0 ? true : false}
        >
          <Icon icon="angle-double-right" group={IconGroupPrefix.LAICON} />
        </button>
      </div>
    </div>
  )
}

export default Pagination
