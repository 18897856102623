import MainLayout from 'components/MainLayout'
import MyAccountPage from 'components/Pages/MyAccountPage'
import styles from './myAccount.module.scss'

const MyAccountLayout = () => {
  return (
    <MainLayout>
      <div className={styles.container}>
        <MyAccountPage />
      </div>
    </MainLayout>
  )
}

export default MyAccountLayout
