import { makeObservable, observable, action, flow } from 'mobx'
import { RootStore } from 'stores'
import { analytics } from 'Api/analytics'
import { sortDate } from 'components/Pages/AnalyticsPage/utils'
import { IAnalyticsData, IDashboardData } from 'constants/interfaces'

export enum AuthenticateParams {
  ACCESS_TOKEN = 'accessToken',
}

export default class AnalyticsStore {
  rootStore: RootStore

  analyticsData: IAnalyticsData = {}
  dashboardData: IDashboardData = {}
  isLoading: boolean = false

  constructor(rootStore: RootStore) {
    //TODO: may need in some special case
    makeObservable(this, {
      analyticsData: observable,
      dashboardData: observable,
      isLoading: observable,
      getAnalyticsData: action,
      setDashboardData: action,
    })

    this.rootStore = rootStore
  }

  getAnalyticsData = flow(function* (this: AnalyticsStore, establishmentId: number, daysAgo: number) {
    if (establishmentId) {
      this.isLoading = true
      this.rootStore.spinnerStore.showLoading()
      const analyticsData = yield analytics(daysAgo)
      if (analyticsData) {
        this.analyticsData = analyticsData as IAnalyticsData
      }
      this.rootStore.spinnerStore.hideLoading()
    }
  })

  setDashboardData(dashboardData: IDashboardData): void {
    this.dashboardData = dashboardData

    this.dashboardData.eventData = sortDate(this.dashboardData.eventData)
    this.dashboardData.specialData = sortDate(this.dashboardData.specialData)
    this.dashboardData.favoriteData = sortDate(this.dashboardData.favoriteData)
    this.dashboardData.profileViewData = sortDate(this.dashboardData.profileViewData)
    this.isLoading = false
  }
}
