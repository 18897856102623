import { useEffect } from 'react'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import EventTabs from './components/EventTabs'
import TodayEvent from './components/TodayEvent'
import styles from './eventPage.module.scss'

const EventPage = () => {
  const { establishmentStore, authStore } = useStore()
  const { establishment } = authStore

  useEffect(() => {
    if (establishment) {
      establishmentStore.fetchEstablishmentEvent()
    }
  }, [establishment])

  return (
    <div className={styles.container}>
      {/* // *INFO: May change design <div className={styles.sectionTitle}>Event</div> */}
      <TodayEvent />
      <EventTabs />
    </div>
  )
}

export default observer(EventPage)
