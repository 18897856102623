import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { sendEmailForgotPassword } from 'Api/auth'
import ButtonWithIcon from 'components/ButtonWithIcon'
import { RoleType } from 'constants/enums/role'
import routes from 'routes'
import styles from './sendMailConfirmation.module.scss'

interface SendMailConfirmationProps {
  isConfirmed: boolean
  email: string
  setIsConfirmed: (isConfirm: boolean) => void
  type: RoleType
}

const ForgotPasswordPage = (props: SendMailConfirmationProps) => {
  const { isConfirmed, email, setIsConfirmed, type } = props
  const { authStore } = useStore()
  const { username } = authStore
  const history = useHistory()

  function backToLogin() {
    history.push({
      pathname: routes.login.value,
    })
  }

  async function sendEmailReset() {
    try {
      await sendEmailForgotPassword(username, type)
      setIsConfirmed(!isConfirmed)
    } catch (error) {
      toast.error('Something wrong happened')
    }
  }

  return (
    <>
      <div className={styles.paragraph}>
        Clicking the
        {` `} <b>Send Email</b> {` `}
        button below will send a message to
        {` `} <span className={styles.email}>{email}</span> {` `}
        with instructions to reset your password.
      </div>
      <div>
        <ButtonWithIcon content="Send Email" className={cx(styles.sendMailButton)} onClick={sendEmailReset} />
        <ButtonWithIcon content="Back" className={styles.backButton} onClick={backToLogin} />
      </div>
    </>
  )
}

export default ForgotPasswordPage
