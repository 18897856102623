import get from 'lodash/get'
import { Timezone } from 'constants/enums/timezone'
import { getDateBasedOnTimeZone } from 'utils/timezone'

export function getExclusiveSpecialOfToday(exclusiveSpecials: any, timezone: Timezone) {
  const currentThisWeek = get(exclusiveSpecials, '[0].current', [])
  const upcomingThisWeek = get(exclusiveSpecials, '[0].upcoming', [])
  const allSpecialsThisWeek = [...currentThisWeek, ...upcomingThisWeek]
  const todaySpecial = allSpecialsThisWeek.find(
    (special) => isToday(new Date(special.start_time), timezone) && isToday(new Date(special.end_time), timezone)
  )
  if (todaySpecial) {
    return [todaySpecial]
  }
  return []
}

function isToday(date: Date, timezone: Timezone) {
  const today = getDateBasedOnTimeZone(timezone)
  return today.getDate === date.getDate
}
