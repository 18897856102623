import cx from 'classnames'
import styles from './labelValueLine.module.scss'

export interface ILabelValueLineProps {
  label: string
  value: string
  hasSpaceTop?: boolean
  className?: string
  isNoColon?: boolean
  inline?: boolean
}

export const TABLET_SCREEN_SIZE = '1200px'

const LabelValueLine = (props: ILabelValueLineProps) => {
  const { label, value, hasSpaceTop, className, isNoColon, inline } = props
  return value ? (
    <div className={cx(styles.textWrapper, className, { [styles.spaceTop]: hasSpaceTop, [styles.inline]: inline })}>
      <span className={cx(styles.label)}>
        {label}
        {!isNoColon && ':'}
      </span>
      <span className={styles.value}>{` ${value}`}</span>
    </div>
  ) : (
    <></>
  )
}
export default LabelValueLine
