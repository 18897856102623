import cx from 'classnames'
import capitalize from 'lodash/capitalize'
import { Control, useWatch } from 'react-hook-form'
import { Col } from 'reactstrap'
import { getDealImage } from 'components/Pages/AnalyticsPage/utils'
import { DealTypeEnum } from 'constants/enums/special'
import styles from './dealTypeImage.module.scss'

interface IImages {
  control: Control
  setValue: (name: string, value: any) => void
  name: string
}

const Images = ({ control, setValue, name }: IImages) => {
  const sourceImg: string = 'https://app.appyhourmobile.com/assets/img'
  const selectedDealType: string | number = useWatch({ control, name }) || 0

  const dealNames: string[] = []
  const dealIds = []

  for (const deal in DealTypeEnum) {
    if (Number.isInteger(+deal)) {
      dealIds.push(deal)
    } else {
      dealNames.push(deal)
    }
  }

  return (
    <Col md={12} className={styles.noPadding}>
      {dealIds.map((id, index) => (
        <img
          key={index}
          onClick={() => setValue(name, id)}
          className={cx(styles.image, { [styles.selected]: +selectedDealType === +id })}
          alt={dealNames[index]}
          title={capitalize(dealNames[index].split('_').join(' '))}
          src={`${sourceImg}/${getDealImage(+id)}`}
        ></img>
      ))}
    </Col>
  )
}

export default Images
