import { ReactNode } from 'react'
import cx from 'classnames'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalProps } from 'reactstrap'
import styles from './modalDialog.module.scss'

interface ModalDialogProps {
  title?: string
  toggle?: React.MouseEventHandler<any>
  footer?: ReactNode
  position?: 'left' | 'top' | 'right' | 'bottom' | 'center'
  classNameHeader?: string
}

const POSITION_CLASSES = {
  left: styles.modalLeft,
  top: styles.modalTop,
  right: styles.modalRight,
  bottom: styles.modalBottom,
  center: 'modal-dialog-centered', // this is bootstrap class
}

const ModalDialog = ({
  toggle = () => {},
  position = 'center',
  className,
  title,
  children,
  footer,
  classNameHeader,
  ...props
}: ModalProps & ModalDialogProps) => {
  const classes = [styles.hfModalDialog]
  if (POSITION_CLASSES[position]) {
    classes.push(POSITION_CLASSES[position])
  }

  return (
    <Modal {...props} toggle={toggle} className={cx(...classes, className, 'modal-lg')}>
      <ModalHeader toggle={toggle} className={cx(classNameHeader, styles.title)}>
        {title}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      {footer ? <ModalFooter>{footer}</ModalFooter> : null}
    </Modal>
  )
}

export default ModalDialog
