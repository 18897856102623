import { api, errorHandler } from 'Api'
import { IServerError } from 'Api/constants'
import { RoleType } from 'constants/enums/role'
import { IAccountSetting, IChangePassword } from 'constants/interfaces'

export async function updateUser(
  username: string,
  secretPassword: string,
  email: string,
  password: string
): Promise<boolean> {
  try {
    const response = await api.patch(`/users/new`, {
      username,
      secretPassword,
      email,
      password,
    })
    if (response.status === 204) {
      return true
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function getAccountSetting(): Promise<IAccountSetting | boolean> {
  try {
    const response = await api.get(`/users/me`)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function editAccountSetting(data: IAccountSetting): Promise<any> {
  try {
    const response = await api.patch(`/users/me`, data)
    if (response.status === 204) {
      return true
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function editPassword(data: IChangePassword): Promise<any> {
  try {
    const response = await api.patch(`/users/me/password`, data)
    if (response.status === 204) {
      return true
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function getUserEstablishments() {
  try {
    const response = await api.get(`/user-establishments/establishments`)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    // const error: IServerError = err?.response?.data?.error ?? {}
    // errorHandler(error)
    return false
  }
}

export async function getUserManager() {
  try {
    const response = await api.get(`/user-establishments/managers`)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function createUserManager(data: any) {
  try {
    const response = await api.post(`/user-establishments`, data)
    if (response.status === 204) {
      return true
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function checkPassword(data: any) {
  try {
    const response = await api.post(`/users/me/check-password`, data)
    if (response.status === 200) {
      return response.data
    }
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function getUserByCondition(field: String, value: String) {
  try {
    const response = await api.get(`/users?filter={"where":{"${field}": "${value}"}}`)
    if (response.status === 200) {
      if (response.data.length === 0) {
        return false
      }
      return true
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function getUser(query: Record<string, any>) {
  try {
    const response = await api.get(`/users?filter=${JSON.stringify(query)}`)
    return response.data
  } catch (error) {
    console.error(error)
    return false
  }
}

export async function checkUserCondition(query: Record<string, any>, type: string = RoleType.ESTABLISHMENT) {
  try {
    const response = await api.get(`/condition/${type}?filter=${JSON.stringify(query)}`)
    return response.data
  } catch (error) {
    console.error(error)
    return false
  }
}
