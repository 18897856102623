const routes = {
  home: {
    value: '/',
  },
  login: {
    value: '/login',
  },
  firstLogin: {
    value: '/first-login',
  },
  forgotPassword: {
    value: '/forgot-password',
  },
  resetPassword: {
    value: '/reset-password',
  },
  myAccount: {
    value: '/my-account',
  },
  businessInfo: {
    value: '/business-info',
  },
  preferredPartners: {
    value: '/preferred-partners',
  },
  special: {
    value: '/special',
  },
  campaign: {
    value: '/campaign',
  },
  analytics: {
    value: '/analytics',
  },
  event: {
    value: '/event',
  },
}

export default routes
