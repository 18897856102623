const establishmentProvisions = [
  {
    name: 'delivery',
    content: 'food delivery',
    text: 'provide ',
  },
  {
    name: 'takeout',
    content: 'food takeout',
    text: 'provide ',
  },
  {
    name: 'patio',
    content: 'patio',
    text: 'have a ',
  },
  {
    name: 'rooftop',
    content: 'rooftop',
    text: 'have a ',
  },
  {
    name: 'brunch',
    content: 'brunch',
    text: 'provide ',
  },
  {
    name: 'dog_friendly',
    content: 'allow dogs ',
    text: '',
  },
]

export default establishmentProvisions
