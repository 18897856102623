import React from 'react'
import dotenv from 'dotenv'
import { Provider as MobxProvider } from 'mobx-react'
import { BrowserRouter, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AnalyticsPage from 'pages/AnalyticsPage'
import BusinessInfoPage from 'pages/BusinessInfoPage'
import CampaignPage from 'pages/CampaignPage'
import EventPage from 'pages/EventPage'
import FirstLoginPage from 'pages/FirstLoginPage'
import ForgotPasswordPage from 'pages/ForgotPasswordPage'
import LoginPage from 'pages/LoginPage'
import MyAccountPage from 'pages/MyAccountPage'
import ResetPasswordPage from 'pages/ResetPasswordPage'
import SpecialPage from 'pages/SpecialPage'
import routes from 'routes'
import { rootStore } from './stores'
dotenv.config()
const App: React.FC = () => {
  return (
    <MobxProvider {...rootStore}>
      <BrowserRouter>
        <Route exact path={[routes.home.value, routes.login.value]} component={LoginPage} />
        <Route exact path={routes.forgotPassword.value} component={ForgotPasswordPage} />
        <Route exact path={routes.resetPassword.value} component={ResetPasswordPage} />
        <Route exact path={routes.firstLogin.value} component={FirstLoginPage} />
        <Route exact path={routes.campaign.value} component={CampaignPage} />
        <Route exact path={routes.special.value} component={SpecialPage} />
        <Route exact path={routes.event.value} component={EventPage} />
        <Route exact path={routes.myAccount.value} component={MyAccountPage} />
        <Route exact path={routes.businessInfo.value} component={BusinessInfoPage} />
        <Route exact path={routes.analytics.value} component={AnalyticsPage} />
      </BrowserRouter>
      <ToastContainer />
    </MobxProvider>
  )
}

export default App
