import { api, errorHandler } from 'Api'
import { IServerError } from 'Api/constants'

export async function getRefsById(id: number) {
  try {
    const response = await api.get(`/refs?filter={"where":{"ref_type_id": ${id}}}`)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}
