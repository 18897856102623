import { useState } from 'react'
import cx from 'classnames'
import styles from './tabs.module.scss'

export interface ITabHeader {
  tabId: string
  label: string
}

export interface ITabContent {
  tabId: string
  content: React.ReactNode
}

export interface ITabsProps {
  headers: ITabHeader[]
  contents: ITabContent[]
}

const Tabs = (props: ITabsProps) => {
  const { headers, contents } = props
  const defaultActiveId: string = headers?.length > 0 ? headers[0].tabId : ''
  const [activeId, setActiveId] = useState<string>(defaultActiveId)

  return (
    <>
      <ul className={cx('nav nav-tabs nav-bordered mb-3', styles.layout)}>
        {Array.isArray(headers) &&
          headers.map((header: ITabHeader, index: number) => (
            <li
              key={index}
              className={cx('nav-item', styles.header, { [styles.active]: activeId === header.tabId })}
              onClick={() => setActiveId(header.tabId)}
            >
              <div data-bs-toggle="tab" aria-expanded="false" className="nav-link">
                <i className="las la-home d-md-none d-block"></i>
                <span className="d-none d-md-block">{header.label}</span>
              </div>
            </li>
          ))}
      </ul>

      <div className="tab-content">
        {Array.isArray(contents) &&
          contents.map((content: ITabContent, index: number) => (
            <div key={index} className={cx('tab-pane', { active: activeId === content.tabId })} id={content.tabId}>
              {content.content}
            </div>
          ))}
      </div>
    </>
  )
}

export default Tabs
