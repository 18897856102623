import queryString from 'querystring'
import { useEffect, useState } from 'react'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import { RoleType } from 'constants/enums/role'
import SendMailConfirmation from './SendMailConfirmation'
import ThankYouMessage from './ThankYouMessage'
import styles from './forgotPasswordPage.module.scss'

interface IForgotPasswordPageProps {
  title?: string
}

const ForgotPasswordPage = (props: IForgotPasswordPageProps) => {
  const { title } = props
  const { authStore } = useStore()
  const { email } = authStore
  const [isConfirmed, setIsConfirmed] = useState(false)
  const location = useLocation()
  const query = queryString.parse(location.search)
  const roleType = query['?type'] as RoleType

  useEffect(() => {
    authStore.getEmail(roleType)
  }, [])

  return (
    <>
      <div className={styles.title}>{title}</div>
      {isConfirmed ? (
        <ThankYouMessage />
      ) : (
        <SendMailConfirmation type={roleType} email={email} isConfirmed={isConfirmed} setIsConfirmed={setIsConfirmed} />
      )}
    </>
  )
}

export default observer(ForgotPasswordPage)
