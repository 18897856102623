import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import Icon, { IconGroupPrefix } from 'components/Icon'
import { EStatusType, getLastIntervalDate, getThisIntervalDate } from 'components/Pages/CampaignPage/constant'
import { LIMIT_PAGE_BREAK } from 'components/Table/components/Pagination'
import { ICampaign } from 'constants/interfaces'
import DateContent from './components/DateContent'
import LabelTitleGroup from './components/LabelTitleGroup'
import styles from './styles.module.scss'

interface ICampaignCardProps {
  item: ICampaign
}

const CampaignCard = (props: ICampaignCardProps) => {
  const { item } = props
  const { promo_id, status, dateTimeGroup, background, active } = item
  const { impressions, interactions } = dateTimeGroup
  const thisIntervalDate = getThisIntervalDate()
  const lastIntervalDate = getLastIntervalDate()
  const { campaignStore } = useStore()

  const handleActivate = async () => {
    if (promo_id) {
      const result = await campaignStore.updateCampaign(promo_id)

      if (result) {
        campaignStore.getAllCampaigns(LIMIT_PAGE_BREAK, campaignStore.pageIndex, '')
        toast.success('Update successfully')
      } else {
        toast.error('Update fail')
      }
    }
  }

  return (
    <div className={styles.campaignContainer}>
      <div className={styles.imageWrapper} style={{ backgroundImage: `url(${background})` }}>
        <LabelTitleGroup campaign={item} />
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.impressionGroup}>
          <div className={styles.infoTitle}>
            <Icon icon="eye" group={IconGroupPrefix.LAICON} />
            <span>Impression</span>
          </div>
          <div className={styles.infoContent}>
            <div>
              <div>{lastIntervalDate}</div>
              <DateContent {...impressions.lastInterval} status={status} />
            </div>
            <div>
              <div>{thisIntervalDate}</div>
              <DateContent {...impressions.thisInterval} status={status} />
            </div>
            <div>
              <div>All time</div>
              <div className={styles.valueRateGroup}>
                <span className={styles.value}>
                  {status === EStatusType.PENDING_ACTIVATION ? impressions.allTime : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.interactionGroup}>
          <div className={styles.infoTitle}>
            <Icon icon="hand-point-up" group={IconGroupPrefix.LAICON} />
            <span>Interaction</span>
          </div>
          <div className={styles.infoContent}>
            <div>
              <div>{lastIntervalDate}</div>
              <DateContent {...interactions.lastInterval} status={status} />
            </div>
            <div>
              <div>{thisIntervalDate}</div>
              <DateContent {...interactions.thisInterval} status={status} />
            </div>
            <div>
              <div>All time</div>
              <div className={styles.valueRateGroup}>
                <span className={styles.value}>
                  {status === EStatusType.PENDING_ACTIVATION ? interactions.allTime : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actionBar}>
        {active !== 1 ? (
          <div
            className={cx(styles.status, {
              [styles.statusActivation]: status === EStatusType.PENDING_ACTIVATION,
              [styles.statusReview]: status === EStatusType.PENDING_REVIEW,
            })}
          >
            {status}
          </div>
        ) : (
          <div></div>
        )}
        <div className={styles.actionButtonGroup}>
          <div className={styles.viewDetail}>
            <Icon icon="file-alt" group={IconGroupPrefix.LAICON} /> View Detail
          </div>
          {status === EStatusType.PENDING_ACTIVATION ? (
            <>
              {active !== 1 && (
                <Button className={cx(styles.actionButton, styles.activateButton)} onClick={handleActivate}>
                  <Icon icon="check-circle" group={IconGroupPrefix.LAICON} /> Activate
                </Button>
              )}
            </>
          ) : status === EStatusType.PENDING_REVIEW ? (
            <Button className={cx(styles.actionButton, styles.editButton)}>
              <Icon icon="edit" group={IconGroupPrefix.LAICON} /> Edit
            </Button>
          ) : (
            <Button className={cx(styles.actionButton, styles.deniedButton)}>
              <Icon icon="ban" group={IconGroupPrefix.LAICON} /> Denied
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CampaignCard
