import { Row, Col } from 'reactstrap'
import Button from 'components/Button'
import Divider from 'components/Divider'

import styles from './footerSection.module.scss'

interface IFooterSectionProps {
  cancelButtonText: string
  acceptButtonText: string
  onClickCancel?: (event: unknown) => void
  onClickAccept?: (event: unknown) => void
}

const FooterSection = (props: IFooterSectionProps) => {
  const { cancelButtonText, acceptButtonText, onClickCancel, onClickAccept } = props

  return (
    <>
      <Row>
        <Col className={styles.dividerStyle}>
          <Divider />
        </Col>
      </Row>
      <Row className={styles.modalFooter}>
        <Col className={styles.footerButtonStyle}>
          {cancelButtonText && (
            <div className={styles.cancelButtonStyle}>
              <Button color="secondary" outline onClick={onClickCancel}>
                {cancelButtonText}
              </Button>
            </div>
          )}
          <div className={styles.saveButtonStyle}>
            <Button color="primary" onClick={onClickAccept}>
              {acceptButtonText}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default FooterSection
