import cx from 'classnames'
import { EStatusType } from 'components/Pages/CampaignPage/constant'
import styles from './styles.module.scss'

interface IDateContentProps {
  value: number
  percentChange: number
  status: EStatusType
}

const DateContent = (props: IDateContentProps) => {
  const { value, percentChange, status } = props
  return (
    <div className={styles.date}>
      <span className={styles.value}>{status === EStatusType.PENDING_ACTIVATION ? value : '-'}</span>
      {status === EStatusType.PENDING_ACTIVATION && (
        <span
          className={cx(styles.rate, {
            [styles.increment]: percentChange >= 0,
            [styles.decrement]: percentChange < 0,
          })}
        >
          {percentChange >= 0 && '+'}
          {percentChange}%
        </span>
      )}
    </div>
  )
}

export default DateContent
