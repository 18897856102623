import cx from 'classnames'
import { ListGroupItem as RSListGroupItem, ListGroupItemProps as RSListGroupItemProps } from 'reactstrap'

const ListGroupItem = (props: RSListGroupItemProps) => {
  const { className, ...otherProps } = props

  return <RSListGroupItem {...otherProps} className={cx(className)} />
}

export default ListGroupItem
