import queryString from 'querystring'
import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useForm, useWatch } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label } from 'reactstrap'
import { resetPassword } from 'Api/auth'
import ButtonWithIcon from 'components/ButtonWithIcon'
import ErrorMessage from 'components/ErrorMessage'
import Input from 'components/InputGroup/Input'
import { RoleType } from 'constants/enums/role'
import routes from 'routes'
import styles from './resetPasswordPage.module.scss'

interface IResetPasswordPageProps {
  title?: string
}

interface ILoginForm {
  newPassword: string
  confirmPassword: string
}

const ResetPasswordPage = (props: IResetPasswordPageProps) => {
  const { title } = props
  const methods = useForm<ILoginForm>()
  const location = useLocation()
  const history = useHistory()
  const query = queryString.parse(location.search)
  const token = query['?token']
  const type = query['type'] as RoleType
  console.log('🚀 ~ file: index.tsx ~ line 34 ~ ResetPasswordPage ~ type', type)
  const [inputNewPasswordType, setInputNewPasswordType] = useState('password')
  const [inputConfirmNewPasswordType, setInputConfirmNewPasswordType] = useState('password')
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false)
  const { handleSubmit, register, errors, control } = methods
  const newPassword = useWatch({ control, name: 'newPassword' })

  useEffect(() => {
    if (isShowPassword) {
      setInputNewPasswordType('text')
    } else {
      setInputNewPasswordType('password')
    }
  }, [isShowPassword])

  useEffect(() => {
    if (isShowConfirmPassword) {
      setInputConfirmNewPasswordType('text')
    } else {
      setInputConfirmNewPasswordType('password')
    }
  }, [isShowConfirmPassword])

  async function handleChange(data: any) {
    const { newPassword, confirmPassword } = data
    const resetToken = Array.isArray(token) ? token[0] : token
    const result = await resetPassword(newPassword, confirmPassword, resetToken, type)
    if (!result) {
      toast.error('Something wrong happened')
    } else {
      toast.success('Reset password successfully')
      history.push(routes.login.value)
    }
  }

  return (
    <Form {...methods} onSubmit={handleSubmit(handleChange)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.userInfoArea}>
        <FormGroup>
          <Label className={styles.label}>New password</Label>
          <Input
            addonType="append"
            addonClassname={styles.inputAppendIcon}
            iconName={isShowPassword ? 'eye' : 'eye-slash'}
            className={cx(styles.passwordInput)}
            name="newPassword"
            type={inputNewPasswordType}
            innerRef={register({
              required: true,
              minLength: { value: 6, message: 'Password must be at least 6 characters' },
            })}
            onIconClick={() => setIsShowPassword(!isShowPassword)}
          />
          <ErrorMessage error={errors?.newPassword?.message} />

          <Label className={styles.label}>Confirm new password</Label>
          <Input
            addonType="append"
            addonClassname={styles.inputAppendIcon}
            iconName={isShowConfirmPassword ? 'eye' : 'eye-slash'}
            className={cx(styles.passwordInput)}
            type={inputConfirmNewPasswordType}
            innerRef={register({
              required: true,
              validate: (value) => newPassword === value,
            })}
            name="confirmPassword"
            onIconClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
          />
          <ErrorMessage error={errors?.confirmPassword ? 'Confirmation password must match new password' : ''} />
        </FormGroup>
      </div>
      <ButtonWithIcon type="submit" content="Submit" className={cx(styles.continueButton)} />
    </Form>
  )
}

export default ResetPasswordPage
