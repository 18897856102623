import cx from 'classnames'
import styles from './label.module.scss'

interface ILabelProps {
  children: React.ReactChildren | React.ReactNode
  strong?: boolean
  inline?: boolean
  strikeThrough?: boolean
  isRequired?: boolean
  className?: string
}

const Label = (props: ILabelProps) => {
  const { children, strong, inline, strikeThrough, isRequired, className } = props
  return (
    <span
      className={cx(className, styles.label, {
        [styles.strong]: strong,
        [styles.inline]: inline,
        [styles.strikeThrough]: strikeThrough,
      })}
    >
      {children}
      {isRequired && <span className={styles.requiredSymbol}> *</span>}
    </span>
  )
}

export default Label
