import { useState } from 'react'
import { has, get } from 'lodash'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Container, Row, Form } from 'reactstrap'
import { editPassword, checkPassword } from 'Api/user'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Icon from 'components/Icon'
import Input from 'components/InputGroup/Input'
import Label from 'components/Label'
import { IChangePassword } from 'constants/interfaces'
import { checkAtLeastCharacters } from './utils'
import styles from './changePassword.module.scss'

const ChangePassword = () => {
  const [currentPasswordInputType, setCurrentPasswordInputType] = useState('password')
  const [newPasswordInputType, setNewPasswordInputType] = useState('password')
  const [newPasswordConfirmInputType, setNewPasswordConfirmInputType] = useState('password')

  const [currentPasswordInputIcon, setCurrentPasswordInputIcon] = useState('eye')
  const [newPasswordInputIcon, setNewPasswordInputIcon] = useState('eye')
  const [newPasswordConfirmInputIcon, setNewPasswordConfirmInputIcon] = useState('eye')

  const methods = useForm<IChangePassword>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
  })

  const { register, reset, errors, formState, watch, handleSubmit } = methods

  const { isSubmitting } = formState

  const newPassword = watch('newPassword')

  const showClick = (name: string) => {
    if (name === 'currentPassword') {
      if (currentPasswordInputType === 'text') {
        setCurrentPasswordInputIcon('eye')
        setCurrentPasswordInputType('password')
      } else {
        setCurrentPasswordInputIcon('eye-slash')
        setCurrentPasswordInputType('text')
      }
    } else if (name === 'newPassword') {
      if (newPasswordInputType === 'text') {
        setNewPasswordInputIcon('eye')
        setNewPasswordInputType('password')
      } else {
        setNewPasswordInputIcon('eye-slash')
        setNewPasswordInputType('text')
      }
    } else {
      if (newPasswordConfirmInputType === 'text') {
        setNewPasswordConfirmInputIcon('eye')
        setNewPasswordConfirmInputType('password')
      } else {
        setNewPasswordConfirmInputIcon('eye-slash')
        setNewPasswordConfirmInputType('text')
      }
    }
  }

  const onSubmit = async (data: IChangePassword) => {
    const result = await editPassword(data)
    if (result) {
      toast.success('Update successfully')
      reset({
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      })
    } else {
      toast.error('Update fail')
    }
  }

  return (
    <Form className={styles.formCustom} onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Row className={styles.rowMarginBottom}>
          <Container className={styles.passwordHelper}>
            <Row>Password Requirements</Row>
            <Row className={styles.requirementCustom}>
              <Icon icon="check-square" />
              Must be at least 6 characters
            </Row>
            <Row className={styles.requirementCustom}>
              <Icon icon="check-square" />
              Can't be your old password
            </Row>
          </Container>
        </Row>
        <Row className={styles.rowMarginBottom}>
          <Label>Current Password</Label>
          <Input
            name="currentPassword"
            type={currentPasswordInputType}
            className={styles.inputCustom}
            addonClassname={styles.addonInputCustom}
            innerRef={register({
              required: 'Current password is empty',
              validate: {
                atLeastSixCharacters: (value) => {
                  return checkAtLeastCharacters(value)
                },
                checkPassword: async (value) => {
                  // eslint-disable-next-line no-return-await
                  return await checkPassword({ newPassword: value })
                },
              },
            })}
            invalid={has(errors, 'currentPassword')}
            addonType={'append'}
            iconName={currentPasswordInputIcon}
            onIconClick={() => showClick('currentPassword')}
          />
          <ErrorMessage error={get(errors, 'currentPassword.message', '')} />
          {get(errors, 'currentPassword.type', null) === 'checkPassword' && (
            <ErrorMessage error={'Current Password is not correct'} />
          )}
          {get(errors, 'currentPassword.type', null) === 'atLeastSixCharacters' && (
            <ErrorMessage error={'Current Password must be at least 6 characters'} />
          )}
        </Row>
        <Row className={styles.rowMarginBottom}>
          <Label>New Password</Label>
          <Input
            name="newPassword"
            type={newPasswordInputType}
            className={styles.inputCustom}
            addonClassname={styles.addonInputCustom}
            innerRef={register({
              required: 'New password is empty',
              validate: {
                atLeastSixCharacters: (value) => {
                  return checkAtLeastCharacters(value)
                },
                isOldPassword: async (value) => {
                  const result = await checkPassword({ newPassword: value })
                  return !result
                },
              },
            })}
            invalid={has(errors, 'newPassword')}
            addonType={'append'}
            iconName={newPasswordInputIcon}
            onIconClick={() => showClick('newPassword')}
          />
          <ErrorMessage error={get(errors, 'newPassword.message', '')} />
          {get(errors, 'newPassword.type', null) === 'atLeastSixCharacters' && (
            <ErrorMessage error={'Password must be at least 6 characters'} />
          )}
          {get(errors, 'newPassword.type', null) === 'isOldPassword' && (
            <ErrorMessage error={"Can't be your old password"} />
          )}
        </Row>
        <Row className={styles.rowMarginBottom}>
          <Label>New Password Confirm</Label>
          <Input
            name="newPasswordConfirm"
            type={newPasswordConfirmInputType}
            className={styles.inputCustom}
            addonClassname={styles.addonInputCustom}
            innerRef={register({
              required: 'New password confirm is empty',
              validate: {
                atLeastSixCharacters: (value) => {
                  return checkAtLeastCharacters(value)
                },
                isNewPassword: (value) => {
                  return value === newPassword
                },
              },
            })}
            invalid={has(errors, 'newPasswordConfirm')}
            addonType={'append'}
            iconName={newPasswordConfirmInputIcon}
            onIconClick={() => showClick('newPasswordConfirm')}
          />
          <ErrorMessage error={get(errors, 'newPasswordConfirm.message', '')} />
          {get(errors, 'newPasswordConfirm.type', null) === 'atLeastSixCharacters' && (
            <ErrorMessage error={'Password must be at least 6 characters'} />
          )}
          {get(errors, 'newPasswordConfirm.type', null) === 'isNewPassword' && (
            <ErrorMessage error={'New Password and New Password Confirm do not match'} />
          )}
        </Row>
        <Row className={styles.saveButtonRow}>
          <Button disabled={isSubmitting} className={styles.saveButton} type="submit">
            <Icon icon={'save'} /> Change Password
          </Button>
        </Row>
      </Container>
    </Form>
  )
}

export default ChangePassword
