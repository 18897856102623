import classNames from 'classnames'
import { useStore } from 'hooks/useStore'
import capitalize from 'lodash/capitalize'
import { observer } from 'mobx-react'
import { ButtonGroup, Button } from 'reactstrap'
import { WeekDayEnum } from '../../constants'
import styles from './chooseWeekDay.module.scss'

export interface IChooseWeekDayProps {
  weekDay: WeekDayEnum[]
  setWeekDay: (weekDay: WeekDayEnum[]) => void
  selectedDays: WeekDayEnum[]
  refProp?: any
}

const ChooseWeekDay = (props: IChooseWeekDayProps) => {
  const { specialStore } = useStore()
  const { specialsRemainingMap = [] } = specialStore
  const { weekDay, setWeekDay, selectedDays, refProp } = props
  const disableTooltipText = 'Already have 5 specials'
  function toggleChooseWeekDay(day: WeekDayEnum): void {
    if (weekDay.includes(day)) {
      const result: WeekDayEnum[] = weekDay.filter((e) => e !== day)
      setWeekDay([...result])
    } else {
      setWeekDay([...weekDay, day])
    }
  }

  return (
    <ButtonGroup className={styles.buttonGroup}>
      <div ref={refProp} />
      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.MONDAY),
          [styles.isDisable]: specialsRemainingMap[1] === 0 && !selectedDays.includes(WeekDayEnum.MONDAY),
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.MONDAY)}
      >
        {capitalize(WeekDayEnum.MONDAY.slice(0, 3))}
        {specialsRemainingMap[1] === 0 && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.TUESDAY),
          [styles.isDisable]: specialsRemainingMap[2] === 0 && !selectedDays.includes(WeekDayEnum.TUESDAY),
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.TUESDAY)}
      >
        {capitalize(WeekDayEnum.TUESDAY.slice(0, 3))}
        {specialsRemainingMap[2] === 0 && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.WEDNESDAY),
          [styles.isDisable]: specialsRemainingMap[3] === 0 && !selectedDays.includes(WeekDayEnum.WEDNESDAY),
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.WEDNESDAY)}
      >
        {capitalize(WeekDayEnum.WEDNESDAY.slice(0, 3))}
        {specialsRemainingMap[3] === 0 && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.THURSDAY),
          [styles.isDisable]: specialsRemainingMap[4] === 0 && !selectedDays.includes(WeekDayEnum.THURSDAY),
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.THURSDAY)}
      >
        {capitalize(WeekDayEnum.THURSDAY.slice(0, 3))}
        {specialsRemainingMap[4] === 0 && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.FRIDAY),
          [styles.isDisable]: specialsRemainingMap[5] === 0 && !selectedDays.includes(WeekDayEnum.FRIDAY),
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.FRIDAY)}
      >
        {capitalize(WeekDayEnum.FRIDAY.slice(0, 3))}
        {specialsRemainingMap[5] === 0 && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.SATURDAY),
          [styles.isDisable]: specialsRemainingMap[6] === 0 && !selectedDays.includes(WeekDayEnum.SATURDAY),
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.SATURDAY)}
      >
        {capitalize(WeekDayEnum.SATURDAY.slice(0, 3))}
        {specialsRemainingMap[6] === 0 && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.SUNDAY),
          [styles.isDisable]: specialsRemainingMap[0] === 0 && !selectedDays.includes(WeekDayEnum.SUNDAY),
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.SUNDAY)}
        title={specialsRemainingMap[0] === 0 ? disableTooltipText : undefined}
      >
        {capitalize(WeekDayEnum.SUNDAY.slice(0, 3))}
        {specialsRemainingMap[0] === 0 && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>
    </ButtonGroup>
  )
}

export default observer(ChooseWeekDay)
