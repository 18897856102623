import { useEffect, useState } from 'react'
import classNames from 'classnames'
import cx from 'classnames'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import DatePicker from 'react-datepicker'
import { Controller, useWatch } from 'react-hook-form'
import styles from './styles.module.scss'

interface DateTimePickerProps {
  hideAddon?: boolean
  name: string
  control?: any
  errors?: Record<string, unknown>
}

interface DateTimePickerState {
  startDate?: Date
  isTime?: boolean
  rules?: Record<string, unknown>
  defaultValue?: Date
  setError?: (name: string, option: Record<string, any>) => void
  clearErrors?: (name: string) => void
}

type DatePickerProps = React.ComponentProps<typeof DatePicker>

const ExclusiveDateTimePicker = (props: DatePickerProps & DateTimePickerProps & DateTimePickerState) => {
  const { specialStore } = useStore()
  const { name, control, errors = {}, rules, defaultValue = new Date(), hideAddon, isTime } = props
  const [startDate, setStartDate] = useState(defaultValue ? defaultValue : new Date())
  const selectedStartDate: Date = useWatch({ control, name: 'startDate' }) || new Date()
  const isEditingExclusive = specialStore?.formData?.editingSpecialId ?? 0
  const openExclusiveModal = specialStore?.openExclusiveCreateModal ?? false

  useEffect(() => {
    if (isEditingExclusive) {
      specialStore.setAvailableOfWeek(true)
    }
  }, [])
  // TODO: turn new Date to time zone
  useEffect(() => {
    let ignore = isEditingExclusive && dayjs(selectedStartDate).isSame(defaultValue, 'hour')
    if (typeof selectedStartDate === 'object' && name === 'startDate' && !ignore && openExclusiveModal) {
      specialStore.checkAvailableOfWeek(selectedStartDate, defaultValue)
    }
    if (ignore) {
      specialStore.setAvailableOfWeek(true)
    }
  }, [selectedStartDate])

  return (
    <div
      className={classNames(styles.hfDateTimePicker, {
        [styles.error]: !!errors[name],
      })}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          setStartDate(value)
          return (
            <div className="input-group" style={{ justifyContent: 'space-between' }}>
              <DatePicker
                {...props}
                className={cx(styles.inputStyle, 'form-control')}
                selected={startDate}
                minDate={new Date()}
                onChange={onChange}
              />
              {!hideAddon && (
                <div className={cx('input-group-append')}>
                  <span className="input-group-text bg-primary border-primary text-white">
                    <i
                      className={classNames({
                        'las la-clock font-20': isTime,
                        'las la-calendar font-20': !isTime,
                      })}
                    ></i>
                  </span>
                </div>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default observer(ExclusiveDateTimePicker)
