import Tabs, { ITabContent, ITabHeader } from 'components/Tabs'
import OneTimeEvent from './components/OneTimeEvent'
import WeeklyEvent from './components/WeeklyEvent'
import styles from './eventTabs.module.scss'

const EventTabs = () => {
  const headers: ITabHeader[] = [
    {
      label: 'ONE-TIME EVENTS',
      tabId: 'one-time-events',
    },
    {
      label: 'WEEKLY EVENTS',
      tabId: 'weekly-events',
    },
  ]

  const contents: ITabContent[] = [
    {
      tabId: 'one-time-events',
      content: <OneTimeEvent />,
    },
    {
      tabId: 'weekly-events',
      content: <WeeklyEvent />,
    },
  ]

  return (
    <div className={styles.container}>
      <Tabs headers={headers} contents={contents} />
    </div>
  )
}

export default EventTabs
