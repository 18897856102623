import get from 'lodash/get'
import { Col, Row } from 'reactstrap'
import ErrorMessage from 'components/ErrorMessage'
import InputRadio from 'components/InputRadio'
import { PriceType } from 'constants/enums/special'

interface IPriceTypeSelectionProps {
  register: any
  errors: Record<string, unknown>
  disabled: boolean
}

const PriceTypeSelection = ({ register, errors, disabled }: IPriceTypeSelectionProps) => {
  return (
    <>
      <Row>
        <Col md={3}>
          <InputRadio
            disabled={disabled}
            label="$"
            name="priceType"
            value={`${PriceType.NORMAL}`}
            innerRef={register}
          />
        </Col>
        <Col md={3}>
          <InputRadio
            label="% Off"
            name="priceType"
            disabled={disabled}
            value={`${PriceType.OFF}`}
            innerRef={register({ required: 'This field is required' })}
          />
        </Col>
        <Col md={3}>
          <InputRadio
            disabled={disabled}
            label="BOGO"
            name="priceType"
            value={`${PriceType.BOGO}`}
            innerRef={register}
          />
        </Col>
        <Col md={3}>
          <InputRadio
            disabled={disabled}
            label="Free"
            name="priceType"
            value={`${PriceType.FREE}`}
            innerRef={register}
          />
        </Col>
      </Row>
      <ErrorMessage error={get(errors, 'priceType.message', '') as string} />
    </>
  )
}

export default PriceTypeSelection
