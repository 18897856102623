import cx from 'classnames'
import { FormFeedback, Input as ReactStrapInput, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import Icon, { IconGroupPrefix } from 'components/Icon'
import styles from './input.module.scss'

interface InputProps {
  iconName?: string
  iconGroup?: IconGroupPrefix
  addonType?: 'append' | 'prepend'
  addonClassname?: string
  className?: string
  type?: 'text' | 'password' | 'email' | 'textarea' | any
  invalid?: boolean
  placeholder?: string
  pattern?: string
  valid?: boolean
  message?: string
  value?: string
  setValue?: (value: string) => void
  onIconClick?: () => void
  readOnly?: boolean
  name?: string
  innerRef?: any
  disabled?: boolean
}

const Input = (props: InputProps) => {
  const {
    iconName,
    iconGroup,
    addonType,
    addonClassname,
    className,
    invalid,
    valid,
    placeholder,
    type,
    pattern,
    message,
    value,
    setValue,
    onIconClick,
    readOnly,
    name,
    innerRef,
    disabled,
  } = props
  return (
    <InputGroup>
      <ReactStrapInput
        disabled={disabled}
        name={name}
        type={type}
        innerRef={innerRef}
        invalid={invalid}
        valid={valid}
        readOnly={readOnly}
        pattern={pattern}
        placeholder={placeholder}
        className={cx(styles.inputMain, className)}
        defaultValue={value || ''}
        onChange={(event: { target: HTMLInputElement }) => setValue && setValue(event.target.value)}
      />
      {addonType && (
        <InputGroupAddon addonType={addonType}>
          <InputGroupText
            className={cx(
              {
                [styles.invalidAddon]: invalid,
                [styles.validAddon]: valid,
              },
              addonClassname
            )}
            onClick={onIconClick}
          >
            {iconName && <Icon icon={iconName} group={iconGroup} />}
          </InputGroupText>
        </InputGroupAddon>
      )}
      <FormFeedback>{message}</FormFeedback>
    </InputGroup>
  )
}

export default Input
