import { useState } from 'react'
import { PointTooltip, PointTooltipProps, ResponsiveLine, Serie } from '@nivo/line'
import dayjs from 'dayjs'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import { getValidEnding } from 'utils/common'
import styles from './line.module.scss'
dayjs.extend(dayOfYear)

export interface PieChartProps {
  data: Serie[]
  unit: string
}

export const Line = (props: PieChartProps) => {
  const { data, unit } = props
  const [hoverX, setHoverX] = useState<string>()
  const isLongData: boolean = Array.isArray(data[0].data) && data[0].data.length >= 24
  function format(date: Date | string) {
    return dayjs(date).dayOfYear() % 2 === 1 || !isLongData ? dayjs(date).format('DD') : ''
  }

  const customTooltip: PointTooltip = ({ point }: PointTooltipProps) => {
    const x: string = point.data.xFormatted as string
    const y: number = parseInt(point.data.yFormatted as string)
    return (
      <div className={styles.tooltip}>
        <span>{x} </span>
        <span>
          <b>{y}</b>
          {`${getValidEnding(y, unit, false, true)}`}
        </span>
      </div>
    )
  }
  return (
    <div className={styles.container}>
      <ResponsiveLine
        margin={{ top: 30, right: 6, bottom: 18, left: 48 }}
        colors={['#FF7B1A']}
        data={data}
        xScale={{ type: 'time', format: '%Y-%m-%d', useUTC: false, precision: 'day' }}
        xFormat="time:%m/%d/%Y"
        yScale={{ type: 'linear' }}
        axisLeft={{ tickSize: 0, tickPadding: 5 }}
        axisBottom={{ tickSize: 0, tickValues: 'every day', format }}
        pointSymbol={(data) => {
          if (data.datum.xFormatted === hoverX) {
            return <circle r={6} fill="#FF7B1A" stroke="white" strokeWidth="1" />
          }
          return <circle r={2} fill="#FF7B1A" />
        }}
        onMouseMove={(d) => {
          d.data.xFormatted !== hoverX && setHoverX(d.data.xFormatted as string)
        }}
        enablePoints={true}
        onMouseLeave={() => setHoverX('')}
        lineWidth={4}
        pointSize={10}
        useMesh
        animate={false}
        tooltip={customTooltip}
        enableCrosshair={false}
        enableSlices={false}
        enableGridX={false}
        pointBorderWidth={1}
        pointBorderColor="white"
      />
    </div>
  )
}
