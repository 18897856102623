import cx from 'classnames'
import styles from './impressionValue.module.scss'

export interface IImpressionValueProps {
  value: string
  isInCard?: boolean
  isNoSubString?: boolean
}

const ImpressionValue = (props: IImpressionValueProps) => {
  const { value, isInCard, isNoSubString } = props
  const splitValue: string[] = Array.isArray(value.split(' ')) ? value.split(' ') : []
  const mainValue: string = splitValue.length > 0 ? splitValue[0] : ''
  const subValue: string = splitValue.length > 1 ? splitValue[1] : ''
  const isMinus: boolean = !!subValue && subValue.charAt(0) === '-'
  const subString: string = Array.isArray(value.split('%')) ? value.split('%')[1] : ''

  return value ? (
    <div className={cx({ [styles.inCard]: isInCard })}>
      <span className={styles.mainValue}>{mainValue}</span>
      {subValue && <span className={cx(styles.subValue, { [styles.isMinus]: isMinus })}>{subValue}</span>}
      {subString && !isNoSubString && <span className={cx(styles.subString)}>{subString}</span>}
    </div>
  ) : (
    <></>
  )
}
export default ImpressionValue
