import { useEffect } from 'react'
import cx from 'classnames'
import Icon from 'components/Icon'
import styles from './styles.module.scss'

interface IPictureProps {
  className?: string
  alt: string
  src: string
}

const Picture = (props: IPictureProps) => {
  const { className, alt, src } = props

  useEffect(() => {
    // console.log(src);
  }, [src])

  return (
    <div className={cx(className || '', styles.container)}>
      <div className={styles.wrapper}>
        {src === '' ? (
          <div className={styles.defaultImageWrapper}>
            <div>No Image</div>
            <div className={styles.uploadImage}>
              <Icon icon="cloud-upload-alt" /> Upload Image
            </div>
          </div>
        ) : (
          <img src={src} alt={alt} />
        )}
      </div>
    </div>
  )
}

export default Picture
