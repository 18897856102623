import cx from 'classnames'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import ButtonWithIcon from 'components/ButtonWithIcon'
import { SpecialType } from 'components/Pages/SpecialPage/constants'
import styles from './weeklyAction.module.scss'

interface IWeeklyActionProps {
  disable: boolean
  initialValue: Date
}

const WeeklyAction = ({ disable, initialValue }: IWeeklyActionProps) => {
  const { specialStore } = useStore()

  function handleAddButton() {
    specialStore.setOpenCreateModal(true, SpecialType.EXCLUSIVE)
    specialStore.setFormData({ startDate: initialValue, duration: dayjs().add(1, 'hour').toDate() }, true)
  }

  return (
    <ButtonWithIcon
      disable={disable}
      iconName="plus"
      className={cx(styles.addButton, { [styles.disabledButton]: disable })}
      onClick={handleAddButton}
    />
  )
}

export default observer(WeeklyAction)
