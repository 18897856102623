import { Modal, Row, Col, Container } from 'reactstrap'
import FooterSection from './components/FooterSection'
import HeaderSection from './components/HeaderSection'
import styles from './confirmModal.module.scss'

export interface IConfirmModalProps {
  titleText?: string
  bodyText?: string | React.ReactNode
  cancelButtonText?: string
  acceptButtonText?: string
  isOpen?: boolean
  onClickClose?: (event: unknown) => void
  onClickCancel?: (event: unknown) => void
  onClickAccept: (event: unknown) => void
}

const ConfirmModal = (props: IConfirmModalProps) => {
  const {
    onClickClose,
    onClickCancel,
    onClickAccept,
    isOpen = false,
    titleText = 'Confirm modal',
    bodyText = 'Changes will be saved. Do you want to cancel?',
    cancelButtonText = 'Cancel',
    acceptButtonText = 'Accept',
  } = props

  return (
    <Modal size="md" isOpen={isOpen} centered>
      <Container>
        <HeaderSection onClickClose={onClickClose} titleText={titleText} />
        <Row className={styles.modalBodyPadding}>
          <Col md="12">
            <div className={styles.modalBodyStyle}>{bodyText}</div>
          </Col>
        </Row>
        <FooterSection
          cancelButtonText={cancelButtonText}
          acceptButtonText={acceptButtonText}
          onClickCancel={onClickCancel}
          onClickAccept={onClickAccept}
        />
      </Container>
    </Modal>
  )
}

export default ConfirmModal
