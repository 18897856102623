import { HTMLProps } from 'react'
import cx from 'classnames'

interface IconProps {
  group?: IconGroupPrefix
  icon: string
  className?: string
  id?: string
}

export enum IconGroupPrefix {
  DRIPICON = 'dripicons',
  MUIICON = 'mdi',
  LAICON = 'las la',
}

const Icon = ({
  group = IconGroupPrefix.LAICON,
  icon,
  className,
  ...props
}: HTMLProps<HTMLSpanElement> & IconProps) => {
  const iconClass = `${group}-${icon}`
  return <i className={cx(iconClass, className)} {...props} />
}

export default Icon
