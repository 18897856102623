export interface ICreateSpecialModal {
  isOpening: boolean
  toggleShow: () => void
  handleReview: () => void
}

export enum WeekDayEnum {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}
