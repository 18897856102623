import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { Container, Row, Col } from 'reactstrap'
import Card from 'components/Card'
import ConfirmContinueModal from 'components/ConfirmModal/confirmContinueModal'
import Icon from 'components/Icon'
import ListGroup from 'components/ListGroup'
import ListGroupItem from 'components/ListGroupItem'
import { IAccountSetting } from 'constants/interfaces'
import AccountManagers from './components/AccountManagers'
import AccountSetting from './components/AccountSetting'
import ChangePassword from './components/ChangePassword'
import { MyAccountSectionsEnum } from './constant'
import styles from './myAccountPage.module.scss'

const MyAccountPage = () => {
  const { myAccountStore } = useStore()

  const methodsAccountSetting = useForm<IAccountSetting>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: '',
      phone_num: '',
      username: '',
    },
  })

  const { formState } = methodsAccountSetting
  const { isDirty } = formState
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [myAccountSection, setMyAccountSection] = useState(MyAccountSectionsEnum.ACCOUNT_SETTING)
  const [myNextAccountSection, setMyNextAccountSection] = useState(MyAccountSectionsEnum.ACCOUNT_SETTING)
  function onAcceptLostButtonClick(): void {
    setIsModalOpen(false)
    setMyAccountSection(myNextAccountSection)
    setMyNextAccountSection(MyAccountSectionsEnum.ACCOUNT_SETTING)
  }

  function onSectionChange(section: MyAccountSectionsEnum): void {
    if (myAccountSection === MyAccountSectionsEnum.ACCOUNT_SETTING) {
      if (isDirty) {
        setIsModalOpen(true)
        setMyNextAccountSection(section)
        if (isModalOpen === true) {
          setMyAccountSection(section)
        }
      } else {
        setMyAccountSection(section)
      }
    } else {
      setMyAccountSection(section)
    }
  }

  useEffect(() => {
    myAccountStore.getAccountSetting()
    myAccountStore.getEstablishmentList()
    myAccountStore.getAddManager()
  }, [])

  function gotoAccountSetting() {
    onSectionChange(MyAccountSectionsEnum.ACCOUNT_SETTING)
  }

  function gotoChangePassword() {
    onSectionChange(MyAccountSectionsEnum.CHANGE_PASSWORD)
  }

  function gotoAccountManagers() {
    onSectionChange(MyAccountSectionsEnum.ACCOUNT_MANAGERS)
  }

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitle}>My Account</div>
      <Card>
        <Container fluid>
          <Row>
            <Col className={styles.leftSide} xs="12" md="3">
              <ListGroup>
                <ListGroupItem
                  className={cx(styles.listItem, {
                    [styles.listItemActive]: myAccountSection === MyAccountSectionsEnum.ACCOUNT_SETTING,
                  })}
                  onClick={gotoAccountSetting}
                >
                  <Icon icon={'user-circle'} className={styles.listItemIcon} />
                  {MyAccountSectionsEnum.ACCOUNT_SETTING}
                </ListGroupItem>
                <ListGroupItem
                  className={cx(styles.listItem, {
                    [styles.listItemActive]: myAccountSection === MyAccountSectionsEnum.CHANGE_PASSWORD,
                  })}
                  onClick={gotoChangePassword}
                >
                  <Icon icon={'lock'} className={styles.listItemIcon} />
                  {MyAccountSectionsEnum.CHANGE_PASSWORD}
                </ListGroupItem>
                <ListGroupItem
                  className={cx(styles.listItem, {
                    [styles.listItemActive]: myAccountSection === MyAccountSectionsEnum.ACCOUNT_MANAGERS,
                  })}
                  onClick={gotoAccountManagers}
                >
                  <Icon icon={'clone'} className={styles.listItemIcon} />
                  {MyAccountSectionsEnum.ACCOUNT_MANAGERS}
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col className={styles.rightSide} xs="12" md="9">
              {myAccountSection === MyAccountSectionsEnum.ACCOUNT_SETTING ? (
                <FormProvider {...methodsAccountSetting}>
                  <AccountSetting />
                </FormProvider>
              ) : myAccountSection === MyAccountSectionsEnum.CHANGE_PASSWORD ? (
                <ChangePassword />
              ) : (
                <AccountManagers />
              )}
            </Col>
          </Row>
          <ConfirmContinueModal
            isOpen={isModalOpen}
            setOpen={setIsModalOpen}
            onAcceptLostButton={onAcceptLostButtonClick}
          />
        </Container>
      </Card>
    </div>
  )
}

export default observer(MyAccountPage)
