import cx from 'classnames'
import { CategoryEnum } from 'constants/enums/special'
import styles from './tag.module.scss'

interface ITagProps {
  dinein?: CategoryEnum
  carryout?: CategoryEnum
}

const Tag = ({ dinein, carryout }: ITagProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.tagContainer, styles.dinein, { [styles.inActive]: !dinein })}>Dine-in</div>
      <div className={cx(styles.tagContainer, styles.takeout, { [styles.inActive]: !carryout })}>Takeout</div>
    </div>
  )
}

export default Tag
