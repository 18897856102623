import { ITableHeader } from 'types'

export interface ISpecialMock {
  productType: string
  name: string
  price: string
  category: string
  applyTime: string
}

export enum SpecialType {
  EXCLUSIVE = 'exclusive',
  WEEKLY = 'weekly',
}

export function getHeaderList(expandableCell: React.ReactNode) {
  const headers: ITableHeader[] = [
    {
      Header: 'Type',
      accessor: 'productType',
      Cell: expandableCell,
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: expandableCell,
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: expandableCell,
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: expandableCell,
    },
    {
      Header: 'Apply time',
      accessor: 'applyTime',
      Cell: expandableCell,
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getTodayHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Type',
      accessor: 'productType',
    },
    {
      Header: 'Price',
      accessor: 'price',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Category',
      accessor: 'category',
    },
    {
      Header: 'Apply time',
      accessor: 'applyTime',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}
