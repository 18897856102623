import { api, errorHandler } from 'Api'
import { IServerError } from 'Api/constants'
import { IPagination } from 'constants/interfaces'

export async function getCampaigns(pagination: IPagination, keyword?: string) {
  try {
    const response = await api.get(`/campaigns?keyword=${keyword}&pagination=${JSON.stringify(pagination)}`)
    return response.data
  } catch (err: any) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function updateCampaigns(id: number) {
  try {
    const response = await api.patch(`/campaigns/activate/${id}`)
    return response
  } catch (err: any) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}
