import dayjs from 'dayjs'
import { flow, makeAutoObservable } from 'mobx'
import { createNewEvent, getEventDetail, getEventList, getEventTableData, updateEvent } from 'Api/event'
import { WeekDayEnum } from 'constants/common'
import { IEvent, ISpecial } from 'constants/interfaces'
import { getDayOfWeekSundayFirst as getDayOfWeek, getUniqueSpecialFormEvent } from 'utils/common'
import { RootStore } from '.'

export interface IEventForm extends Omit<IEvent, 'days'> {
  days?: WeekDayEnum[]
  weekDay?: WeekDayEnum[]
}

export const defaultFormData: IEventForm = {
  details: '',
  title: '',
  start_time: new Date(),
  end_time: dayjs().add(1, 'hour').toDate(),
  startDate: new Date(),
  endDate: new Date(),
  day: undefined,
  event_id: undefined,
}

export const filterRelationSpecial: Record<string, unknown> = {
  include: [
    {
      relation: 'eventSpecials',
      scope: {
        include: [
          {
            relation: 'special',
          },
        ],
      },
    },
  ],
}

class EventStore {
  rootStore: RootStore

  eventDetail: IEvent = {}
  eventList: IEvent[] = []
  todayEvents: IEvent[] = []
  weeklyEvents: IEvent[] = []
  weeklyEventCount: number = 0
  oneTimeEvents: IEvent[] = []

  //* INFO: form state
  editingEvents: IEvent[] = []
  editingEventId?: number = 0
  formData: IEventForm = defaultFormData
  reviewData: IEventForm = defaultFormData

  constructor(rootStore: RootStore) {
    //TODO: may need in some special case
    // makeObservable(this, {
    //   eventDetail: observable,
    //   eventList: observable,
    //   todayEvents: observable,
    //   weeklyEvents: observable,
    //   oneTimeEvents: observable,
    //   editingEvents: observable,
    //   editingEventId: observable,
    //   formData: observable,
    //   reviewData: observable,
    //   getTablesData: action,
    //   createNewEvent: action,
    //   fetchEventList: action,
    //   fetchEventDetail: action,
    //   editEvent: action,
    //   resetStore: action,
    //   setReviewData: action,
    //   unsetEditingEvents: action,
    // })

    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  getTablesData = flow(function* (this: EventStore) {
    const { todayEvents, weeklyEvents, oneTimeEvents } = yield getEventTableData()
    this.todayEvents = todayEvents
    this.weeklyEvents = weeklyEvents
    this.oneTimeEvents = oneTimeEvents
  })

  createNewEvent = flow(function* (this: EventStore, data: IEvent) {
    const newEvent = yield createNewEvent(data)
    return newEvent
  })

  fetchEventList = flow(function* (this: EventStore, filter: Record<string, unknown> = {}) {
    this.eventList = yield getEventList(filter)
  })

  fetchEventDetail = flow(function* (this: EventStore, event_id: number) {
    this.editingEventId = event_id
    const event = yield getEventDetail(event_id, filterRelationSpecial)
    let days: WeekDayEnum[] = []
    if ((event?.day ?? -1) > -1) {
      const daysList = (event?.day ?? 0).toString().split('')
      days = daysList.map((day: string) => getDayOfWeek(parseInt(day)).toLowerCase() as WeekDayEnum)
    }
    const specials: ISpecial[] = getUniqueSpecialFormEvent(event)
    this.formData = {
      event_id,
      title: event?.title ?? '',
      details: event?.details ?? '',
      start_time: dayjs(event?.start_time).toDate(),
      end_time: dayjs(event?.end_time).toDate(),
      startDate: dayjs(event?.start_time).toDate(),
      endDate: dayjs(event?.end_time).toDate(),
      days,
      specials,
    }
    this.reviewData = { ...this.reviewData, specials }
  })

  editEvent = flow(function* (this: EventStore, data: IEvent) {
    yield updateEvent(data)
  })

  resetStore() {
    this.eventDetail = {}
    this.eventList = []
    this.oneTimeEvents = []
    this.weeklyEvents = []
    this.todayEvents = []
    this.editingEvents = []
    this.editingEventId = undefined
    this.formData = defaultFormData
  }

  setReviewData(data: IEvent | any): void {
    this.reviewData = data
  }

  unsetEditingEvents() {
    this.editingEvents = []
    this.editingEventId = undefined
    this.formData = defaultFormData
  }
}
export default EventStore
