import { subHours } from 'date-fns'
import get from 'lodash/get'
import { createManyWeeklySpecials, deleteSpecial, editSpecial } from 'Api/special'
import { ISpecial } from 'constants/interfaces'
import { getValidArray } from 'utils/common'

export async function bulkEditRelatedSpecials(
  editingSpecials: ISpecial[],
  days: boolean[],
  previousDays: boolean[],
  specialData: ISpecial
) {
  delete specialData.occurDays
  const createPromises = []
  const deletePromises = []
  const editPromises = []
  const editingSpecialsArray: ISpecial[] = getValidArray(editingSpecials)
  for (let day = 0; day < 7; day++) {
    if (days[day] && !previousDays[day]) {
      createPromises.push(createManyWeeklySpecials({ ...specialData, day }))
    }

    if (!days[day] && previousDays[day]) {
      const matchedSpecial = editingSpecialsArray.find((special: ISpecial) => special.day === day)
      const specialId = get(matchedSpecial, 'special_id', '')
      if (specialId) {
        deletePromises.push(deleteSpecial(specialId))
      }
    }

    if (days[day] && previousDays[day]) {
      const matchedSpecial = editingSpecialsArray.find((special: ISpecial) => special.day === day)
      const specialId = get(matchedSpecial, 'special_id', '')
      if (specialId) {
        editPromises.push(editSpecial(specialId, { ...specialData, day }))
      }
    }

    await Promise.all([...createPromises, ...deletePromises, ...editPromises])
  }
}

export function ignoreTimezoneParsing(date: Date) {
  const hoursOffset = date.getTimezoneOffset() / 60
  return subHours(date, hoursOffset)
}
