import { ReactNode } from 'react'
import cx from 'classnames'
import styles from './title.module.scss'

interface IProps {
  children?: ReactNode
  onClick?: () => void
  small?: boolean
  isRequired?: boolean
  hasMarginTop?: boolean
  smallMargin?: boolean
  isBlock?: boolean
}

const Title = (props: IProps) => {
  const { children, onClick, small, isRequired, hasMarginTop, smallMargin, isBlock } = props
  return (
    <span
      className={cx({
        [styles.title]: true,
        [styles.small]: small,
        [styles.hasMarginTop]: hasMarginTop,
        [styles.smallMargin]: smallMargin,
        [styles.block]: isBlock,
      })}
      onClick={onClick}
    >
      {children}
      {isRequired && <span className={styles.requiredSymbol}> *</span>}
    </span>
  )
}

export default Title
