import { makeAutoObservable } from 'mobx'
import { LocalStorageItem } from 'constants/enums/localStorage'
import { RootStore } from '.'

class CommonStore {
  isSideBarCondensed = localStorage.getItem(LocalStorageItem.IS_SIDEBAR_CONDENSED) === 'true' ? true : false
  rootStore: RootStore

  constructor(rootStore: RootStore) {
    //TODO: may need in some special case
    // makeObservable(this, {
    //   isSideBarCondensed: observable,
    //   toggleCollapse: action,
    // })

    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  toggleCollapse(value?: boolean): void {
    if (value) {
      this.isSideBarCondensed = value
    } else {
      this.isSideBarCondensed = !this.isSideBarCondensed
    }
    localStorage.setItem(LocalStorageItem.IS_SIDEBAR_CONDENSED, this.isSideBarCondensed.toString())
  }
}

export default CommonStore
