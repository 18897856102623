import { ITableHeader } from 'types/table'
import { ITabHeader } from 'components/Tabs'

export function getHeaderList(showArchived?: boolean) {
  const baseHeader: ITableHeader[] = showArchived
    ? [
        {
          Header: 'Published',
          accessor: 'published',
        },
        {
          Header: 'Archived',
          accessor: 'archived',
        },
      ]
    : [
        {
          Header: 'Published',
          accessor: 'published',
        },
      ]
  const headers: ITableHeader[] = [
    {
      Header: 'General',
      columns: [
        ...baseHeader,
        {
          Header: 'Details',
          accessor: 'details',
        },
        {
          Header: 'Date/Time',
          accessor: 'dateTime',
        },
      ],
    },

    {
      Header: 'Impressions',
      columns: [
        {
          Header: 'Last week',
          accessor: 'lastWeek',
        },
        {
          Header: 'This week',
          accessor: 'thisWeek',
        },
        {
          Header: 'Avg/week',
          accessor: 'avgWeek',
        },
      ],
    },
  ]

  return headers
}
export const mockTableData = [
  {
    published: new Date(),
    archived: new Date(),
    details: 'BOGO U-Call-It',
    date: new Date(),
    dateTime: '3:00PM - 8:00PM',
    lastWeek: '129 +22%',
    thisWeek: '89 +22%',
    avgWeek: '12.8',
  },
  {
    published: new Date(),
    archived: new Date(),
    details: 'BOGO U-Call-It',
    date: new Date(),
    dateTime: '3:00PM - 8:00PM',
    lastWeek: '129 +22%',
    thisWeek: '89 +22%',
    avgWeek: '12.8',
  },
  {
    published: new Date(),
    archived: new Date(),
    details: 'BOGO U-Call-It',
    date: new Date(),
    dateTime: '3:00PM - 8:00PM',
    lastWeek: '129 +22%',
    thisWeek: '89 -22%',
    avgWeek: '12.8',
  },
  {
    published: new Date(),
    archived: new Date(),
    details: 'BOGO U-Call-It',
    date: new Date(),
    dateTime: '3:00PM - 8:00PM',
    lastWeek: '129 -22%',
    thisWeek: '89 +22%',
    avgWeek: '12.8',
  },
  {
    published: new Date(),
    archived: new Date(),
    details: 'BOGO U-Call-It',
    date: new Date(),
    dateTime: '3:00PM - 8:00PM',
    lastWeek: '129 +22%',
    thisWeek: '89 +22%',
    avgWeek: '12.8',
  },
  {
    published: new Date(),
    archived: new Date(),
    details: 'BOGO U-Call-It',
    date: new Date(),
    dateTime: '3:00PM - 8:00PM',
    lastWeek: '129 +22%',
    thisWeek: '89 -22%',
    avgWeek: '12.8',
  },
  {
    published: new Date(),
    archived: new Date(),
    details: 'BOGO U-Call-It',
    date: new Date(),
    dateTime: '3:00PM - 8:00PM',
    lastWeek: '129 +22%',
    thisWeek: '89 +22%',
    avgWeek: '12.8',
  },
]

export enum CardTitle {
  SPECIAL = 'special',
  EVENT = 'event',
  PROFILE = 'profile-view',
  FAVORITE = 'favourite',
}

export enum ChartUnit {
  IMPRESSION = 'impression',
  PROFILE = 'view',
  FAVORITE = 'favorite',
}

export enum CardString {
  SPECIAL = 'Amount of times your specials were seen on the AppyHour app',
  EVENT = 'Amount of times your events were seen on the AppyHour app',
  PROFILE = 'Amount of times customers visited your profile on the AppyHour app',
  FAVORITE = 'Customers who have favorited your establishment',
}

export const headers: ITabHeader[] = [
  {
    label: 'SPECIALS',
    tabId: 'specials',
  },
  {
    label: 'EVENTS',
    tabId: 'events',
  },
]
