import { ReactChild, ReactNode } from 'react'
import cx from 'classnames'
import styles from './tableWrapper.module.scss'

interface ITableWrapperProps {
  children: ReactChild | ReactNode
  hasDivWrapper?: boolean
  isScrollAble?: boolean
}

const TableWrapper = (props: ITableWrapperProps) => {
  const { children, hasDivWrapper = false, isScrollAble } = props
  return hasDivWrapper ? <div className={cx({ [styles.scrollAble]: isScrollAble })}>{children}</div> : <>{children}</>
}

export default TableWrapper
