import cx from 'classnames'
import { Container, Row } from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import ModalDialog from 'components/ModalDialog'
import modalStyles from '../../addManagerModal.module.scss'
import styles from './index.module.scss'

interface ISuccessModalModal {
  isOpen: boolean | undefined
  className?: string
  setOpen: (value: boolean) => void
  setParentOpen: (value: boolean) => void
}

const SuccessModal = (props: ISuccessModalModal) => {
  const { isOpen, className, setOpen, setParentOpen } = props

  return (
    <ModalDialog
      title={'Add Manager'}
      size="sm"
      toggle={() => setOpen(false)}
      isOpen={isOpen}
      classNameHeader={modalStyles.header}
      className={cx(modalStyles.modal, className)}
      footer={
        <Container>
          <Row className={styles.contentRow}>
            <Button
              color="primary"
              type="button"
              onClick={() => {
                setOpen(false)
                setParentOpen(false)
              }}
            >
              Done
            </Button>
          </Row>
        </Container>
      }
    >
      <Container>
        <Row className={styles.contentRow}>
          <Icon icon="check-circle" className={styles.checkCircleIcon} />
        </Row>
        <Row className={styles.contentRow}>
          <div>Account manager created successfully!</div>
          <div>
            If you have any issue with the process, please <span className={styles.emailUs}>email us</span> for support.
          </div>
        </Row>
      </Container>
    </ModalDialog>
  )
}

export default SuccessModal
