import { api, errorHandler } from 'Api'
import { IServerError } from 'Api/constants'
import { IEventSpecial, ISpecial } from 'constants/interfaces'

export async function getSpecialTableData() {
  try {
    const response = await api.get(`/specials/table`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function createExclusiveSpecial(special: ISpecial) {
  try {
    const response = await api.post(`/specials/exclusive`, special)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function createSpecial(special: ISpecial) {
  try {
    const response = await api.post(`/specials/specials-event`, special)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function createEventSpecial(eventSpecial: IEventSpecial) {
  try {
    const response = await api.post(`/event-specials`, eventSpecial)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function checkExclusiveSpecial(startTime: Date) {
  try {
    const response = await api.post(`/specials/exclusive/check-available`, { startTime })
    return response?.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function createManyWeeklySpecials(special: ISpecial) {
  try {
    const response = await api.post(`/specials`, special)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function deleteSpecial(id: number) {
  try {
    const response = await api.delete(`/specials/${id}`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function deleteManySpecial(id: number) {
  try {
    const response = await api.delete(`specials/many-specials/${id}`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function editSpecial(id: number, data: ISpecial) {
  try {
    const response = await api.patch(`/specials/${id}`, data)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}
