import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Row as ReactTableRow } from 'react-table'
import { Row, Col } from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { defaultExclusiveExpandBuilder } from 'components/Pages/SpecialPage/utils'
import Table from 'components/Table'
import { getDateOfStartOfWeek, getStartAndEndOfWeek } from 'utils/common'
import { getHeaderList, SpecialType } from '../../../../constants'
import SubTable from '../../../SubTable'
import CreateSpecialModal from '../CreateExclusiveSpecialModal'
import DeleteSpecialModal from '../DeleteExclusiveSpecialModal'
import ReviewSpecialModal from '../ReviewExclusiveSpecialModal'
import WeeklyAction from './components/WeeklyAction'
import styles from './expandableCell.module.scss'

interface ExpandableCellProps {
  row: any
  value: unknown
}

const ExpandableCell = (props: ExpandableCellProps) => {
  const { row, value } = props
  return <span {...row.getToggleRowExpandedProps()}>{value}</span>
}

const TableRow = (title: string) => {
  return (
    <div className={styles.tableRows}>
      <Icon icon="angle-right" className={styles.iconStyle} />
      <div className={styles.rowLabel}>{title}</div>
    </div>
  )
}

const remainingStatus = (remaining: number) => (
  <span className={styles.remain}>
    <b>{remaining}</b> special(s) remaining
  </span>
)

const ExclusiveSpecial = () => {
  const { specialStore, establishmentStore } = useStore()
  const { currentTimezone } = establishmentStore
  const { exclusiveSpecials, openExclusiveReviewModal, openExclusiveCreateModal, isDeleteModal } = specialStore
  const dataInTable = Array.isArray(exclusiveSpecials)
    ? exclusiveSpecials.map((specialInDay, index) => {
        const title = getStartAndEndOfWeek(index, currentTimezone)
        const currentSpecials = get(specialInDay, 'current', [])
        const upcomingSpecials = get(specialInDay, 'upcoming', [])
        const specials = [...currentSpecials, ...upcomingSpecials]
        return {
          specials,
          productType: TableRow(title),
          status: remainingStatus(get(specialInDay, 'remaining', 0)),
          actions: (
            <WeeklyAction
              initialValue={getDateOfStartOfWeek(index, currentTimezone)}
              disable={get(specialInDay, 'remaining', 0) === 0}
            />
          ),
        }
      })
    : []

  function handleReview(): void {
    specialStore.setOpenReviewModal(true, SpecialType.EXCLUSIVE)
    specialStore.setOpenCreateModal(false, SpecialType.EXCLUSIVE)
  }

  function handleEdit(): void {
    specialStore.setOpenReviewModal(false, SpecialType.EXCLUSIVE)
    specialStore.setOpenCreateModal(true, SpecialType.EXCLUSIVE)
  }

  function toggleReview(): void {
    specialStore.setOpenReviewModal(!openExclusiveReviewModal, SpecialType.EXCLUSIVE)
  }

  function toggleDelete(): void {
    specialStore.setOpenReviewModal(!openExclusiveReviewModal, SpecialType.EXCLUSIVE, true)
  }

  function toggleEdit(): void {
    specialStore.setOpenCreateModal(!openExclusiveCreateModal, SpecialType.EXCLUSIVE)
  }

  function handleCreate(): void {
    specialStore.unsetFormData()
    specialStore.setOpenCreateModal(true, SpecialType.EXCLUSIVE)
  }

  return (
    <Row className={styles.container}>
      <Col md={12} className={styles.tableContainer}>
        <div className={styles.label}>
          These specials occur only once and will be highlighted and pinned to the top of the deal feed on the AppyHour
          mobile app. A push notification will be sent to users when this special begins.
        </div>
        <div className={styles.warning}>Limit 1 exclusive special per week</div>
        <div className={styles.controlSection}>
          <div className={styles.searchSection}></div>
          <div className={styles.buttonSection}>
            <Button onClick={handleCreate} color="primary">
              <Icon icon="plus" className={styles.iconStyle} />
              Create New
            </Button>
          </div>
        </div>
        <Table
          headerList={getHeaderList(ExpandableCell)}
          tableData={dataInTable}
          hasNoSort={false}
          defaultExpanded={defaultExclusiveExpandBuilder(exclusiveSpecials)}
          subComponent={(row: ReactTableRow, index: number) => (
            <SubTable isCompact type={SpecialType.EXCLUSIVE} specials={get(row, 'original.specials')} key={index} />
          )}
        />
        <CreateSpecialModal isOpening={openExclusiveCreateModal} toggleShow={toggleEdit} handleReview={handleReview} />
        {openExclusiveReviewModal && !isDeleteModal && (
          <ReviewSpecialModal isOpening={openExclusiveReviewModal} toggleShow={toggleReview} handleEdit={handleEdit} />
        )}
        {openExclusiveReviewModal && isDeleteModal && (
          <DeleteSpecialModal isOpening={openExclusiveReviewModal && isDeleteModal} toggleShow={toggleDelete} />
        )}
      </Col>
    </Row>
  )
}

export default observer(ExclusiveSpecial)
