import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { Spinner } from 'reactstrap'
import Button from 'components/Button'
import Icon, { IconGroupPrefix } from 'components/Icon'
import Input from 'components/InputGroup/Input'
import Pagination, { LIMIT_PAGE_BREAK } from 'components/Table/components/Pagination'
import GridView from './components/GridView'
import Table from './components/TableView'
import ViewSwitcher from './components/ViewSwitcher'
import { EViewSwitcher, getHeaderList } from './constant'
import styles from './campaignPage.module.scss'

const CampaignPage = () => {
  const [viewType, setViewType] = useState<EViewSwitcher>(EViewSwitcher.TABLE_VIEW)

  const [searchText, setSearchText] = useState('')

  const { campaignStore } = useStore()
  const { allCampaigns, totalCampaigns, isLoading, setIsLoading, pageIndex, setPageIndex, error } = campaignStore

  useEffect(() => {
    if (error) {
      error?.impression &&
        error?.impression.includes('Query rate limit') &&
        toast.error('Too many call to MixPanel, try again later')
    }
  }, [allCampaigns])

  const loadCampaign = async (pageIndex: number) => {
    return campaignStore.getAllCampaigns(LIMIT_PAGE_BREAK, pageIndex === 1 ? 0 : (pageIndex - 1) * 10, searchText)
  }

  const handleFilter = async () => {
    setIsLoading(true)
    setPageIndex(1)
    await loadCampaign(1)
    setIsLoading(false)
  }

  useEffect(() => {
    loadCampaign(1).then(() => setIsLoading(false))
  }, [])

  const handlePageChange = async (pageIndex: number) => {
    setIsLoading(true)
    setPageIndex(pageIndex)
    await loadCampaign(pageIndex)
    setIsLoading(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.filterBar}>
        <div className={styles.filterGroup}>
          <Input
            className={styles.inputSearch}
            iconName="search"
            placeholder="Search campaign..."
            iconGroup={IconGroupPrefix.LAICON}
            addonType="append"
            addonClassname={styles.inputSearchAddon}
            value={searchText}
            setValue={setSearchText}
            onIconClick={handleFilter}
          />
          <Button className={styles.filterButton}>
            <Icon group={IconGroupPrefix.LAICON} icon="filter" />
            {` `}Filter
          </Button>
          <ViewSwitcher viewType={viewType} setViewType={setViewType} />
        </div>
        <Button className={styles.createButton}>
          <Icon icon="plus" group={IconGroupPrefix.LAICON} />
          {` `}Create New
        </Button>
      </div>
      {isLoading ? (
        <div className={cx(styles.tableViewWrapper, styles.spinnerWrapper)}>
          <Spinner className={styles.spinner} color="primary" />
        </div>
      ) : (
        <>
          {allCampaigns.length === 0 ? (
            <div className={styles.tableViewWrapper}>Sorry, No data is available</div>
          ) : (
            <>
              {viewType === EViewSwitcher.TABLE_VIEW ? (
                <div className={styles.tableViewWrapper}>
                  <Table headerList={getHeaderList()} tableData={allCampaigns} hasNoSort={true} />
                </div>
              ) : (
                <GridView data={allCampaigns} />
              )}
            </>
          )}
        </>
      )}
      <Pagination
        pagination={{
          includePagination: true,
          tableLength: totalCampaigns,
          pageIndex: pageIndex,
          gotoPage: handlePageChange,
        }}
      />
    </div>
  )
}

export default observer(CampaignPage)
