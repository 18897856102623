import ReactLoading from 'react-loading'
import styles from './loading.module.scss'

interface ILoadingProps {
  height?: number
  width?: number
  containerHeight?: string
}

const Loading = (props: ILoadingProps) => {
  const { height = 70, width = 40 } = props
  return (
    <div className={styles.loading}>
      <ReactLoading type="spin" color="#777777" height={height} width={width} />
    </div>
  )
}

export default Loading
