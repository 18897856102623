import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Row as ReactTableRow } from 'react-table'
import { Row, Col } from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { defaultWeeklyExpandBuilder } from 'components/Pages/SpecialPage/utils'
import Table from 'components/Table'
import { getDayOfWeek } from 'utils/common'
import { getHeaderList, SpecialType } from '../../../../constants'
import SubTable from '../../../SubTable'
import CreateSpecialModal from '../CreateWeeklySpecialModal'
import DeleteSpecialModal from '../DeleteWeeklySpecialModal'
import ReviewSpecialModal from '../ReviewWeeklySpecialModal'
import WeeklyAction from './components/WeeklyAction'
import styles from './expandableCell.module.scss'

interface ExpandableCellProps {
  row: any
  value: unknown
}

const ExpandableCell = (props: ExpandableCellProps) => {
  const { row, value } = props
  return <span {...row.getToggleRowExpandedProps()}>{value}</span>
}

const TableRow = (day: string) => {
  return (
    <div className={styles.tableRows}>
      <Icon icon="angle-right" className={styles.iconStyle} />
      <div className={styles.rowLabel}>{day}</div>
    </div>
  )
}

const RemainingStatus = (remaining: number) => (
  <span className={styles.remain}>
    <b>{remaining}</b> special(s) remaining
  </span>
)

const WeeklySpecial = () => {
  const { specialStore } = useStore()
  const { openWeeklyReviewModal, openWeeklyCreateModal, isDeleteModal } = specialStore
  const { weeklySpecials = [], specialsRemainingMap = [] } = specialStore

  const startWithMondayRemainingMap = [...specialsRemainingMap]
  const top = startWithMondayRemainingMap[0]
  startWithMondayRemainingMap.shift()
  startWithMondayRemainingMap.push(top)
  const startWithMondayWeeklySpecials = [...weeklySpecials]
  const sunday = startWithMondayWeeklySpecials[0]
  startWithMondayWeeklySpecials.shift()
  startWithMondayWeeklySpecials.push(sunday)
  const dataInTable = Array.isArray(startWithMondayWeeklySpecials)
    ? startWithMondayWeeklySpecials.map((specialInDay, day) => {
        const dayOfWeek = getDayOfWeek(day)
        const remaining = startWithMondayRemainingMap[day]
        return {
          specials: specialInDay,
          productType: TableRow(dayOfWeek),
          status: RemainingStatus(remaining),
          actions: <WeeklyAction disable={remaining === 0} day={dayOfWeek} />,
          isExpanded: get(specialInDay, 'length', 0),
        }
      })
    : []

  function handleReview(): void {
    specialStore.setOpenReviewModal(true, SpecialType.WEEKLY)
    specialStore.setOpenCreateModal(false, SpecialType.WEEKLY)
  }

  function handleEdit(): void {
    specialStore.setOpenReviewModal(false, SpecialType.WEEKLY)
    specialStore.setOpenCreateModal(true, SpecialType.WEEKLY)
  }

  function toggleReview(): void {
    specialStore.setOpenReviewModal(!openWeeklyReviewModal, SpecialType.WEEKLY)
  }

  function toggleDelete(): void {
    specialStore.setOpenReviewModal(!openWeeklyReviewModal, SpecialType.WEEKLY, true)
  }

  function toggleEdit(): void {
    specialStore.setOpenCreateModal(!openWeeklyCreateModal, SpecialType.WEEKLY)
  }

  function handleCreate(): void {
    specialStore.unsetFormData()
    specialStore.setOpenCreateModal(true, SpecialType.WEEKLY)
  }

  return (
    <Row className={styles.container}>
      <Col md={12} className={styles.tableContainer}>
        <div className={styles.label}>
          These specials will occur once per week on the day(s) and time specified. No push notification will be sent to
          users when this special begins.
        </div>
        <div className={styles.warning}>
          Limit 5 recurring specials per day (Event specials do not count against this limit).
        </div>
        <div className={styles.controlSection}>
          <div className={styles.searchSection}></div>
          <div className={styles.buttonSection}>
            <Button onClick={handleCreate} color="primary">
              <Icon icon="plus" className={styles.iconStyle} />
              Create New
            </Button>
          </div>
        </div>
        <Table
          headerList={getHeaderList(ExpandableCell)}
          subComponentStyle={styles.subComponent}
          tableData={dataInTable}
          defaultExpanded={defaultWeeklyExpandBuilder(startWithMondayWeeklySpecials)}
          hasNoSort={false}
          subComponent={(row: ReactTableRow, index: number) => (
            <SubTable isCompact type={SpecialType.WEEKLY} specials={get(row, 'original.specials')} key={index} />
          )}
        />
        <CreateSpecialModal isOpening={openWeeklyCreateModal} toggleShow={toggleEdit} handleReview={handleReview} />
        {openWeeklyReviewModal && !isDeleteModal && (
          <ReviewSpecialModal isOpening={openWeeklyReviewModal} toggleShow={toggleReview} handleEdit={handleEdit} />
        )}
        {openWeeklyReviewModal && isDeleteModal && (
          <DeleteSpecialModal isOpening={openWeeklyReviewModal} toggleShow={toggleDelete} />
        )}
      </Col>
    </Row>
  )
}

export default observer(WeeklySpecial)
