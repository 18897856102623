import { useEffect, useState } from 'react'
import get from 'lodash/get'
import has from 'lodash/has'
import { Controller, useFormContext } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { Container, Row, Col } from 'reactstrap'
import { Input } from 'reactstrap'
import { getRefsById } from 'Api/ref'
import ComboBox from 'components/ComboBox'
import Option from 'components/ComboBox/Option'
import ErrorMessage from 'components/ErrorMessage'
import Label from 'components/Label'
import ProfilePicture from './components/ProfilePicture'
import styles from './generalInformation.module.scss'

const GeneralInformation = () => {
  const { register, errors, control } = useFormContext()

  const [stateCodes, setSateCodes] = useState<any>([])

  async function getStateCodes() {
    const stateCodesResult = await getRefsById(24)
    let stateCodesArray
    if (stateCodesResult) {
      stateCodesArray = Object.keys(stateCodesResult).map((key) => {
        return get(stateCodesResult, key, '')
      })
      setSateCodes(stateCodesArray)
    }
  }

  useEffect(() => {
    getStateCodes()
  }, [])

  return (
    <Container className={styles.container} fluid>
      <Row className={styles.rowMarginBottom}>
        <Col className={styles.inputLeftCol} xs="12" md="3">
          <Label strong isRequired>
            Profile Picture
          </Label>
          <ProfilePicture />
        </Col>
      </Row>
      <Row className={styles.rowMarginBottom}>
        <Col className={styles.inputLeftCol} xs="12" md="6">
          <Label strong isRequired>
            Business Name
          </Label>
          <Input
            className={styles.inputCustom}
            placeholder="E.g: Appyhour"
            type="text"
            invalid={has(errors, 'name')}
            name="name"
            innerRef={register({ required: 'Business name is empty' })}
          />
          <ErrorMessage error={get(errors, 'name.message', '') as string} />
        </Col>
        <Col className={styles.inputRightCol} xs="12" md="6">
          <Label strong isRequired>
            Phone Number
          </Label>
          <Controller
            name="phone_num"
            control={control}
            alwaysShowMask={false}
            defaultValue=""
            rules={{ required: true }}
            render={({ value, onChange }) => (
              <InputMask mask="(999) 999-9999" maskChar=" " value={value} onChange={onChange}>
                {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    type="text"
                    name="phone_num"
                    required
                    placeholder="E.g: (000) 000-0000"
                    innerRef={register({ required: true })}
                    className={styles.inputCustom}
                  />
                )}
              </InputMask>
            )}
          />
        </Col>
      </Row>
      <Row className={styles.rowMarginBottom}>
        <Container>
          <Row className={styles.rowPaddingBottom}>
            <Col md="12">
              <Label strong isRequired>
                Address
              </Label>
              <Input
                className={styles.inputCustom}
                name="address_1"
                invalid={has(errors, 'address_1')}
                placeholder="E.g: 4224 Kemper Lane"
                innerRef={register({ required: 'Address is empty' })}
                type="text"
              />
              <ErrorMessage error={get(errors, 'address_1.message', '')} />
            </Col>
          </Row>
          <Row>
            <Col className={styles.inputLeftCol} xs="12" md="4">
              <Input
                className={styles.inputCustom}
                placeholder="E.g: West Valley City"
                invalid={has(errors, 'city')}
                type="text"
                name="city"
                innerRef={register({ required: 'City is empty' })}
              />
              <ErrorMessage error={get(errors, 'city.message', '')} />
            </Col>
            <Col className={styles.inputMiddleCol} xs="12" md="4">
              <ComboBox
                className={styles.customCombobox}
                name="state"
                placeholder="- Choose state -"
                invalid={has(errors, 'state')}
                innerRef={register({ required: 'State is empty' })}
              >
                {stateCodes.length > 0 &&
                  stateCodes.map((stateCode: any) => (
                    <Option
                      key={`ref-${stateCode.ref_code}`}
                      value={stateCode.ref_code}
                      className={styles.optionCustom}
                    >
                      {stateCode.ref_desc}
                    </Option>
                  ))}
              </ComboBox>
              <ErrorMessage error={get(errors, 'state.message', '')} />
            </Col>
            <Col className={styles.inputRightCol} xs="12" md="4">
              <Input
                className={styles.inputCustom}
                placeholder="ZIP"
                type="text"
                invalid={has(errors, 'zip')}
                name="zip"
                innerRef={register({ required: 'Zip is empty' })}
              />
              <ErrorMessage error={get(errors, 'zip.message', '')} />
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className={styles.rowMarginBottom}>
        <Container>
          <Row>
            <Col md="12">
              <Label strong>Website</Label>
              <Input
                className={styles.inputCustom}
                placeholder="https://"
                type="text"
                name="website"
                innerRef={register}
              />
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className={styles.rowMarginBottom}>
        <Container>
          <Row>
            <Col md="12">
              <Label strong>Reservation Link</Label>
              <Input
                className={styles.inputCustom}
                placeholder="https://"
                type="text"
                name="resWebsite"
                innerRef={register}
              />
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className={styles.rowMarginBottom}>
        <Container>
          <Row>
            <Col md="12">
              <Label strong>Menu Link</Label>
              <Input
                className={styles.inputCustom}
                placeholder="https://"
                type="text"
                name="menu_link"
                innerRef={register}
              />
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  )
}

export default GeneralInformation
