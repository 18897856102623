import { useMemo, ReactNode, Fragment } from 'react'
import cx from 'classnames'
import get from 'lodash/get'
import Skeleton from 'react-loading-skeleton'
import { useTable, useExpanded, Column, HeaderGroup } from 'react-table'
import Cell from './components/Cell'
import Pagination from './components/Pagination'
import SortIcon from './components/SortIcon'
import TableWrapper from './components/TableWrapper'
import styles from './table.module.scss'
export interface IPagination {
  includePagination?: boolean
  tableLength: number
  pageIndex: number
  gotoPage: (pageIndex: number) => void
}

interface ITableProps {
  tableData?: object[]
  headerList?: any[]
  subComponent?: any
  pagination?: IPagination
  isLoading?: boolean
  hasNoSort?: boolean
  columnWidth?: number
  isScrollAble?: boolean
  isManualSort?: boolean
  isStriped?: boolean
  isNotInitialState?: boolean
  bodyClassName?: string
  hasDivWrapper?: boolean
  defaultExpanded?: Record<string, boolean>
  subComponentStyle?: string
}

const Table = (props: ITableProps) => {
  const {
    tableData,
    headerList,
    subComponent,
    pagination = { includePagination: false, pageIndex: 1 },
    isLoading = false,
    hasNoSort,
    isScrollAble,
    isStriped = false,
    bodyClassName,
    hasDivWrapper = false,
    subComponentStyle,
    defaultExpanded = {},
  } = props

  const initialExpanded: any = {
    expanded: defaultExpanded,
  }

  const initialState: any = { ...initialExpanded }

  const columns: Column<object>[] = useMemo(() => headerList, [headerList]) || []
  const paginationComponent = pagination.includePagination ? <Pagination pagination={pagination} /> : null
  const loadingPaginationComponent = isLoading ? null : paginationComponent
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
    {
      columns,
      data: tableData as object[],
      initialState,
    },
    useExpanded
  )

  function showSortIcon(column: Column): ReactNode | string {
    return column.Header ? <SortIcon column={column} /> : ''
  }

  return (
    <>
      <TableWrapper isScrollAble={isScrollAble} hasDivWrapper={hasDivWrapper}>
        <table className={cx(styles.table)} {...getTableProps()}>
          <thead className={cx(styles.thead)}>
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } = headerGroup.getHeaderGroupProps()
              return (
                <tr key={`tr-${key}`} {...restHeaderGroup}>
                  {headerGroup.headers.map((column: HeaderGroup) => {
                    const { key, ...restColumnHeaderProps } = column.getHeaderProps()
                    return (
                      <th key={`th-${key}`} {...restColumnHeaderProps}>
                        {column.render('Header')}
                        &nbsp;
                        {hasNoSort ? '' : <span> {showSortIcon(column)} </span>}
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody
            className={cx(styles.tbody, bodyClassName, {
              [styles.isStriped]: isStriped,
              [styles.isExpanded]: !!subComponent,
            })}
            {...getTableBodyProps()}
          >
            {rows.map((row, index: number) => {
              prepareRow(row)
              const isCurrent: boolean = get(row, 'original.isCurrent', false) || false
              return (
                <Fragment key={`row-${index}`}>
                  {isLoading && <Skeleton className={styles.loadingBar} height={80} />}
                  <tr {...row.getRowProps()} className={cx({ [styles.active]: isCurrent })}>
                    {row.cells.map((cell) => {
                      const { key, ...restCell } = cell.getCellProps()
                      return <Cell key={`cell-${key}`} {...restCell} cell={cell} />
                    })}
                  </tr>
                  {subComponent &&
                    (get(row, 'isExpanded', false) ? (
                      <tr className={cx({ [styles.active]: isCurrent })}>
                        <td
                          colSpan={visibleColumns.length}
                          className={cx(styles.subComponentContainer, subComponentStyle)}
                        >
                          {subComponent(row)}
                        </td>
                      </tr>
                    ) : (
                      <tr style={{ display: 'none' }}></tr>
                    ))}
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </TableWrapper>
      {loadingPaginationComponent}
    </>
  )
}

export default Table
