import { Component } from 'react'
import spinner from 'Assets/images/loading.svg'
import { inject, observer } from 'mobx-react'
import SpinnerStore from '../../stores/spinnerStore'
import styles from './spinner.module.scss'

interface ISpinnerProps {
  spinnerStore?: SpinnerStore
}

const Spinner = inject('spinnerStore')(
  observer(
    class Spinner extends Component<ISpinnerProps> {
      render() {
        return this.props.spinnerStore?.isLoading ? (
          <div className={styles.page__overlay__wrapper}>
            <div className={styles.center__frame}>
              <img alt="loading" src={spinner}></img>
            </div>
          </div>
        ) : (
          <></>
        )
      }
    }
  )
)

export default Spinner
