import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { useForm } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Form, FormGroup, Label } from 'reactstrap'
import { checkExists, userHasChangedPassword } from 'Api/auth'
import ButtonWithIcon from 'components/ButtonWithIcon'
import Input from 'components/InputGroup/Input'
import { RoleType } from 'constants/enums/role'
import routes from 'routes'
import RememberMe from './components/RememberMe'
import RoleOption from './components/RoleOption'
import styles from './loginPage.module.scss'

interface LoginPageProps {
  title?: string
}

interface ILoginForm {
  username: string
  password: string
}

const LoginPage = (props: LoginPageProps) => {
  const { title } = props
  const methods = useForm<ILoginForm>()
  const { handleSubmit, clearErrors } = methods
  const history = useHistory()
  const { authStore } = useStore()
  const [role, setRole] = useState<RoleType>(RoleType.ESTABLISHMENT)
  const [buttonType, setButtonType] = useState<'button' | 'submit'>('button')
  const [isRemember, setIsRemember] = useState(false)
  const [isUsernameInvalid, setIsUsernameInvalid] = useState(false)
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [inputPasswordType, setInputPasswordType] = useState('password')
  const [usernameMessage, setUsernameMessage] = useState('')
  const [passwordMessage, setPasswordMessage] = useState('')
  const [isUsernameVerified, setIsUsernameVerified] = useState(false)
  const [isContinueButtonDisable, setIsContinueButtonDisable] = useState(false)
  const location = useLocation()
  const currentUserName = get(location, 'state.username', '')

  async function handleLogin() {
    if (username && password) {
      clearErrors()
      const result: boolean = await authStore.login(username, password, role, isRemember)
      if (result) {
        if (role === RoleType.SUPPLIER) {
          history.push({
            pathname: routes.campaign.value,
            state: {
              previousUrl: routes.campaign.value,
              type: RoleType,
            },
          })
          authStore.setRole(RoleType.SUPPLIER)
        } else {
          history.push(routes.special.value)
          authStore.setRole(RoleType.ESTABLISHMENT)
        }
      }
      if (!result) {
        setIsPasswordInvalid(true)
        setPasswordMessage('Wrong password !')
      }
    }
  }

  useEffect(() => {
    if (isShowPassword) {
      setInputPasswordType('text')
    } else {
      setInputPasswordType('password')
    }
  }, [isShowPassword])

  useEffect(() => {
    if (isContinueButtonDisable) {
      setIsContinueButtonDisable(false)
    }
  }, [username])

  useEffect(() => {
    if (currentUserName) {
      setUsername(currentUserName)
    }
  }, [currentUserName])

  async function handleContinueButtonClick() {
    if (username) {
      try {
        const result = await checkUserExist(username, role)
        if (result) {
          setIsUsernameVerified(true)
          authStore.setUsername(username)
          setIsUsernameInvalid(false)
          setUsernameMessage('')
          setButtonType('submit')
        } else {
          setPassword('')
          setIsUsernameVerified(false)
          setIsUsernameInvalid(true)
          setUsernameMessage("We couldn't find that username. Please try again")
          setIsContinueButtonDisable(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  function toggleShowPassword() {
    setIsShowPassword(!isShowPassword)
  }

  async function checkUserExist(username: string, role: RoleType) {
    let result: boolean = false
    result = await checkExists(username, role)
    if (result) {
      result = await userHasChangedPassword(username, role)
      if (!result) {
        return history.push({
          pathname: routes.firstLogin.value,
          state: { username },
        })
      }
    }
    return !!result
  }

  return (
    <Form {...methods} onSubmit={handleSubmit(handleLogin)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.selectRoleArea}>
        <FormGroup>
          <Label className={styles.label}>Select Your Role</Label>
          <RoleOption role={role} setRole={setRole} />
        </FormGroup>
      </div>
      <div className={styles.userInfoArea}>
        <FormGroup>
          <Label className={styles.label}>Username</Label>
          <Input
            type="text"
            disabled={isUsernameVerified}
            invalid={isUsernameInvalid}
            placeholder="Ex: john.doe"
            setValue={setUsername}
            message={usernameMessage}
            value={username}
          />
        </FormGroup>
        {!isUsernameVerified ? (
          <></>
        ) : (
          <>
            <FormGroup>
              <Label className={styles.label}>Password</Label>
              <Input
                addonType="append"
                addonClassname={styles.inputAppendIcon}
                iconName="eye"
                className={cx(styles.passwordInput)}
                type={inputPasswordType}
                invalid={isPasswordInvalid}
                setValue={setPassword}
                onIconClick={toggleShowPassword}
                message={passwordMessage}
              />
            </FormGroup>
            <FormGroup className={styles.rememberForgetGroup}>
              <RememberMe isRemember={isRemember} setIsRemember={setIsRemember} />
              <Link to={routes.forgotPassword.value + `?type=${role}`}>
                <div className={styles.forgotPassword}>Forgot Password?</div>
              </Link>
            </FormGroup>
          </>
        )}
      </div>
      <ButtonWithIcon
        content="Continue"
        type={buttonType}
        className={cx(styles.continueButton, {
          [styles.disableContinueButton]: isContinueButtonDisable,
        })}
        onClick={handleContinueButtonClick}
        disable={isContinueButtonDisable}
      />
    </Form>
  )
}

export default LoginPage
