import cx from 'classnames'
import { ICampaign } from 'constants/interfaces'
import styles from './styles.module.scss'

interface ILabelTitleGroup {
  campaign: ICampaign
}

const LabelTitleGroup = (props: ILabelTitleGroup) => {
  const { title, label, fontColor, textAlign, verticalAlign } = props.campaign
  return (title !== '' || label !== '') && fontColor && textAlign && verticalAlign ? (
    <div className={styles.container} style={{ textAlign: textAlign, color: fontColor }}>
      <div className={styles.wrapper} style={{ verticalAlign: verticalAlign }}>
        <div
          className={cx(styles.content, {
            [styles.whiteFontColor]: fontColor === 'white',
            [styles.blackFontColor]: fontColor === 'black',
          })}
        >
          <div>{title}</div>
          <div>{label}</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default LabelTitleGroup
