import { useEffect, useState } from 'react'
import { Datum } from '@nivo/line'
import classNames from 'classnames'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Col, Row } from 'reactstrap'
import { Line } from 'components/Chart/Line'
import Icon, { IconGroupPrefix } from 'components/Icon'
import Input from 'components/Input'
import InputDropDown, { IOption } from 'components/InputGroup/InputDropdown'
import { Switcher } from 'components/Switcher'
import Table from 'components/Table'
import Tabs, { ITabContent } from 'components/Tabs'
import { IDateValue, IListDataItem, IWeeklyImpressions } from 'constants/interfaces'
import { checkValidArray, getValidArray, setState } from 'utils/common'
import ImpressionValue from './components/ImpressionValue'
import { getHeaderList, CardTitle, headers, CardString, ChartUnit } from './constants'
import { getChangesString, getDealImage, getPercentage, getToolTipGroup, loading, options } from './utils'
import styles from './analyticsPage.module.scss'
dayjs.extend(advancedFormat)

const AnalyticsPage = () => {
  const { analyticsStore, authStore } = useStore()
  const { analyticsData, dashboardData, isLoading } = analyticsStore
  const { establishment } = authStore
  const [showArchived, setShowArchived] = useState(false)
  const [daysAgo, setDaysAgo] = useState<IOption>(options[2])
  const [showChart, setShowChart] = useState<CardTitle>(CardTitle.SPECIAL)
  const [specialOpen, setSpecialOpen] = useState<boolean>(false)
  const [eventOpen, setEventOpen] = useState<boolean>(false)
  const [favoriteOpen, setFavoriteOpen] = useState<boolean>(false)
  const [profileOpen, setProfileOpen] = useState<boolean>(false)
  const isEvent: boolean = showChart === CardTitle.EVENT
  const isSpecial: boolean = showChart === CardTitle.SPECIAL
  const isFavorite: boolean = showChart === CardTitle.FAVORITE
  const isProfileView: boolean = showChart === CardTitle.PROFILE
  const eventData: IDateValue[] = dashboardData?.eventData ?? []
  const specialData: IDateValue[] = dashboardData?.specialData ?? []
  const favoriteData: IDateValue[] = dashboardData?.favoriteData ?? []
  const profileViewData: IDateValue[] = dashboardData?.profileViewData ?? []
  const weeklyImpressions: IWeeklyImpressions = dashboardData?.weeklyImpressions ?? {}
  const eventDataChanges: string = dashboardData?.eventDataChanges ?? ''
  const specialDataChanges: string = dashboardData?.specialDataChanges ?? ''
  const favoriteDataChanges: string = dashboardData?.favoriteDataChanges ?? ''
  const profileViewDataChanges: string = dashboardData?.profileViewDataChanges ?? ''

  useEffect(() => {
    analyticsStore.setDashboardData({
      specialData: analyticsData?.special?.chartData,
      specialDataChanges: getChangesString(analyticsData?.special, daysAgo),
      eventData: analyticsData?.event?.chartData,
      eventDataChanges: getChangesString(analyticsData?.event, daysAgo),
      profileViewData: analyticsData?.profileView?.chartData,
      profileViewDataChanges: getChangesString(analyticsData?.profileView, daysAgo),
      favoriteData: analyticsData?.favorite?.chartData,
      favoriteDataChanges: getChangesString(analyticsData?.favorite, daysAgo),
      weeklyImpressions: analyticsData?.weeklyImpressions,
    })
  }, [analyticsData])

  useEffect(() => {
    analyticsStore.getAnalyticsData(establishment, daysAgo?.value ?? 7)
  }, [daysAgo, establishment])

  function getTableData(isEvent = false) {
    const tableData = isEvent ? weeklyImpressions?.event : weeklyImpressions?.special || []
    return getValidArray<IListDataItem>(tableData).map((item) => {
      const views = item?.views ?? {}
      const sourceImg: string = 'https://app.appyhourmobile.com/assets/img'
      return {
        published: (
          <div>
            {dayjs(item?.createdAt).format('MM/DD/YYYY')}
            <br />
            {dayjs(item?.createdAt).format('HH:mm A')}
          </div>
        ),
        archived: get(item, 'deleted', '') ? (
          <div>
            {dayjs(item?.deleted).format('MM/DD/YYYY')}
            <br />
            {dayjs(item?.deleted).format('HH:mm A')}
          </div>
        ) : (
          <></>
        ),
        details: isEvent ? (
          <div className={styles.icon}>{item?.details}</div>
        ) : (
          <div className={styles.icon}>
            {item?.refId && (
              <img src={`${sourceImg}/${getDealImage(item?.refId ?? 0)}`} alt={'deal-type'} className={styles.img} />
            )}
            {item?.details}
          </div>
        ),
        dateTime: (
          <div>
            {dayjs(item?.date).format('dddd')}
            <br />
            {dayjs(item?.start).format('HH:mmA')} - {dayjs(item?.end).format('HH:mmA')}
          </div>
        ),
        lastWeek: <ImpressionValue value={`${views?.lastWeek ?? 0}${getPercentage(views?.changes?.lastWeek ?? 0)}`} />,
        thisWeek: <ImpressionValue value={`${views?.thisWeek ?? 0}${getPercentage(views?.changes?.thisWeek ?? 0)}`} />,
        avgWeek: <ImpressionValue value={String(views?.averageSincePublished?.toFixed(1) ?? 0)} />,
      }
    })
  }

  const tableProps = {
    bodyClassName: styles.tbody,
    headerList: getHeaderList(showArchived),
    hasNoSort: true,
    hasDivWrapper: true,
    isScrollAbleL: true,
    isLoading,
  }
  function getContent(isEvent: boolean): React.ReactNode {
    return (
      <>
        <Switcher value={showArchived} setValue={setShowArchived} label={'Show Archived'} />
        <Table tableData={getTableData(isEvent)} {...tableProps} />
      </>
    )
  }

  const contents: ITabContent[] = [
    { tabId: 'specials', content: getContent(false) },
    { tabId: 'events', content: getContent(true) },
  ]

  function getIconProps(isThis: boolean) {
    return { className: styles.cornerIcon, icon: isThis ? 'compress' : 'expand', group: IconGroupPrefix.LAICON }
  }

  return (
    <>
      <Row className={styles.filterContainer}>
        <Col md={4} xl={6}>
          <div className={styles.sectionTitle}>Analytics Overview</div>
        </Col>
        <Col md={8} xl={6}>
          <Row>
            <Col md={2}>
              <div className={styles.label}>Results for</div>
            </Col>
            <Col md={4} className={styles.dropdown}>
              <InputDropDown onChange={setDaysAgo} options={options} defaultValue={options[2]} hasNoSeparator />
            </Col>
            <Col md={6} className={styles.input}>
              <Input
                disabled
                value={`${dayjs()
                  .subtract(daysAgo?.value ?? 1, 'day')
                  .format('MMM Do')} - ${dayjs().format('MMM Do')}`}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={styles.cardWrapper}>
        <div className={classNames(styles.cardContainer, { [styles.hasChart]: isSpecial })}>
          <div className={styles.cardTitle}>Special Impressions</div>
          {isSpecial && getToolTipGroup(CardString.SPECIAL, CardTitle.SPECIAL, specialOpen, setSpecialOpen)}
          <Icon {...getIconProps(isSpecial)} onClick={() => setState<CardTitle>(CardTitle.SPECIAL, setShowChart)} />
          <Row className={styles.cardBody}>
            <Col md={isSpecial ? 3 : 12}>
              <ImpressionValue value={specialDataChanges ?? ''} isInCard isNoSubString={!isSpecial} />
            </Col>
            {isSpecial && checkValidArray(specialData ?? []) && !isLoading && (
              <Col md={9}>
                <Line data={[{ id: CardTitle.SPECIAL, data: specialData as Datum[] }]} unit={ChartUnit.IMPRESSION} />
              </Col>
            )}
            {loading(isLoading, isSpecial)}
          </Row>
        </div>
        <div className={classNames(styles.cardContainer, { [styles.hasChart]: isEvent })}>
          <div className={styles.cardTitle}>Event Impressions</div>
          {isEvent && getToolTipGroup(CardString.EVENT, CardTitle.EVENT, eventOpen, setEventOpen)}
          <Icon {...getIconProps(isEvent)} onClick={() => setState<CardTitle>(CardTitle.EVENT, setShowChart)} />
          <Row className={styles.cardBody}>
            <Col md={isEvent ? 3 : 12}>
              <ImpressionValue value={eventDataChanges ?? ''} isInCard isNoSubString={!isEvent} />
            </Col>
            {isEvent && checkValidArray(eventData) && !isLoading && (
              <Col md={9}>
                <Line data={[{ id: CardTitle.EVENT, data: eventData as Datum[] }]} unit={ChartUnit.IMPRESSION} />
              </Col>
            )}
            {loading(isLoading, isEvent)}
          </Row>
        </div>
        <div className={classNames(styles.cardContainer, { [styles.hasChart]: isFavorite })}>
          <div className={styles.cardTitle}>Favorites</div>
          {isFavorite && getToolTipGroup(CardString.FAVORITE, CardTitle.FAVORITE, favoriteOpen, setFavoriteOpen)}
          <Icon {...getIconProps(isFavorite)} onClick={() => setState<CardTitle>(CardTitle.FAVORITE, setShowChart)} />
          <Row className={styles.cardBody}>
            <Col md={isFavorite ? 3 : 12}>
              <ImpressionValue value={favoriteDataChanges ?? ''} isInCard isNoSubString={!isFavorite} />
            </Col>
            {isFavorite && checkValidArray(favoriteData) && !isLoading && (
              <Col md={9}>
                <Line data={[{ id: CardTitle.FAVORITE, data: favoriteData as Datum[] }]} unit={ChartUnit.FAVORITE} />
              </Col>
            )}
            {loading(isLoading, isFavorite)}
          </Row>
        </div>
        <div className={classNames(styles.cardContainer, { [styles.hasChart]: isProfileView })}>
          <div className={styles.cardTitle}>Profile views</div>
          {isProfileView && getToolTipGroup(CardString.PROFILE, CardTitle.PROFILE, profileOpen, setProfileOpen)}
          <Icon {...getIconProps(isProfileView)} onClick={() => setState<CardTitle>(CardTitle.PROFILE, setShowChart)} />
          <Row className={styles.cardBody}>
            <Col md={isProfileView ? 3 : 12}>
              <ImpressionValue value={profileViewDataChanges ?? ''} isInCard isNoSubString={!isProfileView} />
            </Col>
            {isProfileView && checkValidArray(profileViewData) && !isLoading && (
              <Col md={9}>
                <Line data={[{ id: CardTitle.PROFILE, data: profileViewData as Datum[] }]} unit={ChartUnit.PROFILE} />
              </Col>
            )}
            {loading(isLoading, isProfileView)}
          </Row>
        </div>
      </Row>
      <div className={styles.sectionTitle}>Weekly Impressions</div>
      <div className={styles.whiteBackground}>
        <Tabs headers={headers} contents={contents} />
      </div>
    </>
  )
}

export default observer(AnalyticsPage)
