import { useRef, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Col, Row, Input } from 'reactstrap'
import Button from 'components/Button'
import DateTimePicker from 'components/DateTimePicker'
import ModalDialog from 'components/ModalDialog'
import Title from 'components/Title'
import { IEvent } from 'constants/interfaces'
import { defaultFormData, IEventForm } from 'stores/eventStore'
import SpecialList from '../../../SpecialList'
import ChooseWeekDay from './components/ChooseWeekDay'
import { WeekDayEnum } from './constants'
import styles from './createEventModal.module.scss'

export interface ICreateEventModal {
  isOpening: boolean
  toggleShow: () => void
  handleReview?: () => void
  formId?: string
}

const CreateEventModal = (props: ICreateEventModal) => {
  const { isOpening = false, toggleShow, handleReview, formId = 'weekly-event-form' } = props
  const { eventStore } = useStore()
  const weekDaySelectionRef = useRef<null | HTMLDivElement>(null)
  const { formData, editingEventId = 0, weeklyEvents } = eventStore
  const weeklyEventCount: number = map(
    weeklyEvents.filter((event) => event.event_id !== editingEventId),
    (e) => String(e.day)
  ).join('').length
  const methods = useForm<IEventForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormData,
  })
  const { days } = formData
  const { register, control, reset, errors, setValue, handleSubmit } = methods
  const [weekDay, setWeekDay] = useState<WeekDayEnum[]>([])
  const startTime: Date = useWatch({ control, name: 'start_time' }) || dayjs().toDate()

  function scrollToWeekDaySelection() {
    if (weekDaySelectionRef !== null && weekDaySelectionRef.current !== null) {
      weekDaySelectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  useEffect(() => {
    register('days')
    register('previousDays')
  }, [register])

  useEffect(() => {
    setValue('end_time', dayjs(startTime).add(1, 'hour').toDate())
  }, [startTime])

  useEffect(() => {
    if (days?.length ?? 0) {
      setWeekDay(days as WeekDayEnum[])
    }
  }, [days?.length])

  async function onSubmit(data: IEvent) {
    if (isEmpty(weekDay) && scrollToWeekDaySelection) {
      scrollToWeekDaySelection()
    }
    if (isEmpty(errors) && !isEmpty(weekDay)) {
      setValue('days', weekDay)
      delete data?.startDate
      delete data?.endDate
      delete data?.day
      set(data, 'days', weekDay)
      try {
        if (!data?.start_time || !data?.end_time || data.start_time >= data.end_time) {
          throw new Error('Start date must less than end date')
        }
        if (editingEventId) {
          set(data, 'event_id', editingEventId)
          await eventStore.editEvent(data)
          toggleShow()
        } else {
          const newEvent: IEvent = await eventStore.createNewEvent(data)
          eventStore.setReviewData({ ...data, event_id: newEvent?.event_id ?? 0, weekDay })
          !!handleReview ? handleReview() : toggleShow()
        }
        await eventStore.rootStore.establishmentStore.fetchEstablishmentEvent()
        resetForm()
        toast.success(`${editingEventId ? 'Edit' : 'Create'} weekly event successfully`)
      } catch (error) {
        let errorMsg = error?.message ?? `${editingEventId ? 'Edit' : 'Create'} weekly event failed`
        if (errorMsg.includes(409)) {
          errorMsg = 'There is no weekly event left that week, please choose another start date !'
        } else {
          errorMsg = `${editingEventId ? 'Edit' : 'Create'} weekly event failed`
        }
        toast.error(errorMsg)
      }
    }
  }

  function resetForm() {
    eventStore.unsetEditingEvents()
    reset(defaultFormData)
    setWeekDay([])
  }

  useEffect(() => {
    if (editingEventId) {
      reset(formData)
    } else {
      reset(defaultFormData)
      setWeekDay([])
    }
  }, [editingEventId, formData])

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <ModalDialog
          title={`${editingEventId ? 'Edit' : 'Create New'} Weekly Event`}
          toggle={toggleShow}
          isOpen={isOpening}
          footer={
            <>
              <Button className={styles.infoButton} onClick={resetForm}>
                Clear fields
              </Button>
              <Button color="secondary" onClick={toggleShow}>
                Cancel
              </Button>
              <Button color="primary" type="submit" form={formId}>
                Save & Review
              </Button>
            </>
          }
        >
          <Row>
            <Col md={12}>
              <Title isRequired small>
                Select day(s) this event occurs on
              </Title>
              <Row>
                <Col md={12}>
                  <ChooseWeekDay
                    weekDay={weekDay}
                    setWeekDay={setWeekDay}
                    refProp={weekDaySelectionRef}
                    weeklyEventCount={weeklyEventCount}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Title isRequired small>
                Start time
              </Title>
              <DateTimePicker
                name="start_time"
                onChange={(e) => e}
                showTimeSelect
                isTime
                control={control}
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="H:mm aa"
                timeFormat="H:mm aa"
                timeCaption="Time"
              />
            </Col>
            <Col md={6}>
              <Title isRequired small>
                End time
              </Title>
              <DateTimePicker
                name="end_time"
                onChange={(e) => e}
                showTimeSelect
                isTime
                control={control}
                minDate={dayjs(startTime).add(1, 'hour').toDate()}
                minTime={dayjs(startTime).add(1, 'hour').toDate()}
                maxTime={dayjs(startTime).endOf('day').toDate()}
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="H:mm aa"
                timeFormat="H:mm aa"
                timeCaption="Time"
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Title isRequired small hasMarginTop>
                Title
              </Title>
              <Input name="title" placeholder="Title of Event" maxLength={50} innerRef={register({ required: true })} />
              <p className={styles.hintText}>Limit 50 characters</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Title isRequired small>
                Details
              </Title>
              <Input
                name="details"
                placeholder="Details of Event"
                maxLength={500}
                innerRef={register({ required: true })}
              />
              <p className={styles.hintText}>Limit 500 characters</p>
            </Col>
          </Row>
          {!!editingEventId && (
            <SpecialList eventId={editingEventId} specials={get(formData, 'specials', [])} key="weekly-specials" />
          )}
        </ModalDialog>
      </form>
    </FormProvider>
  )
}

export default observer(CreateEventModal)
