import { api } from 'Api'

export async function updateSupplier(
  username: string,
  secretPassword: string,
  email: string,
  password: string
): Promise<boolean> {
  try {
    const response = await api.post(`/updateSupplier`, {
      username,
      secretPassword,
      email,
      password,
    })
    return response.data
  } catch (err) {
    return false
  }
}
