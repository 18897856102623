import { useEffect, useState } from 'react'
import get from 'lodash/get'
import { useFormContext } from 'react-hook-form'
import Picture from './components/Picture'
import Uploader from './components/Uploader'
import styles from './styles.module.scss'

const ProfilePicture = () => {
  const { watch } = useFormContext()

  const profilePictureUrl = watch('profilePictureUrl')

  const [{ alt, src }, setImage] = useState({
    src: '',
    alt: 'Profile Picture',
  })

  useEffect(() => {
    if (profilePictureUrl) {
      setImage({ alt, src: profilePictureUrl })
    }
  }, [profilePictureUrl])

  const handleImg = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = get(event.target.files, 0, null)
    if (file) {
      setImage({
        src: URL.createObjectURL(get(event.target.files, 0, null)),
        alt: 'Profile Picture',
      })
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Picture className={styles.picture} alt={alt} src={src} />
        <Uploader className={styles.uploader} onChange={handleImg} />
      </div>
    </div>
  )
}

export default ProfilePicture
