import { flow, makeAutoObservable } from 'mobx'
import { getAccountSetting, getUserEstablishments, getUserManager } from 'Api/user'
import { IAccountSetting, IAddManager } from 'constants/interfaces'
import { RootStore } from '.'

class MyAccountStore {
  rootStore: RootStore

  accountSetting: IAccountSetting = {
    email: '',
    phone_num: '',
    username: '',
  }

  addManager: Array<IAddManager> = []

  establishment: Array<any> = []

  constructor(rootStore: RootStore) {
    //TODO: may need in some special case
    // makeObservable(this, {
    //   accountSetting: observable,
    //   addManager: observable,
    //   establishment: observable,
    //   getAccountSetting: action,
    //   getAddManager: action,
    //   getEstablishmentList: action,
    //   setAccountSetting: action,
    //   setAddManager: action,
    //   addNewManager: action,
    // })

    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  getAccountSetting = flow(function* (this: MyAccountStore) {
    const data = yield getAccountSetting()
    if (typeof data !== 'boolean') {
      this.accountSetting = data
    }
  })

  getAddManager = flow(function* (this: MyAccountStore) {
    const data = yield getUserManager()
    if (typeof data !== 'boolean') {
      this.addManager = data
    }
  })

  getEstablishmentList = flow(function* (this: MyAccountStore) {
    const data = yield getUserEstablishments()
    if (typeof data !== 'boolean') {
      this.establishment = data
    }
  })

  setAccountSetting(data: any) {
    this.accountSetting = data
  }

  setAddManager(data: any) {
    this.addManager = data
  }

  addNewManager(data: IAddManager) {
    this.addManager.push(data)
  }
}
export default MyAccountStore
