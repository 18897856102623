import cx from 'classnames'
import ButtonWithIcon from 'components/ButtonWithIcon'
import { RoleType } from 'constants/enums/role'
import styles from './roleOption.module.scss'

interface RoleOptionProps {
  role: string
  setRole: (role: RoleType) => void
}

const RoleOption = (props: RoleOptionProps) => {
  const { role, setRole } = props

  return (
    <div className={styles.selectRoleButtonGroup}>
      <ButtonWithIcon
        iconName="user-cog"
        content="Establishment"
        className={cx(styles.selectRoleButton, {
          [styles.selectRoleButtonChosen]: role === RoleType.ESTABLISHMENT,
        })}
        onClick={() => setRole(RoleType.ESTABLISHMENT)}
      />

      <ButtonWithIcon
        iconName="store-alt"
        content="Supplier"
        className={cx(styles.selectRoleButton, {
          [styles.selectRoleButtonChosen]: role === RoleType.SUPPLIER,
        })}
        onClick={() => setRole(RoleType.SUPPLIER)}
      />
    </div>
  )
}

export default RoleOption
