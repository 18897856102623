export interface IServerError {
  statusCode?: number
  name?: string
  message?: string | ServerErrorMessage
  code?: string
  details?: IErrorDetails[]
}

interface IErrorDetails {
  code: string
  info: IErrorInfo
  message: ServerErrorMessage | string
  path: string
}

export enum ServerErrorMessage {
  PASSWORD_MINIMUM_LENGTH_REQUIRED = 'should NOT be shorter than 8 characters',
  JWT_EXPIRED = 'Error verifying token : jwt expired',
  UNAVAILABLE_USER = 'Error verifying token : unavailable user.',
  EMAIL_FORMAT = 'should match format "email"',
}

interface IErrorInfo {
  missingProperty: string
}

export interface IErrorOption {
  type: string
  message: string
}

export interface Count {
  count: number
}
