import { useState } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import { Container } from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import AddManagerModal from './components/CreateManagerModal'
import EmptyManagerList from './components/EmptyManagerList'
import ManagerTable from './components/ManagerTable'
import styles from './accountManagers.module.scss'

const AccountManagers = () => {
  const { myAccountStore } = useStore()
  const { addManager } = myAccountStore
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <Container className={styles.container}>
      {addManager.length === 0 ? <EmptyManagerList /> : <ManagerTable />}
      <div
        className={cx(styles.saveButtonRow, {
          [styles.saveButtonRowEmpty]: addManager.length === 0,
        })}
      >
        <Button className={styles.saveButton} type="submit" onClick={() => setIsModalOpen(true)}>
          <Icon icon={'plus'} /> Add Manager
        </Button>
      </div>
      <AddManagerModal isOpen={isModalOpen} setOpen={setIsModalOpen} />
    </Container>
  )
}

export default observer(AccountManagers)
