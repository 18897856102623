import Icon from 'components/Icon'
import styles from './thankYouMessage.module.scss'

const ThankYouMessage = () => {
  return (
    <>
      <div>
        <Icon icon="check-circle" className={styles.checkCircleIcon} />
      </div>
      <div>
        <div>Thank you!</div>
        <div>Please check your email and follow the</div>
        <div>instructions to complete your password reset.</div>
      </div>
    </>
  )
}

export default ThankYouMessage
