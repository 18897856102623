import { useState } from 'react'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Row as ReactTableRow } from 'react-table'
import { Row, Col } from 'reactstrap'
import { deleteEvent } from 'Api/event'
import ConfirmModal from 'components/ConfirmModal'
import Icon from 'components/Icon'
import Table from 'components/Table'
import StatusCell from 'components/Table/components/StatusCell'
import Title from 'components/Title'
import { getValidArray } from 'utils/common'
import DateTimeBlock from '../DateTimeBlock'
import CreateOneTimeEventModal from '../EventTabs/components/CreateOneTimeEventModal'
import CreateWeeklyEventModal from '../EventTabs/components/CreateWeeklyEventModal'
import SpecialList from '../SpecialList'
import { getHeaderList } from './constant'
import styles from './todayEvent.module.scss'

interface ExpandableCellProps {
  row: any
  value: unknown
}

const ExpandableCell = (props: ExpandableCellProps) => {
  const { row, value } = props
  return <span {...row.getToggleRowExpandedProps()}>{value}</span>
}

const TodayEvent = () => {
  const { eventStore } = useStore()
  const { todayEvents } = eventStore
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenOneTimeEvent, setIsOpenOneTimeEvent] = useState(false)
  const [isOpenWeeklyEvent, setIsOpenWeeklyEvent] = useState(false)
  const [targetEventId, setTargetEventId] = useState(0)

  function toggleModal() {
    setIsOpenModal(!isOpenModal)
  }

  function toggleOneTimeEvent() {
    if (isOpenOneTimeEvent) {
      eventStore.unsetEditingEvents()
    }
    setIsOpenOneTimeEvent(!isOpenOneTimeEvent)
  }

  function toggleWeeklyEvent() {
    if (isOpenWeeklyEvent) {
      eventStore.unsetEditingEvents()
    }
    setIsOpenWeeklyEvent(!isOpenWeeklyEvent)
  }

  async function editEvent(eventId: number, day: number) {
    eventStore.unsetEditingEvents()
    await eventStore.fetchEventDetail(eventId)
    if (day > -1) {
      toggleWeeklyEvent()
    } else {
      toggleOneTimeEvent()
    }
  }

  async function handleAccept() {
    await deleteEvent(targetEventId)
    toggleModal()
    await eventStore.rootStore.establishmentStore.fetchEstablishmentEvent()
  }

  const dataInTable = getValidArray(todayEvents).map((event) => ({
    ...event,
    isCurrent: event?.isCurrent ?? false,
    title: <div>{event?.title ?? ''}</div>,
    detail: <div>{event?.details ?? ''}</div>,
    specialCount: <div>{event?.specials?.length ?? 0}</div>,
    type: (
      <StatusCell status={(event?.day ?? -1) > -1 ? 'green' : 'blue'}>
        {(event?.day ?? -1) > -1 && <Icon icon="sync" className={styles.syncIcon} />}
        {(event?.day ?? -1) > -1 ? 'Weekly' : 'One-time'}
      </StatusCell>
    ),
    time: <DateTimeBlock startTime={event?.start_time} endTime={event?.end_time} day={event?.day} />,
    actions: (
      <div className={styles.column}>
        <Icon
          icon="pen"
          className={styles.editIcon}
          onClick={() => editEvent(event?.event_id ?? 0, event?.day ?? -1)}
        />
        <Icon
          icon="trash-alt"
          className={styles.trashIcon}
          onClick={() => {
            setTargetEventId(event?.event_id ?? 0)
            toggleModal()
          }}
        />
      </div>
    ),
  }))
  return (
    <Row className={styles.container}>
      <Col md={12} className={styles.tableContainer}>
        <div className={styles.tableTitle}>
          TODAY'S EVENTS
          <span className={styles.tableDot}> - </span>
          <span className={styles.tableDate}>{dayjs().format('dddd, MM/DD/YYYY')}</span>
        </div>
        <Table
          headerList={getHeaderList(ExpandableCell, true)}
          tableData={dataInTable}
          hasNoSort={false}
          hasDivWrapper
          isScrollAble
          subComponent={(row: ReactTableRow, index: number) => (
            <SpecialList specials={get(row, 'original.specials', [])} key={`sub-${index}`} />
          )}
        />
        <Row>
          <Col md={12} className={styles.info}>
            <Title small>
              <Icon icon="info-circle" />
              Click table row to see more information
            </Title>
          </Col>
        </Row>
        <ConfirmModal
          isOpen={isOpenModal}
          onClickClose={toggleModal}
          onClickCancel={toggleModal}
          onClickAccept={handleAccept}
        />
        <CreateOneTimeEventModal
          isOpening={isOpenOneTimeEvent}
          toggleShow={toggleOneTimeEvent}
          formId="today-onetime-event"
        />
        <CreateWeeklyEventModal
          isOpening={isOpenWeeklyEvent}
          toggleShow={toggleWeeklyEvent}
          formId="today-weekly-event"
        />
      </Col>
    </Row>
  )
}

export default observer(TodayEvent)
