import cx from 'classnames'
import { FallbackFormRegister, FormRegister } from 'types/hookForm'
import Input from 'components/Input'
import styles from './inputRadio.module.scss'

interface Props {
  label?: string
  labels?: string[]
  checked?: boolean
  disabled?: boolean
  name?: string
  value?: string
  small?: boolean
  onChange?: (e: unknown) => void
  innerRef?: FormRegister | FallbackFormRegister
  defaultChecked?: boolean
  isHidden?: boolean
  className?: string
}

const InputRadio = (props: Props) => {
  const { label, labels = [], checked, disabled, small = true, isHidden } = props

  return (
    <label
      className={cx({
        [styles.container]: true,
        [styles.checked]: checked,
        [styles.disabled]: disabled,
        [styles.small]: small,
        [styles.hidden]: isHidden,
      })}
    >
      {label}
      {labels.map((singleLabel) => (
        <p key={Math.random().toString()} className="mb-2">
          {singleLabel}
        </p>
      ))}
      <Input {...props} disabled={disabled} className={styles.input} type="radio" checked={checked} />
      <span
        className={cx({
          [styles.checkmark]: true,
          [styles.disabled]: disabled,
          [styles.small]: small,
        })}
      />
    </label>
  )
}

export default InputRadio
