import { Label } from 'reactstrap'
import Icon from 'components/Icon'
import loginPageStyles from '../../loginPage.module.scss'
import styles from './rememberMe.module.scss'

interface RememberMeProps {
  isRemember: boolean
  setIsRemember: (isRemember: boolean) => void
}

const RememberMe = (props: RememberMeProps) => {
  const { isRemember, setIsRemember } = props

  return (
    <div className={styles.rememberMeWrapper} onClick={() => setIsRemember(!isRemember)}>
      <Icon icon={isRemember ? 'check-square' : 'stop'} className={styles.icon} />
      <Label className={loginPageStyles.label}>Remember me</Label>
    </div>
  )
}

export default RememberMe
