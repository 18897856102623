import cx from 'classnames'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import Icon from 'components/Icon'
import { getDealImage } from 'components/Pages/AnalyticsPage/utils'
import { SpecialType } from 'components/Pages/SpecialPage/constants'
import Tag from 'components/Tag'
import { IMAGE_SOURCE_URL } from 'constants/common'
import { CategoryType, PriceType } from 'constants/enums/special'
import { ICreateSpecial, ISpecial } from 'constants/interfaces'
import { getDisplayPrice } from 'utils/common'
import { getDateBasedOnTimeZone } from 'utils/timezone'
import { WeekDayEnum } from '../../../SpecialTabs/components/CreateWeeklySpecialModal/constants'
import { getDaysOfWeek } from './utils'
import styles from './row.module.scss'

interface IRowProps {
  order: number
  special: ISpecial
  type: SpecialType
  isCompact?: boolean
}

const Row = (props: IRowProps) => {
  const { specialStore, establishmentStore } = useStore()
  const { currentTimezone } = establishmentStore
  const { order, special, isCompact, type } = props
  const {
    special_id,
    price,
    price_type_ref_id: priceType,
    details,
    deal_type_ref_id: dealType,
    start_time,
    end_time,
    dinein,
    carryout,
    isTodaySpecial,
  } = special
  const startTime = dayjs(start_time).toDate()
  const endTime = dayjs(end_time).toDate()
  const isEven: boolean = order % 2 === 0
  const isCurrent =
    isTodaySpecial && new Date(start_time).getHours() <= getDateBasedOnTimeZone(currentTimezone).getHours()

  function handleEdit(): void {
    specialStore.setOpenCreateModal(true, props.type)
    const type = []
    dinein && type.push(CategoryType.DINEIN)
    carryout && type.push(CategoryType.TAKEOUT)
    const sameSpecials = specialStore.getSameSpecials(details, price, priceType, startTime, endTime, dealType)
    const days: WeekDayEnum[] = sameSpecials.map((special: ISpecial) => getDaysOfWeek(special.day))
    const formData: ICreateSpecial = {
      startTime,
      endTime,
      description: details,
      startType: 'schedule',
      dealType: +dealType,
      price: priceType === PriceType.NORMAL ? `${price}` : '',
      percentage: priceType === PriceType.OFF ? `${price}` : '',
      priceType: `${priceType}`,
      type,
      days,
      editingSpecialId: special_id,
    }
    specialStore.setFormData(formData)
  }

  async function handleDelete() {
    specialStore.setOpenReviewModal(true, props.type, true)
    const type = []
    dinein && type.push(CategoryType.DINEIN)
    carryout && type.push(CategoryType.TAKEOUT)
    const sameSpecials = specialStore.getSameSpecials(details, price, priceType, startTime, endTime, dealType)
    const days: WeekDayEnum[] = sameSpecials.map((special: ISpecial) => getDaysOfWeek(special.day))
    const formData: ICreateSpecial = {
      startTime,
      endTime,
      description: details,
      startType: 'schedule',
      dealType: +dealType,
      price: priceType === PriceType.NORMAL ? `${price}` : '',
      percentage: priceType === PriceType.OFF ? `${price}` : '',
      priceType: `${priceType}`,
      type,
      days,
      editingSpecialId: special_id,
    }
    specialStore.setFormData(formData)
  }

  return (
    <div
      className={cx(styles.container, { [styles.even]: isEven, [styles.odd]: !isEven, [styles.current]: isCurrent })}
    >
      <div className={styles.column}>
        <img className={styles.iconStyle} src={`${IMAGE_SOURCE_URL}/${getDealImage(dealType)}`} alt="logo" />
      </div>
      <div className={cx(styles.column, { [styles.priceCompact]: isCompact })}>{getDisplayPrice(price, priceType)}</div>
      <div className={cx(styles.column, { [styles.nameCompact]: isCompact && type === SpecialType.EXCLUSIVE })}>
        {details}
      </div>
      <div className={styles.column}>
        <Tag dinein={dinein} carryout={carryout} />
      </div>
      <div
        className={cx(styles.column, styles.timeStyle, {
          [styles.dateCompact]: isCompact,
        })}
      >
        {type === SpecialType.EXCLUSIVE && (
          <div className={styles.dateBlock}>{dayjs(startTime).format('ddd MM/DD/YYYY')}</div>
        )}
        <div className={styles.timeBlock}>
          {`${dayjs(startTime).format('h:mm A')} - ${dayjs(endTime).format('h:mm A')}`}
        </div>
      </div>
      <div className={cx(styles.column, { [styles.compact]: isCompact })}>
        {!isCurrent && <Icon onClick={handleEdit} icon="pen" className={styles.editIcon} />}
        {!isCurrent && <Icon onClick={handleDelete} icon="trash-alt" className={styles.trashIcon} />}
      </div>
    </div>
  )
}

export default Row
