import MainLayout from 'components/MainLayout'
import CampaignPage from 'components/Pages/CampaignPage'

const SpecialLayout = () => {
  return (
    <MainLayout>
      <CampaignPage />
    </MainLayout>
  )
}

export default SpecialLayout
