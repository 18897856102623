import MainLayout from 'components/MainLayout'
import BusinessInfoPage from '../../components/Pages/BusinessInfoPage'
import styles from './businessInfo.module.scss'

const BusinessInfoLayout = () => {
  return (
    <MainLayout>
      <div className={styles.container}>
        <BusinessInfoPage />
      </div>
    </MainLayout>
  )
}

export default BusinessInfoLayout
