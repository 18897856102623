import { useEffect, useState } from 'react'
import get from 'lodash/get'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'reactstrap'
import DateTimePicker from 'components/DateTimePicker'
import ErrorMessage from 'components/ErrorMessage'
import { Switcher } from 'components/Switcher'
import styles from './dayInWeekRow.module.scss'

interface IDayInWeekRowProps {
  day: {
    content: string
    openName: string
    closeName: string
  }
}

const DayInWeekRow = (props: IDayInWeekRowProps) => {
  const { day } = props
  const { control, setValue, watch, errors } = useFormContext()
  const openTime: string = watch(day.openName)
  const closeTime: string = watch(day.closeName)
  const [isClosed, setIsClosed] = useState(false)

  useEffect(() => {
    if (openTime === '' && closeTime === '') {
      setIsClosed(true)
    } else if (openTime !== '' && closeTime !== '') {
      setIsClosed(false)
    }
  }, [openTime, closeTime])

  useEffect(() => {
    if (isClosed) {
      setValue(day.openName, '')
      setValue(day.closeName, '')
    }
  }, [isClosed])

  return (
    <>
      <Row className={styles.rowMarginBottom} xs="12">
        <Col className={styles.leftCol} md="2">
          {day.content}
        </Col>
        <Col className={styles.middleCol} md="4">
          <DateTimePicker
            name={day.openName}
            onChange={(e) => e}
            addonClassName={isClosed ? styles.dateTimePickerDisable : styles.dateTimePicker}
            showTimeSelect
            disabled={isClosed}
            control={control}
            isTime
            showTimeSelectOnly
            timeIntervals={60}
            dateFormat="h:mm aa"
            timeCaption="Time"
          />
          {get(errors, day.closeName, null) && <ErrorMessage error={'Invalid time'} />}
        </Col>
        <Col className={styles.middleCol} md="4">
          <DateTimePicker
            name={day.closeName}
            onChange={(e) => e}
            addonClassName={isClosed ? styles.dateTimePickerDisable : styles.dateTimePicker}
            showTimeSelect
            control={control}
            isTime
            disabled={isClosed}
            showTimeSelectOnly
            timeIntervals={60}
            dateFormat="h:mm aa"
            timeCaption="Time"
            rules={{
              validate: () => {
                if (openTime === '' && closeTime === '') {
                  return true
                }
                return new Date(openTime) < new Date(closeTime)
              },
            }}
          />
        </Col>
        <Col className={styles.rightCol} md="2">
          <Switcher className={styles.switcher} value={isClosed} setValue={setIsClosed} label={'Closed'} />
        </Col>
      </Row>
    </>
  )
}

export default DayInWeekRow
