import { useRef, useState } from 'react'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import styles from './dateTimePicker.module.scss'

interface DatepickerInputProps {
  onClick?: React.MouseEventHandler<HTMLInputElement>
  value?: string
}
const DatepickerInput = ({ onClick, value }: DatepickerInputProps) => {
  return <input type="text" className="form-control date" onClick={onClick} value={value} />
}

interface DatepickerInputWithAddonProps {
  onClick?: React.MouseEventHandler<HTMLInputElement>
  value?: string
  isTime?: boolean
  isDropdown?: boolean
  className?: string
  calendarRef: DatePicker | null
}
const DatepickerInputWithAddon = (props: DatepickerInputWithAddonProps) => {
  const { isTime, value, onClick, isDropdown, calendarRef, className } = props
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={classNames(className, 'input-group')}
      onClick={() => {
        if (calendarRef && isDropdown) {
          setIsOpen(!isOpen)
          calendarRef.setOpen(isOpen)
        }
      }}
    >
      <input
        type="text"
        className="form-control"
        style={{ background: 'white' }}
        onClick={onClick}
        value={value}
        readOnly
      />
      <div className={'input-group-append'}>
        <span className="input-group-text bg-primary border-primary text-white">
          <i
            className={classNames({
              'las la-clock font-20': isTime,
              'las la-calendar font-20': !isTime,
            })}
          ></i>
        </span>
      </div>
    </div>
  )
}

interface DateTimePickerProps {
  hideAddon?: boolean
  name: string
  control?: any
  errors?: Record<string, unknown>
}

interface DateTimePickerState {
  startDate?: Date
  isTime?: boolean
  isDropdown?: boolean
  rules?: Record<string, unknown>
  defaultValue?: Date
  addonClassName?: string
}

type DatePickerProps = React.ComponentProps<typeof DatePicker>

const DateTimePicker = (props: DatePickerProps & DateTimePickerProps & DateTimePickerState) => {
  const { addonClassName, name, control, errors = {}, rules, defaultValue, isDropdown, hideAddon } = props
  let calendarRef = useRef(null)
  const [startDate, setStartDate] = useState(defaultValue ? defaultValue : new Date())

  return (
    <div
      className={classNames(styles.hfDateTimePicker, {
        [styles.error]: !!errors[name],
      })}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          if (name === 'startTime' || name === 'endTime' || name === 'start_time' || name === 'end_time') {
            value && value?.setMinutes && value.setMinutes(0)
          }
          setStartDate(value)
          return (
            <DatePicker
              {...props}
              // eslint-disable-next-line no-return-assign
              ref={(ref) => {
                // @ts-ignore: Object is possibly 'null'.
                calendarRef.current = ref
              }}
              className={styles.whiteBg}
              customInput={
                hideAddon ? (
                  <DatepickerInput />
                ) : (
                  <DatepickerInputWithAddon
                    calendarRef={calendarRef.current}
                    className={addonClassName}
                    isTime={props?.isTime}
                    isDropdown={isDropdown}
                  />
                )
              }
              selected={startDate}
              minDate={new Date()}
              onChange={onChange}
            />
          )
        }}
      />
    </div>
  )
}

export default DateTimePicker
