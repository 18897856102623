import { Dispatch, SetStateAction } from 'react'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { useFormContext, useWatch } from 'react-hook-form'
import { Button } from 'reactstrap'
import { WeekDayEnum } from 'constants/common'
import { DealTypeEnum } from 'constants/enums/special'
import styles from './footer.module.scss'

interface IFooterProps {
  toggleShow: () => void
  handleReview: () => void
  setWeekDay: Dispatch<SetStateAction<WeekDayEnum[]>>
  isEditing: boolean
  weekDay: string[]
  days: string[]
  dealType: DealTypeEnum
  scrollToWeekDaySelection?: () => void
}

const Footer = ({
  setWeekDay,
  isEditing,
  toggleShow,
  weekDay,
  days,
  dealType,
  handleReview,
  scrollToWeekDaySelection,
}: IFooterProps) => {
  const { specialStore } = useStore()
  const { clearErrors, errors, setValue, trigger, control } = useFormContext()
  const description = useWatch({ control, name: 'description' })
  const priceType = useWatch({ control, name: 'priceType' })
  const types = useWatch({ control, name: 'type' }) || []
  const disableSubmit: boolean =
    !dealType ||
    !description ||
    !priceType ||
    !get(types, 'length', 0) ||
    !get(weekDay, 'length', 0) ||
    !isEmpty(errors)

  function handleClearForm() {
    if (!isEditing) {
      setWeekDay([])
    }
    specialStore.unsetFormData(isEditing)
  }

  function handleCancel() {
    clearErrors(undefined)
    specialStore.unsetFormData()
    setWeekDay([])
    toggleShow()
  }

  function handleSubmit() {
    if (isEmpty(weekDay) && scrollToWeekDaySelection) {
      scrollToWeekDaySelection()
    }
    if (isEmpty(errors) && !isEmpty(weekDay)) {
      setValue('days', weekDay)
      setValue('previousDays', days)
      handleReview()
    }
    trigger()
  }

  return (
    <>
      <Button className={styles.infoButton} onClick={handleClearForm}>
        Clear fields
      </Button>
      <Button color="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        className={styles.submitButton}
        color="primary"
        disabled={disableSubmit}
        type="submit"
        form="create-weekly-special-form"
        onClick={handleSubmit}
      >
        Save & Review
      </Button>
    </>
  )
}

export default Footer
