export enum PriceType {
  NORMAL = 300,
  OFF = 301,
  BOGO = 302,
  FREE = 307,
}

export enum CategoryType {
  DINEIN = 'Dine-in',
  TAKEOUT = 'Takeout',
}

export enum DealTypeEnum {
  BEER = 200,
  SHOT = 202,
  WINE = 204,
  MIXED_DRINK = 206,
  MARGARITA = 208,
  MARTINI = 210,
  TUMBLER = 212,
  BEER_BOTTLE = 214,
  BEER_CAN = 216,
  BURGER = 251,
  APPETIZER = 253,
  PIZZA = 255,
  TACO = 257,
  SUSHI = 259,
  BOWL = 261,
  CHICKEN_WING = 263,
}

export enum DayOfWeek {
  INACTIVE = -1,
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export enum CategoryEnum {
  TRUE = 1,
  FALSE = 0,
}
