import cx from 'classnames'
import { Button as RSButton, ButtonProps } from 'reactstrap'
import styles from './button.module.scss'

const Button = (props: ButtonProps) => {
  const { className, ...otherProps } = props
  return <RSButton {...otherProps} className={cx(styles.hfButton, className)} />
}

export default Button
