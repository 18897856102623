import { useState } from 'react'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { createExclusiveSpecial, editSpecial } from 'Api/special'
import Button from 'components/Button'
import { getSelectedHour, getSelectedMinutes } from 'components/DateTimePicker/utils'
import ModalDialog from 'components/ModalDialog'
import { getDealImage } from 'components/Pages/AnalyticsPage/utils'
import { getPriceTitle } from 'components/Pages/SpecialPage/utils'
import StatusCell from 'components/Table/components/StatusCell'
import Title from 'components/Title'
import { CategoryType, PriceType } from 'constants/enums/special'
import { ISpecial } from 'constants/interfaces'
import { getValidEnding } from 'utils/common'
import LabelValueLine from '../LabelValueLine'
import { ignoreTimezoneParsing } from '../ReviewWeeklySpecialModal/utils'
import styles from './reviewSpecialModal.module.scss'

interface IReviewSpecialModal {
  isOpening: boolean
  toggleShow: () => void
  handleEdit: () => void
}

const ReviewSpecialModal = (props: IReviewSpecialModal) => {
  const { specialStore } = useStore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const sourceImage = 'https://app.appyhourmobile.com/assets/img'
  const { formData } = specialStore
  const {
    description,
    price = 0,
    percentage = 0,
    priceType = 300,
    dealType = 200,
    type,
    startTime = new Date(),
    startDate,
    duration = { hour: new Date(), minute: new Date() },
    editingSpecialId,
  } = formData
  const { hour, minute } = duration
  const selectedHour = getSelectedHour(hour)
  const selectedMinute = getSelectedMinutes(minute)
  const displayPrice = getPriceTitle(+price, +percentage, priceType)
  const { isOpening = false, toggleShow, handleEdit } = props
  const displayDate = startDate ? startDate : startTime
  displayDate.setHours(startTime.getHours())
  displayDate.setMinutes(startTime.getMinutes())

  // const formatHour: string = `${selectedHour < 1 ? '' : `${selectedHour} hour${selectedHour > 1 ? 's' : ''}`}`
  // const formatMinute: string = `${selectedMinute < 1 ? '' : `${selectedMinute} minute${selectedMinute > 1 ? 's' : ''}`}`
  const formatHour: string = getValidEnding(selectedHour, 'hour')
  const formatMinute: string = getValidEnding(selectedMinute, 'minute')

  async function handleSubmit() {
    setIsSubmitting(true)
    const endTime = new Date(displayDate.getTime())
    endTime.setHours(startTime.getHours() + selectedHour)
    endTime.setMinutes(startTime.getMinutes() + selectedMinute)
    const newExclusiveSpecial: ISpecial = {
      price: +priceType === PriceType.NORMAL ? +price : +percentage,
      price_type_ref_id: +priceType,
      details: description,
      deal_type_ref_id: +dealType,
      day: -1,
      start_time: ignoreTimezoneParsing(displayDate),
      end_time: ignoreTimezoneParsing(endTime),
      dinein: type.includes(CategoryType.DINEIN) ? 1 : 0,
      carryout: type.includes(CategoryType.TAKEOUT) ? 1 : 0,
    }

    try {
      if (editingSpecialId) {
        await editSpecial(editingSpecialId, newExclusiveSpecial)
      } else {
        await createExclusiveSpecial(newExclusiveSpecial)
      }
      specialStore.unsetFormData()
      specialStore.getTablesData()
      toggleShow()
      toast.success(`${editingSpecialId ? 'Edit' : 'Create'} exclusive special successfully`)
    } catch (error) {
      let errorMsg = error?.message ?? `${editingSpecialId ? 'Edit' : 'Create'} exclusive special failed`
      if (errorMsg.includes(409)) {
        errorMsg = '0 exclusive special left that week, please choose another start date'
      }
      toast.error(errorMsg)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <ModalDialog
      title={`${editingSpecialId ? 'Edit' : 'Create new'}  Exclusive Special`}
      toggle={toggleShow}
      isOpen={isOpening}
      className={styles.modal}
      footer={
        <>
          <Button className={styles.infoButton} onClick={handleEdit}>
            Back to edit
          </Button>
          <Button color="primary" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
            Confirm & {editingSpecialId ? 'Edit' : 'Create'} Special
          </Button>
        </>
      }
    >
      <Row>
        <Col md={12}>
          <Title small>Here's a preview of how your special will look to your customers.</Title>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <div className={styles.cardWrapper}>
            <img className={styles.image} src={`${sourceImage}/${getDealImage(+dealType)}`} alt="logo" />
            <h5 className={styles.cardLabel}>{`${displayPrice}`}</h5>
          </div>
        </Col>
        <Col md={9}>
          <h4>{`${displayPrice} ${description}`}</h4>
          {type.includes(CategoryType.DINEIN) && (
            <StatusCell status={CategoryType.DINEIN}>{CategoryType.DINEIN}</StatusCell>
          )}
          {type.includes(CategoryType.TAKEOUT) && (
            <StatusCell className={styles.padding} status={CategoryType.TAKEOUT}>
              {CategoryType.TAKEOUT}
            </StatusCell>
          )}
          <LabelValueLine label={'Start time'} value={dayjs(displayDate).format("ddd MMM D, YYYY 'at' p")} />
          <LabelValueLine label={'Duration'} value={` ${formatHour} ${formatMinute}`} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Title small>
            If you're satisfied with your special, click the{' '}
            <b>Confirm & {editingSpecialId ? 'Edit' : 'Create'} Special</b> button below!
          </Title>
        </Col>
      </Row>
    </ModalDialog>
  )
}

export default observer(ReviewSpecialModal)
