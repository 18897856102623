import { useEffect } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Col, Input, InputProps, Row } from 'reactstrap'
import DateTimePicker from 'components/DateTimePicker'
import InputRadio from 'components/InputRadio'
import ModalDialog from 'components/ModalDialog'
import Title from 'components/Title'
import { PriceType } from 'constants/enums/special'
import { ICreateSpecial } from 'constants/interfaces'
import TypeSelection from '../CreateWeeklySpecialModal/components/TypeSelection'
import Images from './components/DealTypeImages'
import Footer from './components/Footer'
import PriceTypeSelection from './components/PriceTypeSelection'
import TimeSelectSection from './components/TimeSelectSection'
import { ICreateSpecialModal } from './interfaces'
import styles from './createSpecialModal.module.scss'

export interface IDuration {
  hour: Date
  minute: Date
}

const CreateExclusiveSpecialModal = (props: ICreateSpecialModal) => {
  const { specialStore } = useStore()
  const { formData, exclusiveSpecials = [] } = specialStore
  const remainThisWeek = get(exclusiveSpecials, '[0].remaining', 0)
  const { isOpening = false, toggleShow, handleReview } = props
  const { editingSpecialId = 0, dealType, startTime, endTime } = formData
  const methods = useForm<ICreateSpecial>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: formData,
  })
  const { register, control, reset, errors, setValue, watch } = methods
  const startType = useWatch({ control, name: 'startType' })
  const priceType = useWatch({ control, name: 'priceType' })
  const startOfDay: Date = dayjs().hour(0).minute(0).second(0).millisecond(0).toDate()
  const defaultDuration = new Date()
  const startHour = startTime?.getHours() ?? 0
  const endHour = endTime?.getHours() ?? 1
  const startMinute = startTime?.getMinutes() ?? 0
  const endMinute = endTime?.getMinutes() ?? 0
  if (editingSpecialId) {
    defaultDuration.setHours(endHour - startHour)
    defaultDuration.setMinutes(endMinute - startMinute)
  } else {
    defaultDuration.setHours(1)
    defaultDuration.setMinutes(0)
  }
  const hasInput = priceType === `${PriceType.NORMAL}` || priceType === `${PriceType.OFF}`
  const hour = watch('duration')
  const hourNumber = get(hour, 'hour', dayjs(startOfDay).hour(1).toDate()).getHours() ?? 1
  const timeProps = {
    isTime: true,
    showTimeSelect: true,
    showTimeSelectOnly: true,
    defaultValue: defaultDuration,
    minTime: startOfDay,
  }

  function getPriceProps(): InputProps {
    const isPrice: boolean = priceType === `${PriceType.NORMAL}`
    return {
      className: cx({
        [styles.error]: isPrice ? !!errors['price'] : !!errors['percentage'],
        [styles.disabledInput]: editingSpecialId,
      }),
      name: `${isPrice ? 'price' : 'percentage'}`,
      type: 'number',
      errors,
      placeholder: `0.00 ${isPrice ? '$' : '%'}`,
      required: true,
      innerRef: register(isPrice ? { required: true } : { required: true, max: 100, min: 0.01 }),
    }
  }

  useEffect(() => {
    if (hourNumber > 4) {
      setValue('duration.minute', startOfDay)
    }
    if (hourNumber < 1) {
      setValue('duration.minute', dayjs(startOfDay).minute(30).toDate())
    }
  }, [hourNumber])

  useEffect(() => {
    if (!startType) {
      formData.startType = 'schedule'
    }
    reset(formData)
  }, [formData])

  useEffect(() => {
    register('dealType')
    register('editingSpecialId')
  }, [register])

  useEffect(() => {
    editingSpecialId && setValue('editingSpecialId', editingSpecialId)
  }, [editingSpecialId])

  useEffect(() => {
    setValue('dealType', dealType)
  }, [dealType])

  async function onSubmit(data: ICreateSpecial) {
    specialStore.setFormData(data)
  }

  async function onCancel() {
    toggleShow()
    specialStore.unsetFormData()
  }

  return (
    <FormProvider {...methods}>
      <form id="create-special-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalDialog
          title={`${editingSpecialId ? 'Edit' : 'Create new'}  Exclusive Special`}
          toggle={onCancel}
          isOpen={isOpening}
          footer={<Footer toggleShow={toggleShow} handleReview={handleReview} />}
        >
          <Row>
            <Col md={12}>
              <Title isRequired small>
                When do you want this special to start?
              </Title>
              <Row>
                <Col md={3}>
                  <InputRadio
                    label="Now"
                    name="startType"
                    value={'now'}
                    disabled={remainThisWeek === 0}
                    innerRef={register}
                  />
                </Col>
                <Col md={3}>
                  <InputRadio label="Schedule" name="startType" value={'schedule'} innerRef={register} />
                </Col>
              </Row>
            </Col>
          </Row>
          {startType !== 'now' && <TimeSelectSection defaultStartTime={startTime} />}
          <Row>
            <Col md={12}>
              <Title isRequired small smallMargin>
                Duration
              </Title>
            </Col>
            <Col>
              <Row>
                <Col md={6}>
                  <DateTimePicker
                    name="duration.hour"
                    control={control}
                    rules={{ required: true }}
                    {...timeProps}
                    onChange={(e) => e}
                    maxTime={dayjs(startOfDay).hour(5).toDate()}
                    timeIntervals={60}
                    timeCaption="Hours"
                    dateFormat="HH"
                    timeFormat="HH"
                  />
                </Col>
                <Col md={6}>
                  <DateTimePicker
                    name="duration.minute"
                    control={control}
                    {...timeProps}
                    onChange={(e) => e}
                    maxTime={dayjs(startOfDay).minute(45).toDate()}
                    excludeTimes={hourNumber < 1 ? [startOfDay] : []}
                    timeIntervals={hourNumber < 5 ? 15 : 60}
                    timeCaption="Minutes"
                    dateFormat="mm"
                    timeFormat="mm"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Title isRequired small hasMarginTop>
                Price type
              </Title>
              <PriceTypeSelection disabled={!!editingSpecialId} errors={errors} register={register} />
              {hasInput && (
                <Row style={{ paddingLeft: 20 }}>
                  <Col md={3}>
                    <Input {...getPriceProps()} />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Title isRequired small hasMarginTop>
                Details
              </Title>
              <Input
                name="description"
                className={cx({ [styles.error]: !!errors['description'], [styles.disabledInput]: editingSpecialId })}
                placeholder="Details of Special"
                innerRef={register({ required: true, maxLength: 40 })}
              />
              <p className={styles.hintText}>Limit 40 characters</p>
            </Col>
          </Row>
          <Row></Row>
          <TypeSelection />
          <Row>
            <Col md={12}>
              <Title isRequired small smallMargin>
                Select an icon to display with your special
              </Title>
            </Col>
            <Images name="dealType" />
          </Row>
        </ModalDialog>
      </form>
    </FormProvider>
  )
}

export default observer(CreateExclusiveSpecialModal)
