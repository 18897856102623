import { useEffect, useState } from 'react'
import classNames from 'classnames'
import cx from 'classnames'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import { Controller, useWatch } from 'react-hook-form'
import styles from './styles.module.scss'

interface DateTimePickerProps {
  hideAddon?: boolean
  name: string
  control?: any
  errors?: Record<string, unknown>
}

interface DateTimePickerState {
  startDate?: Date
  isTime?: boolean
  rules?: Record<string, unknown>
  defaultValue?: Date
  setError: any
  clearErrors: (name: string) => void
}

type DatePickerProps = React.ComponentProps<typeof DatePicker>

const DateTimePicker = (props: DatePickerProps & DateTimePickerProps & DateTimePickerState) => {
  const { name, control, errors = {}, rules, defaultValue, setError, isTime, hideAddon } = props
  const [startDate, setStartDate] = useState(defaultValue ? defaultValue : dayjs().add(1, 'hour').toDate())
  const startTime: Date = useWatch({ control, name: 'startTime' }) || new Date()
  const endTime: Date = useWatch({ control, name: 'endTime' }) || new Date()

  useEffect(() => {
    if (startTime.getHours() >= endTime.getHours()) {
      setError('Start time must be less than end time.')
    } else {
      setError(undefined)
    }
  }, [startTime.getHours(), endTime.getHours()])

  return (
    <div
      className={classNames(styles.hfDateTimePicker, {
        [styles.error]: !!errors[name],
      })}
    >
      <Controller
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange, value }) => {
          setStartDate(value)
          return (
            <div className={'input-group'} style={{ justifyContent: 'space-between' }}>
              <DatePicker
                {...props}
                className={cx(styles.inputStyle, 'form-control')}
                selected={startDate}
                minDate={new Date()}
                onChange={onChange}
              />
              {!hideAddon && (
                <div className={cx('input-group-append')}>
                  <span className="input-group-text bg-primary border-primary text-white">
                    <i
                      className={classNames({
                        'las la-clock font-20': isTime,
                        'las la-calendar font-20': !isTime,
                      })}
                    ></i>
                  </span>
                </div>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

export default DateTimePicker
