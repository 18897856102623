import MainLayout from 'components/MainLayout'
import SpecialPage from '../../components/Pages/SpecialPage'
import styles from './specialPage.module.scss'

const SpecialLayout = () => {
  return (
    <MainLayout>
      <div className={styles.container}>
        <SpecialPage />
      </div>
    </MainLayout>
  )
}

export default SpecialLayout
