import { ITableHeader } from 'types'

export function getHeaderList(expandableCell: React.ReactNode) {
  const headers: ITableHeader[] = [
    {
      Header: 'Establishment',
      accessor: 'establishment',
      Cell: expandableCell,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: expandableCell,
    },
    {
      Header: 'Phone',
      accessor: 'phone_num',
      Cell: expandableCell,
    },
    {
      Header: 'Username',
      accessor: 'username',
      Cell: expandableCell,
    },
    {
      Header: '',
      accessor: 'actions',
      Cell: expandableCell,
    },
  ]

  return headers
}
