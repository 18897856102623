import { useState } from 'react'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { deleteSpecial } from 'Api/special'
import Button from 'components/Button'
import { getSelectedHour, getSelectedMinutes } from 'components/DateTimePicker/utils'
import ModalDialog from 'components/ModalDialog'
import { getDealImage } from 'components/Pages/AnalyticsPage/utils'
import { SpecialType } from 'components/Pages/SpecialPage/constants'
import { getPriceTitle } from 'components/Pages/SpecialPage/utils'
import StatusCell from 'components/Table/components/StatusCell'
import Title from 'components/Title'
import { IMAGE_SOURCE_URL } from 'constants/common'
import { CategoryType } from 'constants/enums/special'
import LabelValueLine from '../LabelValueLine'
import styles from './reviewSpecialModal.module.scss'

interface IReviewSpecialModal {
  isOpening: boolean
  toggleShow: () => void
}

const DeleteSpecialModal = (props: IReviewSpecialModal) => {
  const { specialStore } = useStore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { formData } = specialStore
  const {
    description,
    price = 0,
    percentage = 0,
    priceType = 300,
    dealType = 200,
    type,
    startTime = new Date(),
    startDate,
    duration = { hour: new Date(), minute: new Date() },
    editingSpecialId = 0,
  } = formData
  const { hour, minute } = duration
  const selectedHour = getSelectedHour(hour)
  const selectedMinute = getSelectedMinutes(minute)
  const displayPrice = getPriceTitle(+price, +percentage, priceType)
  const { isOpening = false, toggleShow } = props
  const displayDate = startDate ? startDate : startTime
  displayDate.setHours(startTime.getHours())
  displayDate.setMinutes(startTime.getMinutes())

  async function handleSubmit() {
    setIsSubmitting(true)
    try {
      await deleteSpecial(editingSpecialId)
      toast.success('Delete special successfully')
      specialStore.getTablesData()
    } catch (error) {
      toast.error('Delete special failed')
    } finally {
      setIsSubmitting(false)
      specialStore.setOpenReviewModal(false, SpecialType.EXCLUSIVE, false)
      specialStore.unsetFormData()
    }
  }

  return (
    <ModalDialog
      title={`Delete Special`}
      toggle={toggleShow}
      isOpen={isOpening}
      className={styles.modal}
      footer={
        <>
          <Button
            className={styles.infoButton}
            color="secondary"
            onClick={() => specialStore.setOpenReviewModal(false, SpecialType.EXCLUSIVE, false)}
          >
            Cancel
          </Button>
          <Button color="danger" type="submit" onClick={handleSubmit} disabled={isSubmitting}>
            Yes, Delete it
          </Button>
        </>
      }
    >
      <Row>
        <Col md={12}>
          <Title small>Are you sure you want to delete this special?</Title>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <div className={styles.cardWrapper}>
            <img className={styles.image} src={`${IMAGE_SOURCE_URL}/${getDealImage(+dealType)}`} alt="logo" />
            <h5 className={styles.cardLabel}>{`${displayPrice}`}</h5>
          </div>
        </Col>
        <Col md={9}>
          <h4>{`${displayPrice} ${description}`}</h4>
          {type.includes(CategoryType.DINEIN) && (
            <StatusCell status={CategoryType.DINEIN}>{CategoryType.DINEIN}</StatusCell>
          )}
          {type.includes(CategoryType.TAKEOUT) && (
            <StatusCell className={styles.padding} status={CategoryType.TAKEOUT}>
              {CategoryType.TAKEOUT}
            </StatusCell>
          )}
          <LabelValueLine label={'Start time'} value={dayjs(displayDate).format("ddd MMM D, YYYY 'at' p")} />
          <LabelValueLine label={'Duration'} value={`${selectedHour} hour ${selectedMinute} minutes`} />
        </Col>
      </Row>
    </ModalDialog>
  )
}

export default observer(DeleteSpecialModal)
