import { ILoginRequest } from 'types/user'
import { api } from 'Api'
import { RoleType } from 'constants/enums/role'

export async function login(data: ILoginRequest) {
  try {
    const response = await api.post(`/login`, data)
    return response.data
  } catch (err) {
    return false
  }
}

export async function checkExists(username: string, type: RoleType): Promise<boolean> {
  try {
    const response = await api.post(`/userExists`, {
      username,
      type,
    })
    return response.data
  } catch (err) {
    return false
  }
}

export async function userHasChangedPassword(username: string, type: RoleType): Promise<boolean> {
  try {
    const response = await api.post(`/userHasChangedPassword`, {
      username,
      type,
    })
    return response.data
  } catch (err) {
    return false
  }
}

export async function secretPasswordMatches(secretPassword: string): Promise<boolean> {
  try {
    const response = await api.post(`/secretPasswordMatches`, {
      secretPassword,
    })
    return response.data
  } catch (err) {
    return false
  }
}

export async function sendEmailForgotPassword(username: string, type: string): Promise<boolean> {
  try {
    const response = await api.post(`/forgot-password`, {
      username,
      type,
    })
    return response.data
  } catch (err) {
    return false
  }
}

export async function resetPassword(
  newPassword: string,
  confirmNewPassword: string,
  resetPasswordToken: string,
  type: RoleType = RoleType.ESTABLISHMENT
): Promise<boolean> {
  try {
    const response = await api.post(`/reset-password`, {
      newPassword,
      confirmNewPassword,
      resetPasswordToken,
      type,
    })
    return response.data
  } catch (err) {
    return false
  }
}
