import { DayOfWeek } from 'constants/enums/special'
import { Timezone, TopicName } from 'constants/enums/timezone'

export function getStartAndEndOfWeekByTimezone(week: number, timezone: Timezone) {
  let daysAgo = 1
  if (week === 0) {
    daysAgo = 1
  } else if (week === 1) {
    daysAgo = 8
  } else {
    daysAgo = 15
  }

  const currentStart = getDateBasedOnTimeZone(timezone) // new Date()
  currentStart.setHours(0, 0, 0, 0)
  const currentEnd = getDateBasedOnTimeZone(timezone) // new Date()
  currentEnd.setHours(0, 0, 0, 0)
  let first = 0

  if (currentStart.getDay() !== DayOfWeek.SUNDAY) {
    first = currentStart.getDate() - currentStart.getDay() + daysAgo
  } else {
    first = currentStart.getDate() - 7 + daysAgo
  }

  const last = first + 6
  const startOfWeek = new Date(currentStart.setDate(first))
  const endOfWeek = new Date(currentEnd.setDate(last))
  return { startOfWeek, endOfWeek }
}

export function getDateBasedOnTimeZone(timezone: Timezone): Date {
  const today = new Date()
  let deviatedHour = 0

  if (timezone === Timezone.PST) {
    deviatedHour = 7
  } else if (timezone === Timezone.MST || timezone === Timezone.PDT) {
    deviatedHour = 6
  } else if (timezone === Timezone.CST || timezone === Timezone.MDT) {
    deviatedHour = 5
  } else if (timezone === Timezone.EST || timezone === Timezone.CDT) {
    deviatedHour = 4
  } else if (timezone === Timezone.EDT) {
    deviatedHour = 3
  } else {
    deviatedHour = 5
  }

  today.setHours(today.getHours() - deviatedHour)
  return today
}

export function convertTopicToTimezone(topic: TopicName): Timezone {
  if (topic === TopicName.DENVERCO) {
    return Timezone.MST
  }
  if (topic === TopicName.DESMOINESIA || topic === TopicName.IOWACITYIA || topic === TopicName.MINNEAPOLISMN) {
    return Timezone.CST
  }
  if (topic === TopicName.LOUISVILLEKY || topic === TopicName.TAMPABAYFL) {
    return Timezone.EST
  }
  return Timezone.CST
}
