import dayjs from 'dayjs'
import styles from './dateTimeBlock.module.scss'

export interface IDateTimeBlockProps {
  startTime?: Date
  endTime?: Date
  day?: number
}

export const TABLET_SCREEN_SIZE = '1200px'

const DateTimeBlock = (props: IDateTimeBlockProps) => {
  const { startTime, endTime, day = -1 } = props
  const isWeekly = day > -1
  const daysList: string[] = day.toString().split('')
  const dayString: string[] = daysList.map((day: string) => dayjs(startTime).day(parseInt(day)).format('ddd'))
  return isWeekly ? (
    <div>
      <div>{dayString.join(', ')}</div>
      <div className={styles.timeBlock}>
        {`${dayjs(startTime).format('h:mm A')} - ${dayjs(endTime).format('h:mm A')}`}
      </div>
    </div>
  ) : (
    <div className={styles.nowrap}>
      <div className={styles.dateBlock}>{dayjs(startTime).format('ddd DD/MM')}</div>
      <div className={styles.timeBlock}>{`${dayjs(startTime).format('h:mm A')}`}</div>
      <br />
      <div className={styles.dateBlock}>{` - ${dayjs(endTime).format('ddd DD/MM')}`}</div>
      <div className={styles.timeBlock}>{`${dayjs(endTime).format('h:mm A')}`}</div>
    </div>
  )
}
export default DateTimeBlock
