import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Row, Col } from 'reactstrap'
import Icon, { IconGroupPrefix } from 'components/Icon'
import { getDealImage } from 'components/Pages/AnalyticsPage/utils'
import Table from 'components/Table'
import Tag from 'components/Tag'
import { IMAGE_SOURCE_URL, WeekDayEnum } from 'constants/common'
import { CategoryType, PriceType } from 'constants/enums/special'
import { ICreateSpecial, ISpecial } from 'constants/interfaces'
import { getDisplayPrice } from 'utils/common'
import { getDateBasedOnTimeZone } from 'utils/timezone'
import { getTodayHeaderList, SpecialType } from '../../constants'
import { getDaysOfWeek } from '../SubTable/components/Row/utils'
import { getExclusiveSpecialOfToday } from './utils'
import styles from './todaySpecial.module.scss'

const TodaySpecial = () => {
  const { specialStore, establishmentStore } = useStore()
  const { currentTimezone } = establishmentStore
  const day = new Date().getDay()
  const { weeklySpecials = [], exclusiveSpecials = [] } = specialStore
  let weeklySpecialOfThatDay = get(weeklySpecials, `[${day}]`, []) || []
  weeklySpecialOfThatDay = weeklySpecialOfThatDay.map((special: ISpecial) => ({ ...special, isTodaySpecial: true }))
  weeklySpecialOfThatDay = weeklySpecialOfThatDay.filter(
    (special: ISpecial) => new Date(special.end_time).getHours() >= getDateBasedOnTimeZone(currentTimezone).getHours()
  )
  let exclusiveSpecialsOfThatDay = getExclusiveSpecialOfToday(exclusiveSpecials, currentTimezone)
  exclusiveSpecialsOfThatDay = exclusiveSpecialsOfThatDay.map((special: ISpecial) => ({
    ...special,
    isTodaySpecial: true,
  }))
  exclusiveSpecialsOfThatDay = exclusiveSpecialsOfThatDay.filter(
    (special: ISpecial) => new Date(special.end_time).getHours() >= getDateBasedOnTimeZone(currentTimezone).getHours()
  )
  const todaySpecials: ISpecial[] = [...exclusiveSpecialsOfThatDay, ...weeklySpecialOfThatDay]
  const dataInTable = todaySpecials.map((special) => {
    const {
      special_id: editingSpecialId,
      day,
      price,
      price_type_ref_id: priceType,
      details: description,
      deal_type_ref_id: dealType,
      start_time,
      end_time,
      dinein,
      carryout,
      isTodaySpecial,
    } = special
    const startTime = dayjs(start_time).toDate()
    const endTime = dayjs(end_time).toDate()
    const isCurrent = isTodaySpecial && new Date(start_time).getHours() <= new Date().getHours()
    const specialType: SpecialType = day === -1 ? SpecialType.EXCLUSIVE : SpecialType.WEEKLY
    const category = []
    dinein && category.push(CategoryType.DINEIN)
    carryout && category.push(CategoryType.TAKEOUT)
    const sameSpecials = specialStore.getSameSpecials(description, price, priceType, startTime, endTime, dealType)
    const days: WeekDayEnum[] = sameSpecials.map((special: ISpecial) => getDaysOfWeek(special.day))

    const formData: ICreateSpecial = {
      startTime,
      endTime,
      description,
      startType: 'schedule',
      dealType: +dealType,
      price: priceType === PriceType.NORMAL ? `${price}` : '',
      percentage: priceType === PriceType.OFF ? `${price}` : '',
      priceType: `${priceType}`,
      type: category,
      days,
      editingSpecialId,
    }

    function handleEdit(): void {
      specialStore.setOpenCreateModal(true, specialType)
      specialStore.setFormData(formData)
    }

    function handleDelete(): void {
      specialStore.setOpenReviewModal(true, specialType, true)
      specialStore.setFormData(formData)
    }
    const logoUrl: string = `${IMAGE_SOURCE_URL}/${getDealImage(dealType)}`

    return {
      ...special,
      isCurrent,
      productType: <img className={styles.dealType} src={logoUrl} alt="logo" />,
      price: <div>{getDisplayPrice(price, priceType)}</div>,
      name: <div>{description}</div>,
      category: <div className={styles.tag}>{<Tag dinein={dinein} carryout={carryout} />}</div>,
      applyTime: (
        <div className={styles.timeStyle}>
          <div className={styles.timeBlock}>
            {`${dayjs(startTime).format('h:mm A')} - ${dayjs(endTime).format('h:mm A')}`}
          </div>
        </div>
      ),
      actions: !isCurrent && (
        <div>
          <Icon onClick={handleEdit} icon="pen"  className={styles.editIcon} />
          <Icon onClick={handleDelete} icon="trash-alt"  className={styles.trashIcon} />
        </div>
      ),
    }
  })
  return (
    <Row className={styles.container}>
      <Col md={12} className={styles.tableContainer}>
        <div className={styles.tableTitle}>
          TODAY'S SPECIALS
          <span className={styles.tableDot}> - </span>
          <span className={styles.tableDate}>{dayjs().format('dddd, MM/DD/YYYY')}</span>
        </div>
        <Table headerList={getTodayHeaderList()} tableData={dataInTable} hasNoSort={false} hasDivWrapper isScrollAble />
      </Col>
    </Row>
  )
}

export default observer(TodaySpecial)
