import cx from 'classnames'
import StatusCell from '../StatusCell'
import styles from './cell.module.scss'

interface ICellProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: any
}

export enum RENDER {
  CELL = 'Cell',
}

export enum HEADER {
  STATUS = 'Status',
  ACTION = 'actions',
}

const CellWithIcon = (props: ICellProps) => {
  const { cell } = props
  const isExpanded = cell?.row?.isExpanded ?? false
  let cellContent = cell.render(RENDER.CELL)
  if (cell?.column?.Header === HEADER.STATUS) {
    cellContent = <StatusCell status={cell?.value}>{cell.render(RENDER.CELL)}</StatusCell>
  }
  return (
    <td>
      {console.log(cellContent)}
      <div className={cx({ [styles.expanded]: isExpanded })}>{cellContent}</div>
    </td>
  )
}

export default CellWithIcon
