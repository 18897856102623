import { LegacyRef } from 'react'
import cx from 'classnames'
import { OptionTypeBase } from 'react-select'
import Input from 'components/Input'
import styles from './inputCheckbox.module.scss'

interface IInputCheckboxProps {
  label: string
  name?: string
  squared?: boolean
  circled?: boolean
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  small?: boolean
  isField?: boolean
  value?: string
  innerRef?: LegacyRef<HTMLInputElement>
  onChange?: (event: OptionTypeBase) => void
  setValue?: (name: string, value: string | string[]) => void
}

const InputCheckBox = (props: IInputCheckboxProps) => {
  const {
    label,
    squared,
    circled,
    checked,
    defaultChecked,
    disabled,
    isField,
    name,
    value,
    innerRef,
    onChange,
    small = true,
  } = props

  return (
    <label
      className={cx({
        [styles.container]: true,
        [styles.field]: isField,
        [styles.small]: small,
      })}
    >
      {label}
      <Input
        name={name}
        value={value}
        disabled={disabled}
        innerRef={innerRef}
        className={styles.input}
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
      />
      <span
        className={cx({
          [styles.checkmark]: true,
          [styles.squared]: squared,
          [styles.circled]: circled,
          [styles.disabled]: disabled,
        })}
      ></span>
    </label>
  )
}

export default InputCheckBox
