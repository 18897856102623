import { useEffect } from 'react'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import has from 'lodash/has'
import { observer } from 'mobx-react'
import { Controller, useFormContext } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import { Container, Input, Row, Form } from 'reactstrap'
import { editAccountSetting, getUserByCondition } from 'Api/user'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Icon from 'components/Icon'
import Label from 'components/Label'
import { IAccountSetting } from 'constants/interfaces'
import { ErrorType } from '../../constant'
import styles from './accountSetting.module.scss'

const AccountSetting = () => {
  const { myAccountStore } = useStore()
  const { accountSetting } = myAccountStore
  const { reset, formState, control, register, errors, handleSubmit } = useFormContext()

  const { isSubmitting, isDirty } = formState

  useEffect(() => {
    reset(accountSetting)
  }, [accountSetting])

  const onSubmit = async (data: IAccountSetting) => {
    data.phone_num = data.phone_num?.replace(/[^0-9]+/g, '')
    const result = await editAccountSetting(data)
    if (result) {
      myAccountStore.setAccountSetting(data)
      toast.success('Update successfully')
    } else {
      toast.error('Update fail')
    }
  }

  return (
    <Form className={styles.formCustom} onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Row className={styles.rowMarginBottom}>
          <Label isRequired>Email</Label>
          <Input
            name="email"
            type="email"
            innerRef={register({
              required: 'Email is empty',
              pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              validate: {
                isExisted: async (value) => {
                  if (value === accountSetting.email) {
                    return true
                  }
                  return !(await getUserByCondition('email', value))
                },
              },
            })}
          />
          <ErrorMessage error={get(errors, 'email.message', '')} />
          {get(errors, 'email.type', '') === ErrorType.PATTERN && <ErrorMessage error={'Email is invalid'} />}
          {get(errors, 'email.type', '') === ErrorType.IS_EXISTED && <ErrorMessage error={'Email is existed'} />}
        </Row>
        <Row className={styles.rowMarginBottom}>
          <Label>Phone</Label>
          <Controller
            name="phone_num"
            control={control}
            alwaysShowMask={false}
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <InputMask mask="(999) 999-9999" maskChar=" " value={value} onChange={onChange}>
                {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    type="text"
                    name="phone_num"
                    required
                    placeholder="E.g: (000) 000-0000"
                    ref={register}
                    invalid={has(errors, 'phone_num')}
                    className={styles.inputCustom}
                  />
                )}
              </InputMask>
            )}
          />
          <ErrorMessage error={get(errors, 'phone_num.message', '')} />
          {get(errors, 'phone_num.type', '') === ErrorType.MIN_LENGTH && (
            <ErrorMessage error={'Phone number must be at least 8 characters'} />
          )}
          {get(errors, 'phone_num.type', '') === ErrorType.MAX_LENGTH && (
            <ErrorMessage error={'Phone number must be maximum 10 characters'} />
          )}
        </Row>
        <Row className={styles.rowMarginBottom}>
          <Label isRequired>Username</Label>
          <Input
            name="username"
            type="text"
            innerRef={register({
              required: 'Username is empty',
              validate: {
                isExisted: async (value) => {
                  if (value === accountSetting.username) {
                    return true
                  }
                  return !(await getUserByCondition('username', value))
                },
              },
            })}
          />
          <ErrorMessage error={get(errors, 'username.message', '')} />
          {get(errors, 'username.type', '') === ErrorType.IS_EXISTED && <ErrorMessage error={'Username is existed'} />}
        </Row>
        <Row className={styles.saveButtonRow}>
          <Button disabled={isSubmitting || !isDirty} className={styles.saveButton} type="submit">
            <Icon icon={'save'} /> Save
          </Button>
        </Row>
      </Container>
    </Form>
  )
}

export default observer(AccountSetting)
