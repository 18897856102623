import { Dispatch, SetStateAction } from 'react'
import { capitalize, isArray } from 'lodash'
import { Col, Tooltip } from 'reactstrap'
import Icon from 'components/Icon'
import { IOption } from 'components/InputGroup/InputDropdown'
import Loading from 'components/Loading'
import { DealTypeEnum } from 'constants/enums/special'
import { IChartDataItem, IDateValue } from 'constants/interfaces'
import { CardString, CardTitle } from './constants'
import styles from './analyticsPage.module.scss'

export function getPercentage(number: number | string): string {
  if (Number(number) >= 0) return ' +' + number + '%'
  return ' ' + number + '%'
}

export const options: IOption[] = [
  { label: 'Today', value: 0 },
  { label: 'Yesterday', value: 1 },
  { label: 'Last 7 days', value: 7 },
  { label: 'Last 28 days', value: 28 },
]

export function getChangesString(item?: IChartDataItem, daysAgo?: IOption): string {
  const { totalViews = 0, changes = 0 } = item || { totalViews: 0, changes: 0 }
  return totalViews + getPercentage(changes) + ` from prev. ${daysAgo?.value ?? 0} days`
}

export function loading(boolean: boolean, isLoading: boolean): React.ReactNode {
  return <Col md={9}>{isLoading && boolean ? <Loading /> : <></>}</Col>
}

export function getDealImage(refId: number) {
  switch (refId) {
    case 200:
      return 'beer-icon.png'
    case 202:
      return 'shot-icon.png'
    case 204:
      return 'wine-icon.png'
    case 206:
      return 'mixed-drink-icon.png'
    case 208:
      return 'margarita-icon.png'
    case 210:
      return 'martini-icon.png'
    case 212:
      return 'tumbler-icon.png'
    case 214:
      return 'beerbottle.png'
    case 216:
      return 'beercan.png'
    case 251:
      return 'burger-icon.png'
    case 253:
      return 'appetizer-icon.png'
    case 255:
      return 'pizza-icon.png'
    case 257:
      return 'taco-icon.png'
    case 259:
      return 'sushi.png'
    case 261:
      return 'bowl.png'
    case 263:
      return 'chickenwing.png'
    default:
      return ''
  }
}

export function getDealTypeName(id: number) {
  const dealNames: string[] = []
  const dealIds = []

  for (const deal in DealTypeEnum) {
    if (Number.isInteger(+deal)) {
      dealIds.push(+deal)
    } else {
      dealNames.push(deal)
    }
  }

  const index = dealIds.findIndex((dealId) => dealId === id)
  return capitalize((dealNames[index] ?? '').split('_').join(' '))
}

export function getToolTipGroup(
  item: CardString,
  title: CardTitle,
  toolTipOpen: boolean,
  setToolTipOpen: Dispatch<SetStateAction<boolean>>
): React.ReactNode {
  const id: string = `icon-${title}`
  return (
    <>
      <Icon icon="question-circle" id={id} className={styles.helperIcon} />
      <Tooltip
        innerClassName={styles.inner}
        arrowClassName={styles.arrow}
        placement="top-start"
        isOpen={toolTipOpen}
        target={id}
        toggle={() => setToolTipOpen(!toolTipOpen)}
        autoHide={false}
      >
        {item}
      </Tooltip>
    </>
  )
}

export function sortDate(chartData: IDateValue[] | undefined): IDateValue[] {
  if (!chartData || !isArray(chartData)) {
    return []
  }
  return chartData.sort((currentDate, nextDate) => {
    return new Date(currentDate.x).getTime() - new Date(nextDate.x).getTime()
  })
}
