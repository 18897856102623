import Icon from 'components/Icon'
import styles from './styles.module.scss'

interface ISortIconProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: any
}

const SortIcon = (props: ISortIconProps) => {
  const { column } = props
  if (column.isSorted) {
    if (column.isSortedDesc) {
      return <Icon icon="sort-down" className={styles.iconSize} />
    }
    return <Icon icon="sort-up" className={styles.iconSize} />
  }
  return <Icon icon="sort" className={styles.iconSize} />
}

export default SortIcon
