import { ReactNode, Suspense, useEffect } from 'react'
import loginBgImage from 'Assets/images/login-bg.png'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { RoleType } from 'constants/enums/role'
import routes from 'routes'
import styles from './publicLayout.module.scss'

interface PublicLayoutProps {
  children?: ReactNode
}

const loading = () => <div className="text-center"></div>

const PublicLayout = (props: PublicLayoutProps) => {
  const { children } = props
  const { authStore } = useStore()
  const { token } = authStore
  const history = useHistory()

  useEffect(() => {
    authStore.getMyUser()
  }, [])

  useEffect(() => {
    const role = get(history.location, 'state.previousUrl', '')
    if (token) {
      if (role) {
        history.push(get(history.location, 'state.previousUrl', ''))
      } else {
        if (authStore.role === RoleType.SUPPLIER) {
          console.log('🚀 ~ file: index.tsx ~ line 34 ~ useEffect ~ authStore.role', authStore.role)
          history.push(routes.campaign.value)
        } else {
          history.push(routes.special.value)
        }
      }
    }
  }, [token])

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.leftSide}>
          <img src={loginBgImage} alt={'login-bg'} className={styles.leftSideBgImage} />
          <div className={styles.leftSideOverlay}></div>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.rightSideContentWrapper}>
            <Suspense fallback={loading()}>{children}</Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(PublicLayout)
