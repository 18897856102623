import cx from 'classnames'
import { Input, InputProps } from 'reactstrap'
import styles from './comboBox.module.scss'

interface IComboBox extends InputProps {
  className?: string
  invalid?: boolean
  message?: string
}

const InputComboBox = (props: IComboBox) => {
  const { children, type = 'select', invalid, message, className, ...otherProps } = props

  return (
    <>
      <div
        className={cx(className, styles.container, {
          [styles.invalid]: invalid,
        })}
      >
        <Input {...otherProps} type={type} className={cx(styles.selectCustom, { [styles.invalid]: invalid })}>
          {children}
        </Input>
      </div>
      {message && <div className={cx(styles.message, { [styles.invalid]: invalid })}>{message}</div>}
    </>
  )
}

export default InputComboBox
