import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Col, Row } from 'reactstrap'
import Button from 'components/Button'
import Icon from 'components/Icon'
import ModalDialog from 'components/ModalDialog'
import Title from 'components/Title'
import SpecialList from '../../../SpecialList'
import LabelValueLine from '../LabelValueLine'
import styles from './reviewEventModal.module.scss'

interface IReviewEventModal {
  isOpening: boolean
  toggleShow: () => void
}

const ReviewEventModal = (props: IReviewEventModal) => {
  const { isOpening = false, toggleShow } = props
  const { eventStore } = useStore()
  const { reviewData } = eventStore
  const { title, details, start_time, end_time, event_id } = reviewData

  return (
    <ModalDialog
      title={'Create new OneTime Event'}
      toggle={toggleShow}
      isOpen={isOpening}
      className={styles.modal}
      footer={
        <>
          <Button color="primary" onClick={toggleShow}>
            Done
          </Button>
        </>
      }
    >
      <Row>
        <Col md={12}>
          <Title small>Event created successfully!</Title>
        </Col>
      </Row>
      <Row>
        <div>
          <div className={styles.cardWrapper}>
            <Icon icon="calendar-check" className={styles.iconStyle} />
          </div>
        </div>
        <Col md={10}>
          <h4>{title}</h4>
          <Title small isBlock>
            {details}
          </Title>
          <LabelValueLine label={'Start at'} value={dayjs(start_time).format('ddd DD/MM/YYYY h:mm A')} inline />
          <LabelValueLine label={'End at'} value={dayjs(end_time).format('ddd DD/MM/YYYY h:mm A')} inline />
        </Col>
      </Row>
      <SpecialList eventId={event_id} specials={get(reviewData, 'specials', [])} key="review-weekly-event-specials" />
    </ModalDialog>
  )
}

export default observer(ReviewEventModal)
