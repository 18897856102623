import get from 'lodash/get'

export const mapToDateTime = (data: any) => {
  Object.keys(data).forEach((key) => {
    const dateString = get(data, key, null)
    if (dateString !== '') {
      data[key] = new Date(dateString)
    }
  })
  return data
}
