import InboxIcon from 'Assets/images/inbox.svg'
import { Container, Row } from 'reactstrap'
import styles from './emptyManagerList.module.scss'

const EmptyManagerList = () => {
  return (
    <Container>
      <Row className={styles.rowCustom}>
        <img className={styles.emptyIcon} src={InboxIcon} alt="inbox" />
      </Row>
      <Row className={styles.rowCustom}>
        <div>
          <b>Manager List Is Empty</b>
        </div>
        <div>Add users that have access to the AppyHour account for</div>
        <div>one of your establishments.</div>
      </Row>
    </Container>
  )
}

export default EmptyManagerList
