import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import flatten from 'lodash/flatten'
import { flow, makeObservable, observable, action } from 'mobx'
import { checkExclusiveSpecial, getSpecialTableData } from 'Api/special'
import { SpecialType } from 'components/Pages/SpecialPage/constants'
import { DealTypeEnum, PriceType } from 'constants/enums/special'
import { ICreateSpecial, ISpecial } from 'constants/interfaces'
import { RootStore } from '.'

const today = new Date()
const defaultEndTime = new Date()
defaultEndTime.setHours(today.getHours() + 1)

export const defaultFormData: ICreateSpecial = {
  description: '',
  price: '',
  percentage: '',
  priceType: PriceType.NORMAL,
  startType: 'schedule',
  type: [],
  startTime: today,
  startDate: today,
  endTime: defaultEndTime,
  days: [],
  editingSpecialId: undefined,
}
class SpecialStore {
  rootStore: RootStore

  todaySpecials = {}
  weeklySpecials = []
  specialsRemainingMap = []
  exclusiveSpecials = []

  formData: ICreateSpecial = defaultFormData

  isWeekForExclusiveAvailable: boolean = true

  openWeeklyReviewModal = false
  openWeeklyCreateModal = false
  editingSpecials: ISpecial[] = []
  openExclusiveReviewModal = false
  openExclusiveCreateModal = false
  isDeleteModal: boolean | undefined = false

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      todaySpecials: observable,
      weeklySpecials: observable,
      specialsRemainingMap: observable,
      exclusiveSpecials: observable,
      formData: observable,
      isWeekForExclusiveAvailable: observable,
      openWeeklyReviewModal: observable,
      openWeeklyCreateModal: observable,
      editingSpecials: observable,
      openExclusiveReviewModal: observable,
      openExclusiveCreateModal: observable,
      isDeleteModal: observable,
      getTablesData: action,
      setFormData: action,
      unsetFormData: action,
      setOpenReviewModal: action,
      setOpenCreateModal: action,
      getSameSpecials: action,
      unsetEditingSpecials: action,
      checkAvailableOfWeek: action,
    })

    this.rootStore = rootStore
  }

  getTablesData = flow(function* (this: SpecialStore) {
    this.rootStore.spinnerStore.showLoading()
    const { todaySpecials, weeklySpecials, specialsRemainingMap, exclusiveSpecials } = yield getSpecialTableData()
    this.todaySpecials = todaySpecials
    this.weeklySpecials = weeklySpecials
    this.specialsRemainingMap = specialsRemainingMap
    this.exclusiveSpecials = exclusiveSpecials
    this.rootStore.spinnerStore.hideLoading()
  })

  setFormData(data: ICreateSpecial | any, isPartial?: boolean): void {
    if (isPartial) {
      this.formData = { ...this.formData, ...data }
    } else {
      this.formData = data
    }
  }

  unsetFormData(isEditing?: boolean): void {
    if (isEditing) {
      const { description = '', days = [], priceType = PriceType.NORMAL, price = '', percentage = '' } = this.formData
      this.formData = { ...defaultFormData, description, days, priceType, price, percentage }
    } else {
      this.formData = defaultFormData
    }
  }

  setOpenReviewModal(value: boolean, type: SpecialType, isDelete?: boolean) {
    this.isDeleteModal = isDelete
    if (type === SpecialType.EXCLUSIVE) {
      this.openExclusiveReviewModal = value
    } else {
      this.openWeeklyReviewModal = value
    }
  }

  setOpenCreateModal(value: boolean, type: SpecialType) {
    if (type === SpecialType.EXCLUSIVE) {
      this.openExclusiveCreateModal = value
    } else {
      this.openWeeklyCreateModal = value
    }
  }

  getSameSpecials(
    details: string,
    price: number,
    priceType: PriceType,
    startTime: Date,
    endTime: Date,
    dealType: DealTypeEnum
  ): ISpecial[] {
    const startHour = startTime.getHours()
    const startMinute = startTime.getMinutes()
    const endHour = endTime.getHours()
    const endMinute = endTime.getMinutes()
    const flattenWeeklySpecial = flatten(cloneDeep(this.weeklySpecials))
    const editingSpecials = flattenWeeklySpecial.filter((special: ISpecial) => {
      return (
        special.details === details &&
        special.price === price &&
        special.price_type_ref_id === priceType &&
        new Date(special.start_time).getHours() === startHour &&
        new Date(special.start_time).getMinutes() === startMinute &&
        new Date(special.end_time).getHours() === endHour &&
        new Date(special.end_time).getMinutes() === endMinute &&
        special.price_type_ref_id === priceType &&
        special.deal_type_ref_id === dealType
      )
    })
    this.editingSpecials = editingSpecials
    return this.editingSpecials
  }

  unsetEditingSpecials() {
    this.editingSpecials = []
  }

  checkAvailableOfWeek = flow(function* (this: SpecialStore, startDate: Date, originalStarDate?: Date) {
    const isEditingExclusive = this?.formData?.editingSpecialId ?? 0

    const isAvailable = yield checkExclusiveSpecial(startDate)
    const initialStartDate = originalStarDate || new Date()
    if (isEditingExclusive && dayjs(startDate).isSame(initialStartDate, 'week')) {
      this.isWeekForExclusiveAvailable = true
    } else {
      this.isWeekForExclusiveAvailable = isAvailable
    }
  })

  setAvailableOfWeek(value: boolean) {
    this.isWeekForExclusiveAvailable = value
  }
}
export default SpecialStore
