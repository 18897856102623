import { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import { toast } from 'react-toastify'
import { Col, Input, Row } from 'reactstrap'
import { createSpecial } from 'Api/special'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import PriceTypeSelection from 'components/Pages/SpecialPage/components/SpecialTabs/components/CreateExclusiveSpecialModal/components/PriceTypeSelection'
import DateTimePicker from 'components/Pages/SpecialPage/components/SpecialTabs/components/CreateWeeklySpecialModal/components/DateTimePicker'
import Title from 'components/Title'
import { PriceType } from 'constants/enums/special'
import { ICreateSpecial, ISpecial } from 'constants/interfaces'
import { defaultFormData } from 'stores/specialStore'
import { getValidArray } from 'utils/common'
import Images from '../../../EventTabs/components/DealTypeImages'
import styles from './specialForm.module.scss'

export interface ISpecialForm {
  toggleForm: () => void
  eventId?: number
}

const SpecialForm = (props: ISpecialForm) => {
  const { toggleForm, eventId } = props
  const { eventStore } = useStore()
  const methods = useForm<ICreateSpecial>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: defaultFormData,
  })
  const { register, control, errors, setValue, clearErrors, handleSubmit } = methods
  const [isSubmitting, setIsSubmitting] = useState(false)
  const priceType = useWatch({ control, name: 'priceType' })
  const [dateError, setDateError] = useState(undefined)
  const [date, setDate] = useState<DateObject | DateObject[]>([])
  const dealType = useWatch({ control, name: 'dealType' })
  const datePickerRef = useRef<any>(null)

  useEffect(() => {
    register('dealType')
  }, [register])

  useEffect(() => {
    setValue('dealType', dealType)
  }, [dealType])

  async function onSubmit(data: ICreateSpecial) {
    setIsSubmitting(true)
    const {
      description,
      price = 0,
      percentage = 0,
      priceType = 300,
      dealType = 200,
      startTime = new Date(),
      endTime = new Date(),
    } = data
    const newSpecial: ISpecial = {
      price: +priceType === PriceType.NORMAL ? +price : +percentage,
      price_type_ref_id: +priceType,
      details: description,
      deal_type_ref_id: +dealType,
      day: -1,
      start_time: startTime,
      end_time: endTime,
      dinein: 1,
      event_id: eventId,
      days: getValidArray<DateObject>(date as DateObject[]).map((e) => e.toDate()),
    }
    try {
      await createSpecial(newSpecial)
      setIsSubmitting(false)
      eventId && (await eventStore.fetchEventDetail(eventId))
      toast.success(`Create special successfully`)
      toggleForm()
    } catch (error) {
      let errorMsg = error?.message ?? `Create special failed`
      toast.error(errorMsg)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  function handleClickOutside(event: MouseEvent) {
    if (datePickerRef?.current && !datePickerRef?.current.contains(event.target)) {
      datePickerRef.current.closeCalendar()
    }
  }

  return (
    <FormProvider {...methods}>
      <form id="create-event-special-form" onSubmit={handleSubmit(onSubmit)}>
        <Row className={styles.specialForm}>
          <Col md={12}>
            <Row>
              <Col md={12} className={styles.datePicker}>
                <Title isRequired small>
                  Select day(s) this special occur on
                </Title>
                <DatePicker
                  value={date}
                  ref={datePickerRef}
                  type="input-icon"
                  onChange={setDate}
                  format="ddd DD/MM/YYYY"
                  animation
                  showOtherDays
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Title isRequired small>
                  Start time
                </Title>
                <DateTimePicker
                  name="startTime"
                  onChange={(e) => e}
                  showTimeSelect
                  control={control}
                  isTime
                  showTimeSelectOnly
                  timeIntervals={60}
                  setError={setDateError}
                  clearErrors={clearErrors}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                />
              </Col>
              <Col md={6}>
                <Title isRequired small>
                  End time
                </Title>
                <DateTimePicker
                  name="endTime"
                  onChange={(e) => e}
                  control={control}
                  setError={setDateError}
                  clearErrors={clearErrors}
                  showTimeSelect
                  isTime
                  showTimeSelectOnly
                  timeIntervals={60}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                />
              </Col>
            </Row>
            <ErrorMessage error={dateError} />
            <Row>
              <Col md={12}>
                <Title isRequired small hasMarginTop>
                  Price type
                </Title>
                <PriceTypeSelection disabled={false} errors={errors} register={register} />
                <Row>
                  <Col md={3}>
                    <Input
                      className={cx({
                        [styles.error]: priceType === `${PriceType.NORMAL}` && !!errors['price'],
                      })}
                      name="price"
                      type="number"
                      errors={errors}
                      placeholder="0.00 $"
                      required={priceType === `${PriceType.NORMAL}`}
                      innerRef={register({
                        required: priceType === `${PriceType.NORMAL}`,
                      })}
                      disabled={priceType !== `${PriceType.NORMAL}`}
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      name="percentage"
                      className={cx({
                        [styles.error]: priceType === `${PriceType.OFF}` && !!errors['percentage'],
                      })}
                      type="number"
                      errors={errors}
                      placeholder="0.00 %"
                      required={priceType === `${PriceType.OFF}`}
                      innerRef={register({
                        required: priceType === `${PriceType.OFF}`,
                        max: 100,
                        min: 0.01,
                      })}
                      disabled={priceType !== `${PriceType.OFF}`}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Title isRequired small hasMarginTop>
                  Details
                </Title>
                <Input
                  name="description"
                  className={cx({ [styles.error]: !!errors['description'] })}
                  placeholder="Details of Special"
                  innerRef={register({ required: true, maxLength: 40 })}
                />
                <p className={styles.hintText}>Limit 40 characters</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Title isRequired small smallMargin>
                  Select an icon to display with your special
                </Title>
              </Col>
              <Images control={control} name="dealType" setValue={setValue} />
            </Row>
            <Row>
              <Col md={12} className={styles.footer}>
                <Button className={styles.cancel} onClick={toggleForm}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} className={styles.add} disabled={isSubmitting}>
                  Add
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </FormProvider>
  )
}

export default SpecialForm
