import cx from 'classnames'
import Select from 'react-select'
import Label from '../../Label'
import styles from './inputDropdown.module.scss'

export interface IOption {
  id?: string
  name?: string
  label?: string
  value?: number
}

interface IInputDropDownProps {
  label?: string
  options: IOption[]
  defaultValue?: IOption
  value?: IOption | IOption[]
  isCreatable?: boolean
  isMulti?: boolean
  name?: string
  required?: boolean
  isDisabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (event: any) => void
  getOptionLabel?: (option: IOption) => string
  placeholder?: string
  isTextSmall?: boolean
  hasNoSeparator?: boolean
  className?: string
  containerClassName?: string
  labelClassName?: string
  isNoLabel?: boolean
  onInputChange?: (textInput: string) => void
  id?: string
  isClearable?: boolean
}

const InputDropDown = (props: IInputDropDownProps) => {
  const {
    label,
    options,
    isMulti,
    name,
    isDisabled,
    required = false,
    placeholder,
    isTextSmall,
    hasNoSeparator,
    className,
    containerClassName,
    labelClassName,
    isNoLabel,
    onInputChange,
    onChange,
    value,
    id,
    isClearable,
  } = props

  const components: Record<string, unknown> = {}

  if (hasNoSeparator) {
    components.IndicatorSeparator = null
  }

  return (
    <div
      className={cx(containerClassName, styles.container, {
        [styles.noLabel]: isNoLabel,
      })}
    >
      <Label>
        <span className={cx(styles.fieldLabel, labelClassName)}>{label}</span>
        {required && <span className={styles.required}> *</span>}
        <Select
          {...props}
          name={name}
          isDisabled={isDisabled}
          isMulti={isMulti}
          className={cx(styles.dropdown, className)}
          classNamePrefix="select"
          styles={{
            control: (base) => ({
              ...base,
              minHeight: 40,
              fontSize: isTextSmall ? '14px' : '16px',
            }),
            option: (optionStyles) => {
              return {
                ...optionStyles,
              }
            },
          }}
          options={options}
          placeholder={placeholder}
          components={components}
          onInputChange={onInputChange}
          onChange={onChange}
          value={value}
          id={id}
          isClearable={isClearable}
        />
      </Label>
    </div>
  )
}

export default InputDropDown
