import MainLayout from 'components/MainLayout'
import AnalyticsPage from 'components/Pages/AnalyticsPage'

const AnalyticsLayout = () => {
  return (
    <MainLayout>
      <AnalyticsPage />
    </MainLayout>
  )
}

export default AnalyticsLayout
