import cx from 'classnames'
import Switch from 'react-switch'
import styles from './switcher.module.scss'

export interface ISwitcherProps {
  className?: string
  value: boolean
  setValue: (value: boolean) => void
  label?: string
}
export const Switcher = (props: ISwitcherProps) => {
  const { className, value, setValue, label } = props

  function handleChange(value: boolean) {
    setValue(value)
  }

  return (
    <div className={cx(className, styles.switcher)}>
      <Switch
        checked={value}
        onChange={handleChange}
        onColor="#ff7b1a"
        onHandleColor="#fff"
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow="0px"
        height={16}
        width={28}
      />
      <span>{label}</span>
    </div>
  )
}
