import { Row, Col } from 'reactstrap'
import Divider from 'components/Divider'
import Icon from 'components/Icon'

import styles from './headerSection.module.scss'

interface IHeaderSectionProps {
  titleText: string
  onClickClose?: (event: unknown) => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { onClickClose, titleText } = props

  return (
    <>
      <Row className={styles.modalHeader}>
        <Col className={styles.modalHeaderTextStyle} xl="11" lg="11" md="11">
          {titleText}
        </Col>
        <Col className={styles.modalHeaderButtonStyle} onClick={onClickClose} xl="1" lg="1" md="1">
          <Icon icon="times" />
        </Col>
      </Row>
      <Row>
        <Col className={styles.dividerStyle}>
          <Divider />
        </Col>
      </Row>
    </>
  )
}

export default HeaderSection
