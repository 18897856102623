import dayjs from 'dayjs'
import { ITableHeader } from 'types/table'

export enum EViewSwitcher {
  GRID_VIEW = 'GRID VIEW',
  TABLE_VIEW = 'TABLE VIEW',
}

export enum ETableHeaderType {
  TITLE = 'title',
  LABEL = 'label',
  STATUS = 'status',
  DATE_TIME_GROUP = 'dateTimeGroup',
  ACTIONS = 'actions',
}

export enum EStatusType {
  PENDING_ACTIVATION = 'Pending activation',
  PENDING_REVIEW = 'Pending review',
  PENDING_DENIED = 'Pending denied',
}

export function getHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Label',
      accessor: 'label',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Impressions / Interactions',
      accessor: 'dateTimeGroup',
    },
    {
      Header: ' ',
      accessor: 'actions',
    },
  ]

  return headers
}

export const getThisIntervalDate = () => {
  const fromDate = new Date().setDate(new Date().getDate() - 7)
  const toDate = new Date().setDate(new Date().getDate() - 1)
  return `${dayjs(fromDate).format('DD/MM')} - ${dayjs(toDate).format('DD/MM')}`
}

export const getLastIntervalDate = () => {
  const fromDate = new Date().setDate(new Date().getDate() - 14)
  const toDate = new Date().setDate(new Date().getDate() - 8)
  return `${dayjs(fromDate).format('DD/MM')} - ${dayjs(toDate).format('DD/MM')}`
}
