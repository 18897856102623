import { IFilter } from 'types/common'
import { api, errorHandler } from 'Api'
import { IServerError } from 'Api/constants'
import { IEstablishment } from 'constants/interfaces'

export async function getEstablishmentTableData() {
  try {
    const response = await api.get(`/establishments/table`)
    return response.data
  } catch (err) {
    return false
  }
}

export async function getEstablishmentList(filter: IFilter<IEstablishment> = {}): Promise<IEstablishment[]> {
  try {
    const response = await api.get(`/establishments?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return []
  }
}

export async function updateEstablishment(data: IEstablishment): Promise<void> {
  try {
    await api.patch(`/establishments/${data.establishment_id}`, data)
    return undefined
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return undefined
  }
}

export async function getEstablishmentDetail(
  id: number,
  filter: IFilter<IEstablishment> = {}
): Promise<IEstablishment> {
  try {
    const response = await api.get(`/establishments/${id}?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return {}
  }
}

export async function createNewEstablishment(promotion: unknown): Promise<IEstablishment> {
  try {
    const response = await api.post(`/establishments`, promotion)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return {}
  }
}
