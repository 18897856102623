import { useState, useEffect } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useForm, FormProvider } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Container, Row, Col } from 'reactstrap'
import {
  getFeatures,
  getTime,
  getGeneral,
  editGeneral,
  editTime,
  editFeatures,
  getPresignedUrl,
  postProfilePictureUrlOnS3,
} from 'Api/business'
import Button from 'components/Button'
import Card from 'components/Card'
import Icon from 'components/Icon'
import ListGroup from 'components/ListGroup'
import ListGroupItem from 'components/ListGroupItem'
import { IBusinessInfo } from 'constants/interfaces'
import ConfirmContinueModal from '../../ConfirmModal/confirmContinueModal'
import EstablishmentFeatures from './components/EstablishmentFeatures'
import GeneralInformation from './components/GeneralInformation'
import OperatingHour from './components/OperatingHours'
import { BusinessInfoSections, defaultFormValue } from './constants'
import { mapToDateTime } from './utils'
import styles from './businessInfoPage.module.scss'

const BusinessInfoPage = () => {
  const methods = useForm<IBusinessInfo>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: defaultFormValue,
  })
  const { formState, reset, handleSubmit } = methods
  const { authStore, spinnerStore } = useStore()
  const { establishment } = authStore

  const { isDirty, isSubmitSuccessful } = formState

  const [businessInfoSection, setBusinessInfoSection] = useState(BusinessInfoSections.GENERAL_INFORMATION)

  const [nextBusinessInfoSection, setNextBusinessInfoSection] = useState(BusinessInfoSections.GENERAL_INFORMATION)

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (establishment && businessInfoSection) {
      spinnerStore.showLoading()
      getBusinessInfoData()
    }
  }, [businessInfoSection, establishment])

  async function getBusinessInfoData(): Promise<void> {
    let data: any = {}
    if (businessInfoSection === BusinessInfoSections.GENERAL_INFORMATION) {
      data = await getGeneral()
    } else if (businessInfoSection === BusinessInfoSections.OPERATING_HOURS) {
      data = await getTime()
      data = mapToDateTime(data)
    } else if (businessInfoSection === BusinessInfoSections.ESTABLISHMENT_FEATURES) {
      data = await getFeatures()
    }
    if (data) {
      reset(data)
    }
    spinnerStore.hideLoading()
  }

  function onAcceptLostButtonClick(): void {
    setIsModalOpen(false)
    reset(defaultFormValue)
    setBusinessInfoSection(nextBusinessInfoSection)
    setNextBusinessInfoSection(BusinessInfoSections.GENERAL_INFORMATION)
  }

  function onBusinessChange(section: BusinessInfoSections): void {
    if (section !== businessInfoSection) {
      if (isDirty && !isSubmitSuccessful) {
        setNextBusinessInfoSection(section)
        setIsModalOpen(true)
      } else {
        setBusinessInfoSection(section)
      }
    }
  }

  async function onSubmit(data: IBusinessInfo): Promise<void> {
    let result
    try {
      if (businessInfoSection === BusinessInfoSections.GENERAL_INFORMATION) {
        let updateGeneralInfoData: IBusinessInfo = {
          ...data,
          phone_num: data.phone_num?.replace(/[^0-9]+/g, ''),
        }
        delete updateGeneralInfoData?.image_name

        if (data.profilePicture.length > 0) {
          try {
            const presignedUrl = await getPresignedUrl(get(data, 'profilePicture[0].name', null))
            const type = get(data, 'profilePicture[0].type', null)
            postProfilePictureUrlOnS3(presignedUrl, get(data, 'profilePicture[0]'), type)
            updateGeneralInfoData.image_name = get(data.profilePicture, '0', null)?.name
          } catch (error) {
            console.error(error)
            toast.error('Cannot upload profile picture')
          }
        }
        delete updateGeneralInfoData.profilePictureUrl
        delete updateGeneralInfoData.profilePicture
        result = await editGeneral(updateGeneralInfoData)
      } else if (businessInfoSection === BusinessInfoSections.OPERATING_HOURS) {
        result = await editTime(data)
      } else {
        result = await editFeatures(data)
      }
      if (result) {
        toast.success('Update successfully')
      } else {
        toast.error('Update fail')
      }
    } catch (error) {
      console.log(error)
      toast.error('Update fail')
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitle}>Business Information</div>
      <Card>
        <Container fluid>
          <Row>
            <Col className={styles.leftSide} xs="12" md="3">
              <ListGroup>
                <ListGroupItem
                  className={cx(styles.listItem, {
                    [styles.listItemActive]: businessInfoSection === BusinessInfoSections.GENERAL_INFORMATION,
                  })}
                  onClick={() => onBusinessChange(BusinessInfoSections.GENERAL_INFORMATION)}
                >
                  <Icon icon={'exclamation-circle'} className={styles.listItemIcon} />
                  {BusinessInfoSections.GENERAL_INFORMATION}
                </ListGroupItem>
                <ListGroupItem
                  className={cx(styles.listItem, {
                    [styles.listItemActive]: businessInfoSection === BusinessInfoSections.OPERATING_HOURS,
                  })}
                  onClick={() => onBusinessChange(BusinessInfoSections.OPERATING_HOURS)}
                >
                  <Icon icon={'calendar-check'} className={styles.listItemIcon} />
                  {BusinessInfoSections.OPERATING_HOURS}
                </ListGroupItem>
                <ListGroupItem
                  className={cx(styles.listItem, {
                    [styles.listItemActive]: businessInfoSection === BusinessInfoSections.ESTABLISHMENT_FEATURES,
                  })}
                  onClick={() => onBusinessChange(BusinessInfoSections.ESTABLISHMENT_FEATURES)}
                >
                  <Icon icon={'wrench'} className={styles.listItemIcon} />
                  {BusinessInfoSections.ESTABLISHMENT_FEATURES}
                </ListGroupItem>
              </ListGroup>
            </Col>
            <Col className={styles.rightSide} xs="12" md="9">
              <FormProvider {...methods}>
                <form id="business-info-form" onSubmit={handleSubmit(onSubmit)}>
                  <Container>
                    <Row>
                      {businessInfoSection === BusinessInfoSections.GENERAL_INFORMATION ? (
                        <GeneralInformation />
                      ) : businessInfoSection === BusinessInfoSections.OPERATING_HOURS ? (
                        <OperatingHour />
                      ) : (
                        <EstablishmentFeatures />
                      )}
                    </Row>
                    <Row className={styles.saveButtonRow}>
                      <Button className={styles.saveButton} form="business-info-form" type="submit">
                        <Icon icon={'save'} className={styles.listItemIcon} /> Save
                      </Button>
                    </Row>
                  </Container>
                  <ConfirmContinueModal
                    isOpen={isModalOpen}
                    setOpen={setIsModalOpen}
                    onAcceptLostButton={onAcceptLostButtonClick}
                  />
                </form>
              </FormProvider>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  )
}

export default observer(BusinessInfoPage)
