import { api, errorHandler } from 'Api'
import { IServerError } from 'Api/constants'
import { IAnalyticsData } from 'constants/interfaces'

export async function analytics(daysAgo: number): Promise<IAnalyticsData | boolean> {
  try {
    const response = await api.get(`/analytics/${daysAgo}`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}
