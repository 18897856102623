import MainLayout from 'components/MainLayout'
import EventPage from '../../components/Pages/EventPage'
import styles from './eventPage.module.scss'

const EventLayout = () => {
  return (
    <MainLayout withOutAuth>
      <div className={styles.container}>
        <EventPage />
      </div>
    </MainLayout>
  )
}

export default EventLayout
