import axios from 'axios'
import { AuthenticateParams } from 'stores/authStore'
import { IServerError, ServerErrorMessage } from './constants'

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/v2',
})

api.interceptors.request.use(
  (config) => {
    const token =
      localStorage.getItem(AuthenticateParams.ACCESS_TOKEN) || sessionStorage.getItem(AuthenticateParams.ACCESS_TOKEN)
    const establishmentId = localStorage.getItem(AuthenticateParams.ACCESS_ESTABLISHMENT)
    if (token && establishmentId) {
      config.headers['Authorization'] = `Bearer ${token}`
      config.headers['Access-Establishment'] = establishmentId
    }
    return config
  },
  (error) => Promise.reject(error)
)

export const errorHandler = (error: IServerError): void => {
  switch (error?.statusCode) {
    case 401:
      if (error?.message === ServerErrorMessage.JWT_EXPIRED) {
        redirectLogin()
      } else if (error?.message === ServerErrorMessage.UNAVAILABLE_USER) {
        redirectLogin()
      } else if (error?.message === 'invalid token') {
        redirectLogin()
      } else {
        throw new Error(error?.message)
      }
      break
    default:
      console.log(error)
  }
}

function redirectLogin(): void {
  localStorage.removeItem(AuthenticateParams.ACCESS_TOKEN)
  sessionStorage.removeItem(AuthenticateParams.ACCESS_TOKEN)
  localStorage.removeItem(AuthenticateParams.ACCESS_ESTABLISHMENT)
  window && window?.location && window.location.replace('/')
}
