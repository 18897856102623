import React from 'react'
import cx from 'classnames'
import get from 'lodash/get'
import Skeleton from 'react-loading-skeleton'
import { useTable, useExpanded, Column, HeaderGroup } from 'react-table'
import SortIcon from 'components/Table/components/SortIcon'
import TableWrapper from 'components/Table/components/TableWrapper'
import styles from 'components/Table/table.module.scss'
import { ETableHeaderType, getLastIntervalDate, getThisIntervalDate } from '../../constant'
import ActionCell from './components/ActionCell'
import DateCell from './components/DateCell'
import StatusCell from './components/StatusCell'
import tableStyles from './styles.module.scss'

export interface IPagination {
  includePagination?: boolean
  tableLength: number
  pageIndex: number
  gotoPage: (pageIndex: number) => void
}

interface ITableProps {
  tableData?: object[]
  headerList?: any[]
  subComponent?: any
  isLoading?: boolean
  hasNoSort?: boolean
  columnWidth?: number
  isScrollAble?: boolean
  isHightLightExpanded?: boolean
  isManualSort?: boolean
  isStriped?: boolean
  isNotInitialState?: boolean
  isStripedWithExpand?: boolean
  bodyClassName?: string
  hasDivWrapper?: boolean
  defaultExpanded?: Record<string, boolean>
  subComponentStyle?: string
}

const Table = (props: ITableProps) => {
  const {
    tableData,
    headerList,
    subComponent,
    isLoading = false,
    hasNoSort,
    isScrollAble,
    isHightLightExpanded = false,
    isStriped = false,
    bodyClassName,
    isStripedWithExpand = false,
    hasDivWrapper = false,
    subComponentStyle,
    defaultExpanded = {},
  } = props

  const initialExpanded: any = {
    expanded: defaultExpanded,
  }

  const initialState: any = { ...initialExpanded }

  const columns: Column<object>[] = React.useMemo(() => headerList, [headerList]) || []
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
    {
      columns,
      data: tableData as object[],
      initialState,
    },
    useExpanded
  )

  const thisIntervalDate = getThisIntervalDate()
  const lastIntervalDate = getLastIntervalDate()

  function showSortIcon(column: Column): React.ReactNode | string {
    return column.Header ? <SortIcon column={column} /> : ''
  }

  return (
    <>
      <TableWrapper isScrollAble={isScrollAble} hasDivWrapper={hasDivWrapper}>
        <table className={cx(styles.table)} {...getTableProps()}>
          <thead className={cx(styles.thead)}>
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroup } = headerGroup.getHeaderGroupProps()
              return (
                <tr key={`tr-${key}`} {...restHeaderGroup}>
                  {headerGroup.headers.map((column: HeaderGroup) => {
                    const { key, ...restColumnHeaderProps } = column.getHeaderProps()
                    return column.id === ETableHeaderType.DATE_TIME_GROUP ? (
                      <th key={`th-${key}`}>
                        <div className={tableStyles.dateTimeGroupHeader}>{column.render('Header')}</div>
                        <div className={tableStyles.subDateTimeGroupHeader}>
                          <div>{lastIntervalDate}</div>
                          <div>{thisIntervalDate}</div>
                          <div>All time</div>
                        </div>
                      </th>
                    ) : (
                      <th key={`th-${key}`} {...restColumnHeaderProps}>
                        {column.render('Header')}
                        &nbsp;
                        {hasNoSort ? '' : <span> {showSortIcon(column)} </span>}
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody
            className={cx(styles.tbody, bodyClassName, {
              [styles.isStriped]: isStriped,
            })}
            {...getTableBodyProps()}
          >
            {rows.map((row, index: number) => {
              prepareRow(row)
              return (
                <React.Fragment key={`row-${index}`}>
                  {isLoading && <Skeleton className={styles.loadingBar} height={80} />}
                  <tr
                    {...row.getRowProps()}
                    className={cx({
                      [styles.isExpanded]: get(row, 'isExpanded', false) && isHightLightExpanded,
                      [styles.isHightLightGreen]: isStripedWithExpand && index % 2,
                    })}
                  >
                    {row.cells.map((cell) => {
                      const { key } = cell.getCellProps()
                      if (cell.column.id === ETableHeaderType.TITLE || cell.column.id === ETableHeaderType.LABEL) {
                        cell.value = cell.value !== '' ? cell.value : '-'
                      }

                      return cell.column.id === ETableHeaderType.DATE_TIME_GROUP ? (
                        <DateCell key={`cell-${key}`} value={cell.value} status={row.values.status} />
                      ) : cell.column.id === ETableHeaderType.STATUS ? (
                        <StatusCell key={`cell-${key}`} value={cell.value} />
                      ) : cell.column.id === ETableHeaderType.ACTIONS ? (
                        <ActionCell key={`cell-${key}`} values={row.original} />
                      ) : (
                        <td>
                          <div
                            key={`cell-${key}`}
                            className={cell.column.id === ETableHeaderType.TITLE ? styles.titleCell : ''}
                          >
                            {cell.value}
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                  {subComponent && get(row, 'isExpanded', false) && (
                    <tr
                      className={cx({
                        [styles.isHightLightBlue]: isStripedWithExpand,
                      })}
                    >
                      <td
                        colSpan={visibleColumns.length}
                        className={cx(styles.subComponentContainer, subComponentStyle)}
                      >
                        {subComponent(row)}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </TableWrapper>
    </>
  )
}

export default Table
