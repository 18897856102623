import get from 'lodash/get'
import { PriceType } from 'constants/enums/special'
import { ISpecial } from 'constants/interfaces'

export function getPriceTitle(price: number, percentage: number, priceType: any): string {
  switch (priceType) {
    case `${PriceType.NORMAL}`:
      return `$${price}`
    case `${PriceType.OFF}`:
      return `${percentage}% Off`
    case `${PriceType.BOGO}`:
      return `BOGO`
    case `${PriceType.FREE}`:
      return `Free`
    default:
      return ''
  }
}

export function defaultExclusiveExpandBuilder(exclusives: ISpecial[]) {
  if (!exclusives || !Array.isArray(exclusives)) {
    return {}
  }
  const defaultExpand: Record<string, boolean> = {}
  exclusives.forEach((exclusive, index) => {
    const current = get(exclusive, 'current', [])
    const upcoming = get(exclusive, 'upcoming', [])

    if (current.length || upcoming.length) {
      defaultExpand[index] = true
    }
  })

  return defaultExpand
}

export function defaultWeeklyExpandBuilder(weeklySpecials: ISpecial[]) {
  if (!weeklySpecials || !Array.isArray(weeklySpecials)) {
    return {}
  }
  const defaultExpand: Record<string, boolean> = {}

  weeklySpecials.forEach((daySpecials, index) => {
    if (get(daySpecials, 'length', 0)) {
      defaultExpand[index] = true
    }
  })

  return defaultExpand
}
