import { IBusinessInfo } from 'constants/interfaces'

export enum BusinessInfoSections {
  GENERAL_INFORMATION = 'General Information',
  OPERATING_HOURS = 'Operating Hours',
  ESTABLISHMENT_FEATURES = 'Establishment Features',
}

export const defaultFormValue: IBusinessInfo = {
  profilePicture: null,
  profilePictureUrl: null,
  name: '',
  phone_num: '',
  address_1: '',
  city: '',
  state: '',
  zip: '',
  website: '',
  resWebsite: '',
  menu_link: '',
  mon_open: '',
  mon_close: '',
  tue_open: '',
  tue_close: '',
  wed_open: '',
  wed_close: '',
  thu_open: '',
  thu_close: '',
  fri_open: '',
  fri_close: '',
  sat_open: '',
  sat_close: '',
  sun_open: '',
  sun_close: '',
  description: '',
  delivery: false,
  takeout: false,
  patio: false,
  rooftop: false,
  brunch: false,
  dog_friendly: false,
}
