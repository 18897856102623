import { ITableHeader } from 'types'

export function getHeaderList(expandableCell: React.ReactNode, hasType?: boolean) {
  const headers: ITableHeader[] = [
    {
      Header: 'Title',
      accessor: 'title',
      Cell: expandableCell,
    },
    {
      Header: 'Detail',
      accessor: 'detail',
      Cell: expandableCell,
    },
    {
      Header: 'Special(s)',
      accessor: 'specialCount',
      Cell: expandableCell,
    },
  ]
  hasType &&
    headers.push({
      Header: 'Type',
      accessor: 'type',
      Cell: expandableCell,
    })
  headers.push(
    {
      Header: 'Time',
      accessor: 'time',
      Cell: expandableCell,
    },
    {
      Header: '',
      accessor: 'actions',
    }
  )

  return headers
}
