import cx from 'classnames'
import Icon, { IconGroupPrefix } from 'components/Icon'
import styles from './buttonWithIcon.module.scss'

interface ButtonWithIconProps {
  iconName?: string
  iconGroup?: IconGroupPrefix
  className?: string
  type?: 'submit' | 'reset' | 'button'
  content?: string
  disable?: boolean
  onClick?: () => void
}

const ButtonWithIcon = (props: ButtonWithIconProps) => {
  const { iconName, iconGroup, className, content, type, disable, onClick } = props
  return (
    <button type={type} className={cx(styles.button, className)} onClick={onClick} disabled={disable}>
      {iconName && <Icon icon={iconName} group={iconGroup} />}
      {content}
    </button>
  )
}

export default ButtonWithIcon
