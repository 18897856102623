import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import { ButtonGroup, Button } from 'reactstrap'
import { WeekDayEnum } from '../../constants'
import styles from './chooseWeekDay.module.scss'

export interface IChooseWeekDayProps {
  weekDay: WeekDayEnum[]
  setWeekDay: (weekDay: WeekDayEnum[]) => void
  refProp?: any
  weeklyEventCount: number
}

const ChooseWeekDay = (props: IChooseWeekDayProps) => {
  const { weekDay, setWeekDay, refProp, weeklyEventCount } = props
  const disableTooltipText: string = 'No more weekly event left'
  const isNoEventLeft: boolean = weeklyEventCount + weekDay.length >= 3
  function toggleChooseWeekDay(day: WeekDayEnum): void {
    if (weekDay.includes(day)) {
      const result: WeekDayEnum[] = weekDay.filter((e) => e !== day)
      setWeekDay([...result])
    } else if (!isNoEventLeft) {
      setWeekDay([...weekDay, day])
    }
  }

  return (
    <ButtonGroup className={styles.buttonGroup}>
      <div ref={refProp} />
      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.MONDAY),
          [styles.isDisable]: isNoEventLeft,
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.MONDAY)}
      >
        {capitalize(WeekDayEnum.MONDAY.slice(0, 3))}
        {disableTooltipText && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.TUESDAY),
          [styles.isDisable]: isNoEventLeft,
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.TUESDAY)}
      >
        {capitalize(WeekDayEnum.TUESDAY.slice(0, 3))}
        {disableTooltipText && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.WEDNESDAY),
          [styles.isDisable]: isNoEventLeft,
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.WEDNESDAY)}
      >
        {capitalize(WeekDayEnum.WEDNESDAY.slice(0, 3))}
        {disableTooltipText && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.THURSDAY),
          [styles.isDisable]: isNoEventLeft,
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.THURSDAY)}
      >
        {capitalize(WeekDayEnum.THURSDAY.slice(0, 3))}
        {disableTooltipText && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.FRIDAY),
          [styles.isDisable]: isNoEventLeft,
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.FRIDAY)}
      >
        {capitalize(WeekDayEnum.FRIDAY.slice(0, 3))}
        {disableTooltipText && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.SATURDAY),
          [styles.isDisable]: isNoEventLeft,
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.SATURDAY)}
      >
        {capitalize(WeekDayEnum.SATURDAY.slice(0, 3))}
        {disableTooltipText && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>

      <Button
        className={classNames(styles.tooltip, {
          [styles.isSelected]: !!weekDay.includes(WeekDayEnum.SUNDAY),
          [styles.isDisable]: isNoEventLeft,
        })}
        onClick={() => toggleChooseWeekDay(WeekDayEnum.SUNDAY)}
      >
        {capitalize(WeekDayEnum.SUNDAY.slice(0, 3))}
        {disableTooltipText && <span className={styles.tooltiptext}>{disableTooltipText}</span>}
      </Button>
    </ButtonGroup>
  )
}

export default ChooseWeekDay
