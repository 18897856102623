import { useState } from 'react'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import capitalize from 'lodash/capitalize'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { Col, Row } from 'reactstrap'
import { createManyWeeklySpecials } from 'Api/special'
import Button from 'components/Button'
import { convertToOccurDays } from 'components/DateTimePicker/utils'
import ModalDialog from 'components/ModalDialog'
import { getDealImage } from 'components/Pages/AnalyticsPage/utils'
import { getPriceTitle } from 'components/Pages/SpecialPage/utils'
import StatusCell from 'components/Table/components/StatusCell'
import Title from 'components/Title'
import { IMAGE_SOURCE_URL } from 'constants/common'
import { CategoryType, PriceType } from 'constants/enums/special'
import { ISpecial } from 'constants/interfaces'
import { WeekDayEnum } from '../CreateWeeklySpecialModal/constants'
import LabelValueLine from '../LabelValueLine'
import { bulkEditRelatedSpecials, ignoreTimezoneParsing } from './utils'
import styles from './reviewSpecialModal.module.scss'
interface IReviewSpecialModal {
  isOpening: boolean
  toggleShow: () => void
  handleEdit: () => void
}

const ReviewSpecialModal = (props: IReviewSpecialModal) => {
  const { isOpening = false, toggleShow, handleEdit } = props
  const { specialStore } = useStore()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { formData, editingSpecials = [] } = specialStore
  const isEditing = editingSpecials?.length ?? 0
  const {
    description,
    price = 0,
    percentage = 0,
    priceType = 300,
    dealType = 200,
    type,
    startTime = new Date(),
    endTime = new Date(),
    days = [],
    previousDays = [],
  } = formData
  const displayPrice = getPriceTitle(+price, +percentage, priceType)

  async function handleSubmit() {
    setIsSubmitting(true)
    const newWeeklySpecial: ISpecial = {
      price: +priceType === PriceType.NORMAL ? +price : +percentage,
      price_type_ref_id: +priceType,
      details: description,
      deal_type_ref_id: +dealType,
      day: -1,
      occurDays: convertToOccurDays(days),
      start_time: ignoreTimezoneParsing(startTime),
      end_time: ignoreTimezoneParsing(endTime),
      dinein: type.includes(CategoryType.DINEIN) ? 1 : 0,
      carryout: type.includes(CategoryType.TAKEOUT) ? 1 : 0,
    }

    try {
      if (previousDays.length && editingSpecials.length) {
        await bulkEditRelatedSpecials(
          editingSpecials,
          convertToOccurDays(days),
          convertToOccurDays(previousDays),
          newWeeklySpecial
        )
      } else {
        await createManyWeeklySpecials(newWeeklySpecial)
      }
      specialStore.unsetEditingSpecials()
      specialStore.unsetFormData()
      specialStore.getTablesData()
      toggleShow()
      toast.success(`${isEditing ? 'Edit' : 'Create'} special successfully`)
    } catch (error) {
      toast.error(`${isEditing ? 'Edit' : 'Create'} special failed`)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <ModalDialog
      title={`${isEditing ? 'Edit' : 'Create new'} Weekly Special`}
      toggle={toggleShow}
      isOpen={isOpening}
      className={styles.modal}
      footer={
        <>
          <Button className={styles.infoButton} onClick={handleEdit}>
            Back to edit
          </Button>
          <Button disabled={isSubmitting} color="primary" type="submit" onClick={handleSubmit}>
            Confirm & {isEditing ? 'Edit' : 'Create'} Special
          </Button>
        </>
      }
    >
      <Row>
        <Col md={12}>
          <Title small>Here's a preview of how your special will look to your customers.</Title>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <div className={styles.cardWrapper}>
            <img className={styles.image} src={`${IMAGE_SOURCE_URL}/${getDealImage(+dealType)}`} alt="logo" />
            <h5 className={styles.cardLabel}>{`${displayPrice}`}</h5>
          </div>
        </Col>
        <Col md={9}>
          <h4>{`${displayPrice} ${description}`}</h4>
          {type.includes(CategoryType.DINEIN) && (
            <StatusCell status={CategoryType.DINEIN}>{CategoryType.DINEIN}</StatusCell>
          )}
          {type.includes(CategoryType.TAKEOUT) && (
            <StatusCell className={styles.padding} status={CategoryType.TAKEOUT}>
              {CategoryType.TAKEOUT}
            </StatusCell>
          )}
          <LabelValueLine label={'Day(s)'} value={days.map((day: WeekDayEnum) => capitalize(day)).join(', ')} />
          <div className={styles.timeContainer}>
            <LabelValueLine
              label={'Duration'}
              value={` ${dayjs(startTime).format('h:mm A')}  - ${dayjs(endTime).format('h:mm A')}`}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Title small>
            If you're satisfied with your special, click the <b>Confirm & {isEditing ? 'Edit' : 'Create'} Special</b>{' '}
            button below!
          </Title>
        </Col>
      </Row>
    </ModalDialog>
  )
}

export default observer(ReviewSpecialModal)
