import find from 'lodash/find'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import { RootStore } from 'stores'
import { login } from 'Api/auth'
import { checkUserCondition } from 'Api/user'
import { RoleType } from 'constants/enums/role'
import { Timezone } from 'constants/enums/timezone'
import { convertTopicToTimezone } from 'utils/timezone'

export enum AuthenticateParams {
  ACCESS_TOKEN = 'accessToken',
  ACCESS_ESTABLISHMENT = 'establishment',
}

export default class AuthStore {
  rootStore: RootStore

  token: string = ''
  establishment: number = 0
  role: RoleType = RoleType.ESTABLISHMENT

  email: string = ''
  username: string = ''

  constructor(rootStore: RootStore) {
    //TODO: may need in some special case
    // makeObservable(this, {
    //   token: observable,
    //   establishment: observable,
    //   getMyUser: action,
    //   getMyEstablishments: action,
    //   setAccessToken: action,
    //   setAccessEstablishment: action,
    //   login: action,
    // })

    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  getMyUser(): void {
    const accessToken: string | null =
      localStorage.getItem(AuthenticateParams.ACCESS_TOKEN) || sessionStorage.getItem(AuthenticateParams.ACCESS_TOKEN)
    if (accessToken) {
      this.token = accessToken
    }
  }

  setUsername(username: string): void {
    this.username = username
  }

  setRole(role: RoleType): void {
    this.role = role
  }

  async getEmail(roleType: RoleType) {
    const user = await checkUserCondition({ where: { username: this.username }, fields: { email: true } }, roleType)
    this.email = get(user, '[0].email')
  }

  getMyEstablishments(): void {
    const accessEstablishments = localStorage.getItem(AuthenticateParams.ACCESS_ESTABLISHMENT) || ''
    if (accessEstablishments && accessEstablishments !== 'undefined') {
      this.establishment = JSON.parse(accessEstablishments)
    }
  }

  setAccessToken(accessToken: string, type?: 'local' | 'session'): void {
    if (type === 'local') {
      localStorage.setItem(AuthenticateParams.ACCESS_TOKEN, accessToken)
    } else if (type === 'session') {
      sessionStorage.setItem(AuthenticateParams.ACCESS_TOKEN, accessToken)
    } else if (!type) {
      localStorage.setItem(AuthenticateParams.ACCESS_TOKEN, accessToken)
      sessionStorage.setItem(AuthenticateParams.ACCESS_TOKEN, accessToken)
    }
    this.token = accessToken
  }

  public setAccessEstablishment(establishmentId: number): void {
    const establishments = this.rootStore.establishmentStore.establishments
    const topic = get(find(establishments, ['establishment_id', establishmentId]), 'topic')
    const currentTimezone = convertTopicToTimezone(topic)
    this.rootStore.establishmentStore.currentTimezone = currentTimezone
    localStorage.setItem(AuthenticateParams.ACCESS_ESTABLISHMENT, JSON.stringify(establishmentId))
    this.establishment = establishmentId
  }

  async login(username: string, password: string, role: RoleType, isRemember: boolean) {
    const res = await login({ username, password, type: role })
    this.setRole(role)
    if (get(res, 'token', '')) {
      if (isRemember) {
        this.setAccessToken(get(res, 'token', ''), 'local')
      } else {
        this.setAccessToken(get(res, 'token', ''), 'session')
      }
      this.getMyUser()
      const establishments = get(res, 'establishments', [])
      if (establishments) {
        const currentTimezone = convertTopicToTimezone(get(res, 'establishments[0].topic'))
        this.rootStore.establishmentStore.currentTimezone = currentTimezone
        this.setAccessEstablishment(get(res, 'establishments[0].establishment_id'))
      }

      if (role === RoleType.SUPPLIER) {
        this.rootStore.establishmentStore.currentTimezone = Timezone.EST
        this.setAccessEstablishment(0)
      }
      return true
    }
    return false
  }
}
