import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { toast } from 'react-toastify'
import Icon, { IconGroupPrefix } from 'components/Icon'
import { EStatusType } from 'components/Pages/CampaignPage/constant'
import { LIMIT_PAGE_BREAK } from 'components/Table/components/Pagination'
import styles from './styles.module.scss'

interface IActionProps {
  values: any
}

const ActionCell = (props: IActionProps) => {
  const { promo_id, status, active } = props.values
  const { campaignStore } = useStore()

  const handleActivate = async () => {
    const result = await campaignStore.updateCampaign(promo_id)

    if (result) {
      campaignStore.getAllCampaigns(LIMIT_PAGE_BREAK, campaignStore.pageIndex, '')
      toast.success('Update successfully')
    } else {
      toast.error('Update fail')
    }
  }

  return (
    <td>
      <div className={styles.cellWrapper}>
        <Icon className={styles.icon} icon="file-alt" group={IconGroupPrefix.LAICON} />
        {status === EStatusType.PENDING_ACTIVATION ? (
          <Icon
            className={cx(styles.icon, { [styles.activate]: active === 1 })}
            icon="check-circle"
            onClick={handleActivate}
            group={IconGroupPrefix.LAICON}
          />
        ) : status === EStatusType.PENDING_REVIEW ? (
          <Icon className={styles.icon} icon="edit" group={IconGroupPrefix.LAICON} />
        ) : (
          <Icon className={styles.icon} icon="ban" group={IconGroupPrefix.LAICON} />
        )}
      </div>
    </td>
  )
}

export default ActionCell
