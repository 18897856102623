import { FieldError } from 'react-hook-form'
import styles from './errorMessage.module.scss'

interface IErrorMessage {
  error: string | (FieldError | undefined)[] | undefined
}

const ErrorMessage = ({ error }: IErrorMessage) => {
  return error ? (
    <div>
      <span className={styles.message}>{error}</span>
    </div>
  ) : null
}

export default ErrorMessage
