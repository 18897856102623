import { Container } from 'reactstrap'
import DayInWeekRow from './components/DayInWeekRow'
import daysInWeek from './constant'
import styles from './operatingHour.module.scss'

const OperatingHours = () => {
  return (
    <Container className={styles.container}>
      {daysInWeek.map((day, index: number) => {
        return <DayInWeekRow key={`$day.content}-${index}`} day={day} />
      })}
    </Container>
  )
}

export default OperatingHours
