import { KeyboardEvent, FormEvent } from 'react'
import cx from 'classnames'
import { OptionTypeBase } from 'react-select'
import { Event } from 'types/hookForm'
import styles from './input.module.scss'

interface IInputProps {
  className?: string
  name?: string
  value?: string | number | string[]
  defaultValue?: string | number | string[]
  type?: string
  placeholder?: string
  checked?: boolean
  disabled?: boolean
  defaultChecked?: boolean
  prepend?: React.ReactElement | string
  postpend?: React.ReactElement | string
  onChange?: (event: OptionTypeBase) => void
  innerRef?: React.LegacyRef<HTMLInputElement>
  id?: string
  pattern?: string
  onInput?: (event: FormEvent | Event) => void
  autoComplete?: string
  hasNoBorder?: boolean
  onKeyDown?: (event: KeyboardEvent) => void
}

const Input = (props: IInputProps) => {
  const {
    className,
    name,
    value,
    defaultValue,
    type = 'text' || 'tel',
    placeholder,
    innerRef,
    checked,
    disabled,
    onChange,
    defaultChecked,
    prepend,
    pattern,
    postpend,
    onInput,
    autoComplete,
    onKeyDown,
  } = props
  return (
    <>
      <span className={styles.prepend}>{prepend}</span>
      <input
        disabled={disabled}
        className={cx(styles.input, className, {
          [styles.hasPrepend]: prepend,
        })}
        value={value}
        defaultValue={defaultValue}
        name={name}
        type={type}
        checked={checked}
        pattern={pattern}
        placeholder={placeholder}
        ref={innerRef}
        onChange={onChange}
        defaultChecked={defaultChecked}
        onInput={onInput}
        autoComplete={autoComplete}
        onKeyDown={onKeyDown}
      />
      <span className={styles.postpend}>{postpend}</span>
    </>
  )
}

export default Input
