import { useEffect } from 'react'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import SpecialTabs from './components/SpecialTabs'
import TodaySpecial from './components/TodaySpecial'
import styles from './specialPage.module.scss'

const SpecialPage = () => {
  const { specialStore, authStore } = useStore()
  const { establishment } = authStore

  useEffect(() => {
    if (establishment) {
      specialStore.getTablesData()
    }
  }, [establishment])

  return (
    <div className={styles.container}>
      {/* // *INFO: May change design <div className={styles.sectionTitle}>Special</div> */}
      <TodaySpecial />
      <SpecialTabs />
    </div>
  )
}

export default observer(SpecialPage)
