import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'
import { Col, Row } from 'reactstrap'
import ErrorMessage from 'components/ErrorMessage'
import Title from 'components/Title'
import ExclusiveDateTimePicker from '../ExclusiveDateTimePicker'

interface ITimeSelectSectionProps {
  defaultStartTime: Date | undefined
}

const TimeSelectSection = ({ defaultStartTime }: ITimeSelectSectionProps) => {
  const { specialStore } = useStore()
  const { control, setError, clearErrors } = useFormContext()
  const { isWeekForExclusiveAvailable } = specialStore
  const conflictError = `Can't create more than 1 exclusive special that week. Please select another start date.`
  return (
    <>
      <Row>
        <Col md={6}>
          <Title isRequired small>
            Start date
          </Title>
          <ExclusiveDateTimePicker
            rules={{ required: true }}
            name="startDate"
            defaultValue={defaultStartTime}
            control={control}
            setError={setError}
            clearErrors={clearErrors}
            dateFormat="MM/dd/yyyy"
            onChange={(date) => get(date, '[0]', date)}
          />
        </Col>
        <Col md={6}>
          <Title isRequired small>
            Start time
          </Title>
          <ExclusiveDateTimePicker
            name="startTime"
            onChange={(e) => e}
            showTimeSelect
            control={control}
            setError={setError}
            clearErrors={clearErrors}
            defaultValue={defaultStartTime}
            isTime
            showTimeSelectOnly
            timeIntervals={60}
            dateFormat="h:mm aa"
            timeCaption="Time"
          />
        </Col>
      </Row>
      <ErrorMessage error={isWeekForExclusiveAvailable ? '' : conflictError} />
    </>
  )
}

export default observer(TimeSelectSection)
