import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import ButtonWithIcon from 'components/ButtonWithIcon'
import { SpecialType } from 'components/Pages/SpecialPage/constants'
import { defaultFormData } from 'stores/specialStore'
import { WeekDayEnum } from '../../../CreateWeeklySpecialModal/constants'
import styles from './weeklyAction.module.scss'

interface IWeeklyActionProps {
  disable: boolean
  day: string
}

const WeeklyAction = ({ disable, day }: IWeeklyActionProps) => {
  const { specialStore } = useStore()
  const weekDay = day.toLowerCase() as WeekDayEnum

  function handleAddButton(): void {
    specialStore.unsetEditingSpecials()
    specialStore.setOpenCreateModal(true, SpecialType.WEEKLY)
    specialStore.setFormData({ ...defaultFormData, days: [weekDay] })
  }

  return (
    <ButtonWithIcon
      disable={disable}
      iconName="plus"
      className={cx(styles.addButton, { [styles.disabledButton]: disable })}
      onClick={handleAddButton}
    />
  )
}

export default observer(WeeklyAction)
