import cx from 'classnames'
import { useFormContext } from 'react-hook-form'
import { Row, Col } from 'reactstrap'
import InputCheckbox from 'components/InputCheckbox'
import styles from './provisionRow.module.scss'

interface IProvisionRowProps {
  provision: {
    name: string
    content: string
    text: string
  }
}

const ProvisionRow = (props: IProvisionRowProps) => {
  const { provision } = props
  const { register } = useFormContext()

  return (
    <Row className={cx(styles.container, styles.rowMarginBottom)} xs="12">
      <Col className={styles.col} md="12">
        <div>
          {`Does your establishment ${provision.text}`}
          <b>{provision.content}</b>?
        </div>
        <InputCheckbox label="" name={provision.name} innerRef={register} />
      </Col>
    </Row>
  )
}

export default ProvisionRow
