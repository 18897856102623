import cx from 'classnames'
import Button from 'components/Button'
import ModalDialog from 'components/ModalDialog'
import styles from './confirmModal.module.scss'

interface IConfirmContinueModalProps {
  isOpen: boolean | undefined
  className?: string
  setOpen: (value: boolean) => void
  onAcceptLostButton: () => void
}

const ConfirmContinueModal = (props: IConfirmContinueModalProps) => {
  const { isOpen, className, setOpen, onAcceptLostButton } = props

  function setClose() {
    setOpen(false)
  }

  return (
    <ModalDialog
      title={'Warning Submit'}
      size="sm"
      toggle={setClose}
      isOpen={isOpen}
      className={cx(className, styles.modalBodyStyle)}
      footer={
        <>
          <Button color="secondary" type="button" onClick={setClose}>
            Back To Edit
          </Button>
          <Button color="primary" type="button" onClick={onAcceptLostButton}>
            Continue
          </Button>
        </>
      }
    >
      You haven't saved yet, so your changes will be lost. Are you sure you want to continue?
    </ModalDialog>
  )
}

export default ConfirmContinueModal
