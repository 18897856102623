export function getSelectedHour(date: Date = new Date()) {
  const hour = new Date(date).getHours()
  if (hour > 12) {
    return hour - 12
  }
  return hour
}

export function getSelectedMinutes(date: Date) {
  if (date) {
    return new Date(date).getMinutes()
  }
  return 0
}

export function convertToOccurDays(days: string[]): boolean[] {
  const occurDays = [false, false, false, false, false, false, false]
  const daysMapping: Record<string, number> = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  }
  days.map((day) => {
    const index = daysMapping[day]
    occurDays[index] = true
    return true
  })

  return occurDays
}
