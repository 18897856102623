import { lazy, ReactNode, Suspense, useEffect } from 'react'
import classNames from 'classnames'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router'
import { Container } from 'reactstrap'
import Spinner from 'components/Spinner'
import { TABLET_WIDTH } from 'constants/devices'
import routes from 'routes'
import { SidebarType } from '../../constants/enums'
import styles from './mainLayout.module.scss'

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const LeftSidebar = lazy(() => import('../LeftSidebar'))
const TopNavBar = lazy(() => import('../TopNavBar'))

interface MainLayoutProps {
  children?: ReactNode
  withOutAuth?: boolean
}

// loading
const emptyLoading = () => <div></div>
const loading = () => <div className="text-center"></div>

const MainLayout = (props: MainLayoutProps) => {
  const { children, withOutAuth } = props
  const { authStore, businessInfoStore, commonStore } = useStore()
  const { isSideBarCondensed } = commonStore
  const { token, establishment } = authStore
  const history = useHistory()

  useEffect(() => {
    if (window && window.innerWidth <= TABLET_WIDTH) {
      commonStore.toggleCollapse(true)
      changeSidebarTypeUI(SidebarType.CONDENSED)
    } else {
      changeSidebarTypeUI(isSideBarCondensed ? SidebarType.CONDENSED : SidebarType.FIXED)
    }
  }, [])

  function changeSidebarTypeUI(newType: SidebarType | ''): void {
    document.body.setAttribute('data-leftbar-compact-mode', newType)
  }

  function toggleSidebarCollapse() {
    changeSidebarTypeUI(isSideBarCondensed ? SidebarType.FIXED : SidebarType.CONDENSED)
    commonStore.toggleCollapse()
  }

  useEffect(() => {
    if (establishment) {
      businessInfoStore.getGeneralInfo()
    }
    authStore.getMyUser()
    authStore.getMyEstablishments()

    if (document.body) document.body.setAttribute('data-leftbar-theme', 'dark')
  }, [establishment])

  useEffect(() => {
    authStore.getMyUser()
    if (!authStore.token && !withOutAuth) {
      history.push(routes.login.value, { previousUrl: window.location.pathname })
    }
  }, [token])

  return (
    <div className="app">
      <div className={classNames(styles.wrapper, 'wrapper')}>
        <Suspense fallback={emptyLoading()}>
          <LeftSidebar {...props} isCondensed={isSideBarCondensed} hideUserProfile={true} />
        </Suspense>

        <div className={cx('content-page', styles.content)}>
          <div className="content">
            <Spinner />
            <Suspense fallback={emptyLoading()}>
              <TopNavBar hideLogo={true} toggleSidebarCollapse={toggleSidebarCollapse} />
            </Suspense>

            <Container fluid>
              <Suspense fallback={loading()}>{children}</Suspense>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(MainLayout)
