import { api, errorHandler } from 'Api'
import { Count, IServerError } from 'Api/constants'
import { IEvent } from 'constants/interfaces'

export async function getEventTableData() {
  try {
    const response = await api.get(`/event/table`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function getEventList(filter: Record<string, unknown> = {}): Promise<IEvent[]> {
  try {
    const response = await api.get(`/events?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return []
  }
}

export async function patchEvents(eventData: IEvent, filter: Record<string, unknown> = {}): Promise<Count> {
  try {
    const response = await api.patch(`/events?where=${JSON.stringify(filter?.where ?? {})}`, eventData)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return { count: 0 }
  }
}

export async function updateEvent(eventData: IEvent): Promise<void> {
  try {
    await api.patch(`/events/${eventData.event_id}`, eventData)
    return undefined
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    throw new Error(err?.message ?? err)
  }
}

export async function getEventDetail(event_id: number, filter: Record<string, unknown> = {}): Promise<IEvent> {
  try {
    const response = await api.get(`/events/${event_id}?filter=${JSON.stringify(filter)}`)
    return response.data
  } catch (err) {
    // const error: IServerError = err?.response?.data?.error ?? {}
    // errorHandler(error)
    return {}
  }
}

export async function createNewEvent(event: IEvent): Promise<IEvent> {
  try {
    const response = await api.post(`/events`, event)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    throw new Error(err?.message ?? err)
  }
}

export async function deleteEvent(event_id: number): Promise<IEvent> {
  try {
    const response = await api.delete(`/events/${event_id}`)
    return response.data
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return {}
  }
}
