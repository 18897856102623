import { makeObservable, observable, action } from 'mobx'
import { getCampaigns, updateCampaigns } from 'Api/campaign'
import { EStatusType } from 'components/Pages/CampaignPage/constant'
import { ICampaign } from 'constants/interfaces'
import { RootStore } from '.'

export interface IError {
  interaction: string
  impression: string
}
class CampaignStore {
  rootStore: RootStore

  allCampaigns: ICampaign[] = []
  totalCampaigns: number = 0
  pageIndex: number = 1
  error: IError | undefined = undefined
  isLoading: boolean = true

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      allCampaigns: observable,
      totalCampaigns: observable,
      pageIndex: observable,
      isLoading: observable,
      error: observable,
      getAllCampaigns: action,
      setPageIndex: action,
    })
    this.rootStore = rootStore
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value
  }

  setPageIndex = (pageIndex: number) => {
    this.pageIndex = pageIndex
  }

  getAllCampaigns = async (limit: number = 10, offset: number, keyword: string) => {
    const data = await getCampaigns({ limit, offset }, keyword)
    if (typeof data !== 'boolean') {
      this.totalCampaigns = data.count ?? 0
      this.error = data.error
      this.allCampaigns = data.data.map((value: any) => {
        const { interactions, impressions, approved_at, denied_at } = value
        delete value.approved_at
        delete value.denied_at
        delete value.interactions
        delete value.impressions
        const campaign: ICampaign = {
          ...value,
          status: approved_at
            ? EStatusType.PENDING_ACTIVATION
            : denied_at
            ? EStatusType.PENDING_DENIED
            : EStatusType.PENDING_REVIEW,
          dateTimeGroup: {
            interactions,
            impressions,
          },
        }
        return campaign
      })
    }
  }

  async updateCampaign(id: number) {
    const result = await updateCampaigns(id)
    if (typeof result !== 'boolean' && result) {
      return true
    }
    return false
  }
}

export default CampaignStore
