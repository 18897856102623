import { useEffect } from 'react'
import { useStore } from 'hooks/useStore'
import { set } from 'lodash'
import { observer } from 'mobx-react'
import { FormProvider, get, useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { Col, Input, Row } from 'reactstrap'
import Button from 'components/Button'
import DateTimePicker from 'components/DateTimePicker'
import ModalDialog from 'components/ModalDialog'
import Title from 'components/Title'
import { IEvent } from 'constants/interfaces'
import { defaultFormData, IEventForm } from 'stores/eventStore'
import { getDateWithHour } from 'utils/common'
import SpecialList from '../../../SpecialList'
import styles from './createEventModal.module.scss'

interface ICreateEventModal {
  isOpening: boolean
  toggleShow: () => void
  handleReview?: () => void
  formId?: string
}

const CreateEventModal = (props: ICreateEventModal) => {
  const { eventStore } = useStore()
  const { formData, editingEventId } = eventStore
  const { isOpening = false, toggleShow, handleReview, formId = 'one-time-event-form' } = props
  const methods = useForm<IEventForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultFormData,
  })
  const { register, handleSubmit, control, reset } = methods
  const startDate: Date = useWatch({ control, name: 'startDate' }) || new Date()

  useEffect(() => {
    if (editingEventId) {
      reset(formData)
    } else {
      reset(defaultFormData)
    }
  }, [editingEventId, formData])

  async function onSubmit(data: IEvent) {
    if (data?.startDate && data?.endDate && data?.start_time && data?.end_time) {
      set(data, 'start_time', getDateWithHour(data.startDate, data.start_time))
      set(data, 'end_time', getDateWithHour(data.endDate, data.end_time))
      delete data?.startDate
      delete data?.endDate
      try {
        if (data.start_time >= data.end_time) {
          throw new Error('Start date must less than end date')
        }
        if (editingEventId) {
          set(data, 'event_id', editingEventId)
          await eventStore.editEvent(data)
          toggleShow()
        } else {
          const newEvent: IEvent = await eventStore.createNewEvent(data)
          eventStore.setReviewData({ ...data, event_id: newEvent?.event_id ?? 0 })
          !!handleReview ? handleReview() : toggleShow()
        }
        await eventStore.rootStore.establishmentStore.fetchEstablishmentEvent()
        toast.success(`${editingEventId ? 'Edit' : 'Create'} one time event successfully`)
        resetForm()
      } catch (error) {
        let errorMsg = error?.message ?? `${editingEventId ? 'Edit' : 'Create'} one time event failed`
        if (errorMsg.includes(409)) {
          errorMsg = 'There is no one time event left that week, please choose another start date !'
        } else {
          errorMsg = `${editingEventId ? 'Edit' : 'Create'} weekly event failed`
        }
        toast.error(errorMsg)
      }
    }
  }

  function resetForm() {
    eventStore.unsetEditingEvents()
    reset(defaultFormData)
  }

  return (
    <FormProvider {...methods}>
      <form id={formId} onSubmit={handleSubmit(onSubmit)}>
        <ModalDialog
          title={`${editingEventId ? 'Edit' : 'Create New'} One Time Event`}
          toggle={toggleShow}
          isOpen={isOpening}
          footer={
            <>
              <Button className={styles.infoButton} onClick={resetForm}>
                Clear fields
              </Button>
              <Button color="secondary" onClick={toggleShow}>
                Cancel
              </Button>
              <Button color="primary" type="submit" form={formId}>
                Save & Review
              </Button>
            </>
          }
        >
          <Row>
            <Col md={6}>
              <Title isRequired small>
                Start date
              </Title>
              <DateTimePicker
                name="startDate"
                control={control}
                dateFormat="MM/dd/yyyy"
                onChange={(date) => get(date, '[0]', date)}
              />
            </Col>
            <Col md={6}>
              <Title isRequired small>
                Start time
              </Title>
              <DateTimePicker
                name="start_time"
                onChange={(e) => e}
                showTimeSelect
                isTime
                control={control}
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="h:mm aa"
                timeCaption="Time"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Title isRequired small hasMarginTop>
                End date
              </Title>
              <DateTimePicker
                name="endDate"
                control={control}
                dateFormat="MM/dd/yyyy"
                minDate={startDate}
                onChange={(date) => get(date, '[0]', date)}
              />
            </Col>
            <Col md={6}>
              <Title isRequired small hasMarginTop>
                End time
              </Title>
              <DateTimePicker
                name="end_time"
                onChange={(e) => e}
                showTimeSelect
                isTime
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="h:mm aa"
                timeCaption="Time"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Title isRequired small hasMarginTop>
                Title
              </Title>
              <Input name="title" placeholder="Title of Event" maxLength={50} innerRef={register({ required: true })} />
              <p className={styles.hintText}>Limit 50 characters</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Title isRequired small hasMarginTop>
                Details
              </Title>
              <Input
                name="details"
                placeholder="Details of Event"
                maxLength={500}
                innerRef={register({ required: true })}
              />
              <p className={styles.hintText}>Limit 500 characters</p>
            </Col>
          </Row>
          {!!editingEventId && (
            <SpecialList eventId={editingEventId} specials={get(formData, 'specials', [])} key="one-time-specials" />
          )}
        </ModalDialog>
      </form>
    </FormProvider>
  )
}

export default observer(CreateEventModal)
