import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form, FormGroup, Label } from 'reactstrap'
import { secretPasswordMatches } from 'Api/auth'
import { updateSupplier } from 'Api/supplier'
import { checkUserCondition, updateUser } from 'Api/user'
import ButtonWithIcon from 'components/ButtonWithIcon'
import Input from 'components/InputGroup/Input'
import { RoleType } from 'constants/enums/role'
import routes from 'routes'
import RoleOption from './components/RoleOption'
import styles from './firstLoginPage.module.scss'

interface FirstLoginPageProps {
  title?: string
}

interface ILoginForm {
  username: string
  password: string
}

const FirstLoginPage = (props: FirstLoginPageProps) => {
  const { title } = props
  const methods = useForm<ILoginForm>()
  const { handleSubmit, clearErrors } = methods
  const history = useHistory()
  const { authStore } = useStore()
  const { token } = authStore
  const [role, setRole] = useState<RoleType>(RoleType.ESTABLISHMENT)
  const [buttonType, setButtonType] = useState<'button' | 'submit'>('button')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [secretPassword, setSecretPassword] = useState('')
  const [email, setEmail] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [inputPasswordType, setInputPasswordType] = useState('password')
  const [inputSecretPasswordType, setInputSecretPasswordType] = useState('password')
  const [inputConfirmPasswordType, setInputConfirmPasswordType] = useState('password')
  const [isPasswordSecretInvalid, setIsPasswordSecretInvalid] = useState(false)
  const [secretPasswordMessage, setSecretPasswordMessage] = useState('')
  const [isConfirmPasswordInvalid, setIsConfirmPasswordInvalid] = useState(false)
  const [isEmailExisted, setIsEmailExisted] = useState(false)
  const [emailMessage, setEmailMessage] = useState('')
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState('')
  const [isSecretPasswordVerified, setIsSecretPasswordVerified] = useState(false)
  const location = useLocation()
  const currentUserName = get(location, 'state.username', '')

  async function handleSaveAndLogin() {
    const existedUser = await checkUserCondition({ where: { username } })
    const existedEmail = existedUser?.email
    const emails = await checkUserCondition({ where: { email } })
    const checkEmail = get(emails, '[0].email')
    if (checkEmail !== existedEmail) {
      setIsEmailExisted(true)
      setEmailMessage('Email already existed')
      return
    }
    setIsEmailExisted(false)

    if (username && password && email && secretPassword && password === confirmPassword) {
      clearErrors()
      setIsConfirmPasswordInvalid(false)
      setConfirmPasswordMessage('')
      const result: boolean =
        role === RoleType.ESTABLISHMENT
          ? await updateUser(username, secretPassword, email, password)
          : await updateSupplier(username, secretPassword, email, password)
      if (result) {
        history.push({ pathname: routes.login.value, state: { username } })
      }
      if (!result) {
        setIsConfirmPasswordInvalid(true)
        setConfirmPasswordMessage('Something may wrong, please try again !')
        toast.error('Something wrong happened')
      }
    } else {
      setIsConfirmPasswordInvalid(true)
      setConfirmPasswordMessage('')
      toast.success('Password changed successfully')
    }
  }

  useEffect(() => {
    if (currentUserName) {
      setUsername(currentUserName)
    } else {
      history.push(routes.login.value)
    }
  }, [currentUserName])

  async function handleContinueButtonClick() {
    if (username) {
      try {
        const result = await checkSecretPasswordMatch(secretPassword)
        if (result) {
          setIsSecretPasswordVerified(true)
          setIsPasswordSecretInvalid(false)
          setSecretPasswordMessage('')
          setButtonType('submit')
        } else {
          setPassword('')
          setIsSecretPasswordVerified(false)
          setIsPasswordSecretInvalid(true)
          setSecretPasswordMessage("We couldn't find that username. Please try again")
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  function toggleShowPassword() {
    setInputPasswordType(inputPasswordType === 'password' ? 'text' : 'password')
  }

  function toggleShowSecretPassword() {
    setInputSecretPasswordType(inputSecretPasswordType === 'password' ? 'text' : 'password')
  }

  function toggleShowConfirmPassword() {
    setInputConfirmPasswordType(inputConfirmPasswordType === 'password' ? 'text' : 'password')
  }

  async function checkSecretPasswordMatch(secretPassword: string) {
    const result: boolean = await secretPasswordMatches(secretPassword)
    return !!result
  }

  useEffect(() => {
    authStore.getMyUser()
  }, [])

  useEffect(() => {
    if (token) {
      history.push(routes.special.value)
    }
  }, [token])

  return (
    <Form {...methods} onSubmit={handleSubmit(handleSaveAndLogin)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.selectRoleArea}>
        <FormGroup>
          <Label className={styles.label}>Select Your Role</Label>
          <RoleOption role={role} setRole={setRole} />
        </FormGroup>
      </div>
      <div className={styles.userInfoArea}>
        <FormGroup>
          <Label className={styles.label}>Username</Label>
          <Input type="text" value={username} readOnly />
        </FormGroup>
        <FormGroup>
          <Label className={styles.label}>Secret Password</Label>
          <Input
            addonType="append"
            addonClassname={styles.inputAppendIcon}
            iconName="eye"
            className={cx(styles.passwordInput)}
            type={inputSecretPasswordType}
            setValue={setSecretPassword}
            onIconClick={toggleShowSecretPassword}
            invalid={isPasswordSecretInvalid}
            message={secretPasswordMessage}
          />
        </FormGroup>
        {!isSecretPasswordVerified ? (
          <div className={styles.paragraph}>
            If you haven't received a Secret Password, please contact the
            {` `} <b>AppyHour team</b> {` `}
            {` (`}
            <span className={styles.email}>appy-support@appyhourmobile.com</span>
            {`) `}
          </div>
        ) : (
          <>
            <FormGroup>
              <Label className={styles.label}>Email</Label>
              <Input type="email" setValue={setEmail} invalid={isEmailExisted} message={emailMessage} />
            </FormGroup>
            <FormGroup>
              <Label className={styles.label}>Password</Label>
              <Input
                addonType="append"
                addonClassname={styles.inputAppendIcon}
                iconName="eye"
                className={cx(styles.passwordInput)}
                type={inputPasswordType}
                setValue={setPassword}
                onIconClick={toggleShowPassword}
              />
            </FormGroup>
            <FormGroup>
              <Label className={styles.label}>Confirm Password</Label>
              <Input
                addonType="append"
                addonClassname={styles.inputAppendIcon}
                iconName="eye"
                className={cx(styles.passwordInput)}
                type={inputConfirmPasswordType}
                setValue={setConfirmPassword}
                onIconClick={toggleShowConfirmPassword}
                invalid={isConfirmPasswordInvalid}
                message={confirmPasswordMessage}
              />
            </FormGroup>
          </>
        )}
      </div>
      <ButtonWithIcon
        content={isSecretPasswordVerified ? 'Save & Login' : 'Continue'}
        type={buttonType}
        className={styles.continueButton}
        onClick={handleContinueButtonClick}
      />
    </Form>
  )
}

export default observer(FirstLoginPage)
