import cx from 'classnames'
import { EStatusType } from '../../../../constant'
import styles from './styles.module.scss'

interface IStatusCellProps {
  value: any
}

const StatusCell = (props: IStatusCellProps) => {
  const { value } = props
  return (
    <td>
      <div
        className={cx(styles.status, {
          [styles.statusActivation]: value === EStatusType.PENDING_ACTIVATION,
          [styles.statusReview]: value === EStatusType.PENDING_REVIEW,
        })}
      >
        {value}
      </div>
    </td>
  )
}

export default StatusCell
