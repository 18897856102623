import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { observer } from 'mobx-react'
import { useFormContext, useWatch } from 'react-hook-form'
import Button from 'components/Button'
import { DealTypeEnum } from 'constants/enums/special'
import styles from './footer.module.scss'

interface IFooterProps {
  toggleShow: () => void
  handleReview: () => void
}

const Footer = ({ toggleShow, handleReview }: IFooterProps) => {
  const { specialStore } = useStore()
  const { trigger, errors, control } = useFormContext()
  const { isWeekForExclusiveAvailable } = specialStore
  const description = useWatch({ control, name: 'description' })
  const priceType = useWatch({ control, name: 'priceType' })
  const types = useWatch({ control, name: 'type' }) || []
  const dealType: DealTypeEnum = useWatch({ control, name: 'dealType' }) as DealTypeEnum
  const disableSubmit: boolean =
    !dealType || !description || !priceType || !get(types, 'length', 0) || !isWeekForExclusiveAvailable

  function handleClearForm() {
    specialStore.unsetFormData()
  }
  function handleCancel() {
    specialStore.unsetFormData()
    toggleShow()
  }
  function handleSubmit() {
    if (isEmpty(errors)) {
      handleReview()
    }
    trigger()
  }
  return (
    <>
      <Button className={styles.infoButton} onClick={handleClearForm}>
        Clear fields
      </Button>
      <Button color="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button color="primary" type="submit" disabled={disableSubmit} form="create-special-form" onClick={handleSubmit}>
        Save & Review
      </Button>
    </>
  )
}

export default observer(Footer)
