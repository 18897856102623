import { ICampaign } from 'constants/interfaces'
import CampaignCard from './components/CampaignCard'
import styles from './styles.module.scss'

interface IGridViewProps {
  data: ICampaign[]
}

const GridView = (props: IGridViewProps) => {
  return (
    <>
      <div className={styles.gridViewWrapper}>
        {props.data.map((campaign: ICampaign, index: number) => {
          return <CampaignCard key={`campaign-${index}`} item={campaign} />
        })}
      </div>
    </>
  )
}

export default GridView
