import { EStatusType } from 'components/Pages/CampaignPage/constant'
import DateContent from './components/DateContent'
import styles from './styles.module.scss'

interface IDateCellProps {
  value: any
  status: EStatusType
}

const DateCell = (props: IDateCellProps) => {
  const { value, status } = props
  return (
    <td>
      <div className={styles.cellWrapper}>
        <div className={styles.line}>
          <DateContent
            value={value.impressions.lastInterval.value}
            percentChange={value.impressions.lastInterval.percentChange}
            status={status}
          />
          <DateContent
            value={value.impressions.thisInterval.value}
            percentChange={value.impressions.thisInterval.percentChange}
            status={status}
          />
          <div>
            <span className={styles.value}>
              {status === EStatusType.PENDING_ACTIVATION ? value.impressions.allTime : '-'}
            </span>
          </div>
        </div>
        <hr />
        <div className={styles.line}>
          <DateContent
            value={value.interactions.lastInterval.value}
            percentChange={value.interactions.lastInterval.percentChange}
            status={status}
          />
          <DateContent
            value={value.interactions.thisInterval.value}
            percentChange={value.interactions.thisInterval.percentChange}
            status={status}
          />
          <div>
            <span className={styles.value}>
              {status === EStatusType.PENDING_ACTIVATION ? value.interactions.allTime : '-'}
            </span>
          </div>
        </div>
      </div>
    </td>
  )
}

export default DateCell
