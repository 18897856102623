import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import map from 'lodash/map'
import set from 'lodash/set'
import { flow, makeAutoObservable } from 'mobx'
import { IFilter } from 'types/common'
import {
  createNewEstablishment,
  getEstablishmentDetail,
  getEstablishmentList,
  updateEstablishment,
} from 'Api/establishment'
import { getUserEstablishments } from 'Api/user'
import { Timezone } from 'constants/enums/timezone'
import { IEstablishment, IEvent } from 'constants/interfaces'
import { getUniqueSpecialFormEvent, getValidArray } from 'utils/common'
import { RootStore } from '.'
dayjs.extend(isoWeek)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const filterEventFromEstablishment: IFilter<IEstablishment> = {
  fields: ['establishment_id', 'name', 'show_in_app', 'establishmentEvents'],
  include: [
    {
      relation: 'establishmentEvents',
      scope: {
        fields: ['event_id', 'establishment_id', 'event'],
        include: [
          {
            relation: 'event',
            scope: {
              where: {
                or: [
                  {
                    and: [{ start_time: { gte: dayjs().subtract(28, 'day').toDate() } }, { day: { eq: -1 } }],
                  },
                  { day: { neq: -1 } },
                ],
              },
              include: [
                {
                  relation: 'eventSpecials',
                  scope: {
                    include: [{ relation: 'special' }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

class EstablishmentStore {
  rootStore: RootStore

  establishments: IEstablishment[] = []
  establishmentDetail: IEstablishment = {}
  establishmentList: IEstablishment[] = []
  currentTimezone: Timezone = Timezone.CST

  constructor(rootStore: RootStore) {
    //TODO: may need in some special case
    // makeObservable(this, {
    //   establishments: observable,
    //   establishmentDetail: observable,
    //   establishmentList: observable,
    //   getEstablishments: action,
    //   createNewEstablishment: action,
    //   fetchEstablishmentList: action,
    //   fetchEstablishmentEvent: action,
    //   editEstablishment: action,
    // })

    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  getEstablishments = flow(function* (this: EstablishmentStore) {
    this.establishments = yield getUserEstablishments()
  })

  createNewEstablishment = flow(function* (this: EstablishmentStore, data: unknown) {
    const newEstablishment = yield createNewEstablishment(data)
    return newEstablishment
  })

  fetchEstablishmentList = flow(function* (this: EstablishmentStore, filter: IFilter<IEstablishment> = {}) {
    this.establishmentList = yield getEstablishmentList(filter)
  })

  fetchEstablishmentEvent = flow(function* (this: EstablishmentStore) {
    this.rootStore.eventStore.resetStore()
    this.establishmentDetail = {}
    this.establishmentList = []
    this.establishments = []
    const establishment_id: number = this.rootStore.authStore.establishment
    this.rootStore.spinnerStore.showLoading()
    const establishmentDetail = yield getEstablishmentDetail(establishment_id, filterEventFromEstablishment)
    this.establishmentDetail = establishmentDetail
    if (establishmentDetail?.establishmentEvents) {
      const eventList: IEvent[] = map(getValidArray(establishmentDetail.establishmentEvents), 'event')
        .filter((e) => e)
        .map((event: IEvent) => {
          return {
            ...event,
            specials: getUniqueSpecialFormEvent(event),
            eventSpecials: undefined,
          }
        })
      getValidArray(eventList).forEach((event) => {
        const { day = -1, start_time, end_time } = event
        if (day === -1) {
          this.rootStore.eventStore.oneTimeEvents = [...this.rootStore.eventStore.oneTimeEvents, event]
        } else {
          this.rootStore.eventStore.weeklyEvents = [...this.rootStore.eventStore.weeklyEvents, event]
        }
        const startTime = dayjs(start_time)
        const endTime = dayjs(end_time)
        const today: Date = new Date()
        if (
          startTime.isSame(today, 'day') ||
          endTime.isSame(today, 'day') ||
          (startTime.isSameOrBefore(today, 'day') && endTime.isSameOrAfter(today, 'day')) ||
          (day > -1 &&
            day
              .toString()
              .split('')
              .some((d) => dayjs().isoWeekday(parseInt(d)).isoWeekday() === dayjs().isoWeekday()))
        ) {
          if (startTime.hour() <= dayjs().hour() && endTime.hour() >= dayjs().hour()) {
            set(event, 'isCurrent', true)
          }
          console.log(event)
          this.rootStore.eventStore.todayEvents = [...this.rootStore.eventStore.todayEvents, event]
        }
      })
      const weeklyEventCount: string = map(this.rootStore.eventStore.weeklyEvents, (e) => String(e.day)).join('')
      this.rootStore.eventStore.weeklyEventCount = weeklyEventCount.length
      this.rootStore.eventStore.eventList = eventList
      this.rootStore.eventStore.unsetEditingEvents()
      this.rootStore.spinnerStore.hideLoading()
    }
  })

  editEstablishment = flow(function* (this: EstablishmentStore, data: { establishment_id: number }) {
    yield updateEstablishment(data)
  })
}
export default EstablishmentStore
