import cx from 'classnames'
import styles from './statusCell.module.scss'

interface IStatusCellProps {
  children: React.ReactNode
  status: string
  className?: string
}

const StatusCell = (props: IStatusCellProps) => {
  const { children, status, className } = props
  return status ? (
    <div
      className={cx(className, {
        [styles.statusCell]: true,
        [styles[status]]: status ? true : false,
      })}
    >
      {children}
    </div>
  ) : (
    <></>
  )
}

export default StatusCell
