import FirstLoginPage from 'components/Pages/FirstLoginPage'
import PublicLayout from 'components/PublicLayout'
import styles from './firstLoginPage.module.scss'

const LoginLayout = () => {
  return (
    <PublicLayout>
      <div className={styles.container}>
        <FirstLoginPage title="Login" />
      </div>
    </PublicLayout>
  )
}

export default LoginLayout
