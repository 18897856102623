import { flow, makeObservable, observable, action } from 'mobx'
import { getGeneral, getTime, getFeatures } from 'Api/business'
import { mapToDateTime } from 'components/Pages/BusinessInfoPage/utils'
import { IBusinessInfo } from 'constants/interfaces'
import { RootStore } from '.'

class BusinessInfoStore {
  rootStore: RootStore

  generalInfo: IBusinessInfo = {}
  workingTime: IBusinessInfo = {}
  establishmentFeatures: IBusinessInfo = {}
  profilePictureUrl = ''

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      generalInfo: observable,
      workingTime: observable,
      establishmentFeatures: observable,
      profilePictureUrl: observable,
      getGeneralInfo: action,
      getOperatingHour: action,
      getEstablishment: action,
      setGeneralInfo: action,
      setOperatingHour: action,
      setEstablishmentFeatures: action,
      pickEstablishmentFeatures: action,
      setProfilePicture: action,
    })

    this.rootStore = rootStore
  }

  getGeneralInfo = flow(function* (this: BusinessInfoStore) {
    const data = yield getGeneral()
    if (typeof data !== 'boolean') {
      this.generalInfo = data
    }
  })

  getOperatingHour = flow(function* (this: BusinessInfoStore) {
    const result = yield getTime()

    if (typeof result !== 'boolean') {
      const data = yield mapToDateTime(result)
      this.workingTime = data
    }
  })

  getEstablishment = flow(function* (this: BusinessInfoStore) {
    const data = yield getFeatures()
    if (typeof data !== 'boolean') {
      this.establishmentFeatures = data
    }
  })

  setGeneralInfo(data: any) {
    this.generalInfo = data
  }

  setOperatingHour(data: any) {
    this.workingTime = data
  }

  setEstablishmentFeatures(data: any) {
    this.establishmentFeatures = data
  }

  pickEstablishmentFeatures() {
    return this.establishmentFeatures
  }

  setProfilePicture(url: string) {
    this.profilePictureUrl = url
  }
}
export default BusinessInfoStore
