import cx from 'classnames'
import ButtonWithIcon from 'components/ButtonWithIcon'
import { RoleType } from 'constants/enums/role'
import styles from './roleOption.module.scss'

interface RoleOptionProps {
  role: string
  setRole: (role: RoleType) => void
}

const RoleOption = (props: RoleOptionProps) => {
  const { role, setRole } = props
  const isBetaProduction = (process?.env?.REACT_APP_API_URL ?? '').includes('beta')

  function handleClickEstablishment() {
    setRole(RoleType.ESTABLISHMENT)
  }

  function handleClickSupplier() {
    if (isBetaProduction && window?.location) {
      window.location.replace('https://app.appyhourmobile.com/estlogin')
    } else {
      setRole(RoleType.SUPPLIER)
    }
  }

  return (
    <div className={styles.selectRoleButtonGroup}>
      <ButtonWithIcon
        iconName="user-cog"
        content="Establishment"
        className={cx(styles.selectRoleButton, {
          [styles.selectRoleButtonChosen]: role === RoleType.ESTABLISHMENT,
        })}
        onClick={handleClickEstablishment}
      />

      <ButtonWithIcon
        iconName="store-alt"
        content="Supplier"
        className={cx(styles.selectRoleButton, {
          [styles.selectRoleButtonChosen]: role === RoleType.SUPPLIER,
        })}
        onClick={handleClickSupplier}
      />
    </div>
  )
}

export default RoleOption
