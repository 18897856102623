import { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Col, Row, Input, InputProps } from 'reactstrap'
import ErrorMessage from 'components/ErrorMessage'
import InputCheckBox from 'components/InputCheckbox'
import ModalDialog from 'components/ModalDialog'
import Title from 'components/Title'
import { PriceType, CategoryType, DealTypeEnum } from 'constants/enums/special'
import { ICreateSpecial } from 'constants/interfaces'
import ChooseWeekDay from './components/ChooseWeekDay'
import DateTimePicker from './components/DateTimePicker'
import Images from './components/DealTypeImages'
import Footer from './components/Footer'
import PriceTypeSelection from './components/PriceTypeSelection'
import { ICreateSpecialModal, WeekDayEnum } from './constants'
import styles from './createSpecialModal.module.scss'

const CreateWeeklySpecialModal = (props: ICreateSpecialModal) => {
  const { isOpening = false, toggleShow, handleReview } = props
  const { specialStore } = useStore()
  const weekDaySelectionRef = useRef<null | HTMLDivElement>(null)
  const { formData, editingSpecials = [] } = specialStore
  const methods = useForm<ICreateSpecial>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: formData,
  })
  const { register, control, errors, clearErrors, setValue, reset } = methods
  const priceType = useWatch({ control, name: 'priceType' })
  const [dateError, setDateError] = useState(undefined)
  const { days = [], dealType } = formData
  const [weekDay, setWeekDay] = useState<WeekDayEnum[]>([...days])
  const isEditing = !!days.length && !!editingSpecials.length
  const hasInput = priceType === `${PriceType.NORMAL}` || priceType === `${PriceType.OFF}`
  const formDealType: DealTypeEnum = useWatch({ control, name: 'dealType' }) as DealTypeEnum

  function scrollToWeekDaySelection() {
    if (weekDaySelectionRef !== null && weekDaySelectionRef.current !== null) {
      weekDaySelectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

  useEffect(() => {
    register('days')
    register('dealType')
    register('previousDays')
  }, [register])

  useEffect(() => {
    reset(formData)
  }, [formData])

  useEffect(() => {
    setValue('dealType', dealType)
    if (days.length) {
      setWeekDay(days)
    }
  }, [days.length, dealType])

  async function onSubmit(data: ICreateSpecial) {
    specialStore.setFormData(data)
  }

  function getPriceProps(): InputProps {
    const isPrice: boolean = priceType === `${PriceType.NORMAL}`
    return {
      className: cx({
        [styles.error]: isPrice ? !!errors['price'] : !!errors['percentage'],
        [styles.disabledInput]: isEditing,
      }),
      name: `${isPrice ? 'price' : 'percentage'}`,
      type: 'number',
      errors,
      placeholder: `0.00 ${isPrice ? '$' : '%'}`,
      required: true,
      innerRef: register(isPrice ? { required: true } : { required: true, max: 100, min: 0.01 }),
    }
  }
  return (
    <FormProvider {...methods}>
      <form id="create-weekly-special-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <ModalDialog
          title={`${isEditing ? 'Edit' : 'Create new'} Weekly Special`}
          toggle={toggleShow}
          isOpen={isOpening}
          footer={
            <Footer
              setWeekDay={setWeekDay}
              isEditing={isEditing}
              toggleShow={toggleShow}
              weekDay={weekDay}
              days={days}
              dealType={formDealType}
              handleReview={handleReview}
              scrollToWeekDaySelection={scrollToWeekDaySelection}
            />
          }
        >
          <Row>
            <Col md={12}>
              <Title isRequired small>
                Day(s)
              </Title>
              <Row>
                <Col md={12}>
                  <ChooseWeekDay
                    refProp={weekDaySelectionRef}
                    weekDay={weekDay}
                    setWeekDay={setWeekDay}
                    selectedDays={days}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Title isRequired small>
                Start time
              </Title>
              <DateTimePicker
                name="startTime"
                onChange={(e) => e}
                showTimeSelect
                control={control}
                setError={setDateError}
                clearErrors={clearErrors}
                isTime
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="h:mm aa"
                timeCaption="Time"
              />
            </Col>
            <Col md={6}>
              <Title isRequired small>
                End time
              </Title>
              <DateTimePicker
                name="endTime"
                onChange={(e) => e}
                control={control}
                setError={setDateError}
                clearErrors={clearErrors}
                showTimeSelect
                isTime
                showTimeSelectOnly
                timeIntervals={60}
                dateFormat="h:mm aa"
                timeCaption="Time"
              />
            </Col>
          </Row>
          <ErrorMessage error={dateError} />

          <Row>
            <Col md={12}>
              <Title isRequired small hasMarginTop>
                Price type
              </Title>
              <PriceTypeSelection isEditing={isEditing} />
              {hasInput && (
                <Row style={{ paddingLeft: 20 }} className={styles.row}>
                  <Col md={3}>
                    <Input {...getPriceProps()} />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Title isRequired small hasMarginTop>
                Details
              </Title>
              <Input
                name="description"
                className={cx({
                  [styles.error]: !!errors['description'],
                  [styles.disabledInput]: isEditing,
                })}
                placeholder="Details of Special"
                innerRef={register({ required: true, maxLength: 40 })}
              />
              <p className={styles.hintText}>Limit 40 characters</p>
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col md={12}>
              <Title isRequired small smallMargin>
                Dine-in and/or Takeout
              </Title>
              <Row>
                <Col md={3}>
                  <InputCheckBox name="type" label="Dine-in" value={`${CategoryType.DINEIN}`} innerRef={register} />
                </Col>
                <Col md={3}>
                  <InputCheckBox
                    name="type"
                    label="Takeout"
                    value={`${CategoryType.TAKEOUT}`}
                    innerRef={register({ required: 'This field is required' })}
                  />
                </Col>
              </Row>
              <ErrorMessage error={get(errors, 'type.message', '')} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Title isRequired small smallMargin>
                Select an icon to display with your special
              </Title>
            </Col>
            <Images name="dealType" />
          </Row>
        </ModalDialog>
      </form>
    </FormProvider>
  )
}

export default observer(CreateWeeklySpecialModal)
