import PublicLayout from 'components/PublicLayout'
import LoginPage from '../../components/Pages/LoginPage'
import styles from './loginPage.module.scss'

const LoginLayout = () => {
  return (
    <PublicLayout>
      <div className={styles.container}>
        <LoginPage title="Login" />
      </div>
    </PublicLayout>
  )
}

export default LoginLayout
