import { useState } from 'react'
import cx from 'classnames'
import { useStore } from 'hooks/useStore'
import get from 'lodash/get'
import has from 'lodash/has'
import { observer } from 'mobx-react'
import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import { Container, Row, Input, Form } from 'reactstrap'
import { createUserManager, getUserByCondition } from 'Api/user'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import InputDropdown from 'components/InputGroup/InputDropdown'
import Label from 'components/Label'
import ModalDialog from 'components/ModalDialog'
import { IAddManager } from 'constants/interfaces'
import SuccessModal from './components/SuccessModal'
import { mapToOptions } from './utils'
import styles from './addManagerModal.module.scss'

interface IAddManagerModalProps {
  isOpen: boolean | undefined
  className?: string
  setOpen: (value: boolean) => void
}

const AddManagerModal = (props: IAddManagerModalProps) => {
  const { myAccountStore } = useStore()
  const { isOpen, className, setOpen } = props
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const methods = useForm<IAddManager>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      establishment: {},
      email: '',
      phone_num: '',
      username: '',
    },
  })

  const { register, reset, formState, control, handleSubmit, errors } = methods
  const { isSubmitting } = formState

  const onSubmit = async (data: IAddManager) => {
    const bodyData = {
      establishmentId: data.establishment.value,
      email: data.email,
      phone: data.phone_num?.replace(/[^0-9]+/g, ''),
      username: data.username,
    }
    const result = await createUserManager(bodyData)
    if (result) {
      myAccountStore.addNewManager(data)
      setIsSuccessModalOpen(true)
      reset({
        establishment: {},
        email: '',
        phone_num: '',
        username: '',
      })
      toast.success('Create manager success')
    } else {
      toast.error('Create manager fail')
    }
  }

  return (
    <ModalDialog
      title={'Add Manager'}
      size="sm"
      toggle={() => setOpen(false)}
      isOpen={isOpen}
      classNameHeader={styles.header}
      className={cx(styles.modal, className)}
      footer={
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Button color="secondary" type="button" className={styles.cancelButton} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button disabled={isSubmitting} color="primary" type="submit">
            Add Manager
          </Button>
        </Form>
      }
    >
      <Container>
        <Row className={styles.rowMarginBottom}>
          <Label strong isRequired>
            Establishment
          </Label>
          <Controller
            name="establishment"
            control={control}
            rules={{
              required: 'Establishment is empty',
            }}
            render={({ onChange, value }) => (
              <InputDropdown
                className={cx({
                  [styles.inputDropdownInvalid]: has(errors, 'establishment'),
                })}
                name="establishment"
                value={value}
                onChange={onChange}
                containerClassName={cx(styles.inputDropdownCustom)}
                options={mapToOptions(myAccountStore.establishment)}
              />
            )}
          />
          {<ErrorMessage error={get(errors, 'establishment.message', '')} />}
        </Row>
        <Row className={styles.rowMarginBottom}>
          <Label strong isRequired>
            Email
          </Label>
          <Input
            className={styles.inputCustom}
            placeholder="E.g: john.doe@yomail.com"
            type="text"
            invalid={has(errors, 'email')}
            name="email"
            innerRef={register({
              required: 'Email is empty',
              pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              validate: {
                isExisted: async (value) => {
                  return !(await getUserByCondition('email', value))
                },
              },
            })}
          />
          <ErrorMessage error={get(errors, 'email.message', '')} />
          {get(errors, 'email.type', '') === 'pattern' && <ErrorMessage error={'Email is invalid'} />}
          {get(errors, 'email.type', '') === 'isExisted' && <ErrorMessage error={'Email is existed'} />}
          <div className={styles.emailNotes}>
            An email with the subject AppyHour Login Instructions will be sent to the manager with details for
            first-time login
          </div>
        </Row>
        <Row className={styles.rowMarginBottom}>
          <Label strong>Phone Number</Label>
          <Controller
            name="phone_num"
            control={control}
            alwaysShowMask={false}
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <InputMask mask="(999) 999-9999" maskChar=" " value={value} onChange={onChange}>
                {(inputProps: any) => (
                  <Input
                    {...inputProps}
                    type="text"
                    name="phone_num"
                    required
                    placeholder="E.g: (000) 000-0000"
                    innerRef={register({ required: true })}
                    invalid={has(errors, 'phone_num')}
                    className={styles.inputCustom}
                  />
                )}
              </InputMask>
            )}
          />
          {get(errors, 'phone_num.type', '') === 'minLength' && (
            <ErrorMessage error={'Phone number must be at least 8 characters'} />
          )}
          {get(errors, 'phone_num.type', '') === 'maxLength' && (
            <ErrorMessage error={'Phone number must be maximum 10 characters'} />
          )}
        </Row>
        <Row className={styles.rowMarginBottom}>
          <Label strong isRequired>
            Username
          </Label>
          <Input
            className={styles.inputCustom}
            placeholder="E.g: john.doe"
            type="text"
            invalid={has(errors, 'username')}
            name="username"
            innerRef={register({
              required: 'Username is empty',
              validate: {
                isExisted: async (value) => {
                  return !(await getUserByCondition('username', value))
                },
              },
            })}
          />
          {<ErrorMessage error={get(errors, 'username.message', '')} />}
          {get(errors, 'username.type', '') === 'isExisted' && <ErrorMessage error={'Username is existed'} />}
        </Row>
        <SuccessModal isOpen={isSuccessModalOpen} setParentOpen={setOpen} setOpen={setIsSuccessModalOpen} />
      </Container>
    </ModalDialog>
  )
}

export default observer(AddManagerModal)
