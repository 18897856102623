import { ISpecial } from 'constants/interfaces'
import { SpecialType } from '../../constants'
import Row from './components/Row'
import styles from './subTable.module.scss'

interface ISubTableProps {
  specials: ISpecial[]
  type: SpecialType
  isCompact?: boolean
}

const SubTable = ({ specials, type, isCompact }: ISubTableProps) => {
  const noSpecialText = type === SpecialType.EXCLUSIVE ? 'No exclusive specials this week' : 'No specials'
  return (
    <div>
      {Array.isArray(specials) &&
        specials.map((special: ISpecial, index: number) => (
          <Row isCompact={isCompact} type={type} key={index} order={index} special={special} />
        ))}
      {!specials?.length && <div className={styles.noSpecial}>{noSpecialText}</div>}
    </div>
  )
}

export default SubTable
