export enum Timezone {
  EST = 'EST',
  MST = 'MST',
  PST = 'PST',
  CST = 'CST',
  EDT = 'EDT',
  PDT = 'PDT',
  MDT = 'MDT',
  CDT = 'CDT',
}

export enum TopicName {
  MINNEAPOLISMN = 'minneapolismn',
  IOWACITYIA = 'iowacityia',
  DESMOINESIA = 'desmoinesia',
  DENVERCO = 'denverco',
  LOUISVILLEKY = 'louisvilleky',
  TAMPABAYFL = 'tampabayfl',
}
