import { HTMLProps } from 'react'
import cx from 'classnames'

interface IComboBoxOption extends HTMLProps<HTMLOptionElement> {
  className?: string
}

const ComboBoxOption = (props: IComboBoxOption) => {
  const { children, className, ...otherProps } = props

  return (
    <option {...otherProps} className={cx(className)}>
      {children}
    </option>
  )
}

export default ComboBoxOption
