import { Dispatch, FormEvent, SetStateAction } from 'react'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import omit from 'lodash/omit'
import set from 'lodash/set'
import uniqBy from 'lodash/uniqBy'
import { PriceType } from 'constants/enums/special'
import { Timezone } from 'constants/enums/timezone'
import { IEvent, ISpecial } from 'constants/interfaces'
import { getDateBasedOnTimeZone, getStartAndEndOfWeekByTimezone } from './timezone'

export function thousandSeparator(number: number, locale: string = 'vi'): string {
  return Number(number).toLocaleString(locale)
}

export function thousandSeparatorByComma(number: number): string {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
}

export function thousandSeparatorByDot(number: number): string {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : ''
}

// INFO: parse string seperator by comma to a number
export function parseCommaStringToNumber(commaString: string): number {
  return Number(commaString ? commaString.replace(/\D/g, '') : '0')
}

export function changeQuantity(type: 'inc' | 'dec', currentValue: number): number {
  let result: number = currentValue
  if (type === 'inc') {
    currentValue >= 999 ? (result = currentValue) : (result = currentValue + 1)
  } else {
    currentValue <= 1 ? (result = currentValue) : (result = currentValue - 1)
  }
  return result
}

export function getPositiveNumberOnly(inputValue: string): string {
  let result = inputValue.replaceAll(/[^0-9]/g, '')
  result = Number(result) > 0 ? Number(result).toString() : ''
  return result
}

export function deleteFieldsInObject(object: { [x: string]: unknown }, fieldList: string[]) {
  fieldList.forEach((field) => {
    object[field] = undefined
  })
}

export function filterValidArrayFromArray(array: (string | string[])[]): (string | string[])[] {
  array = array.filter(isValidArray)
  return array
}

export function isValidArray(item: string | string[]): string[] | boolean {
  item = Array.isArray(item) ? item : ['']
  if (item.length > 0 && item[0] !== '' && item) {
    return item
  }
  return false
}

export function handleNumberSeparatorInput(event: FormEvent<HTMLInputElement>): void {
  const formattedNumberValue = parseCommaStringToNumber(event.currentTarget.value)
  event.currentTarget.value = thousandSeparatorByDot(formattedNumberValue)
}

export function replaceWithAsterisk(inputString: string, indices: number[]): string {
  if (!inputString) return ''
  let outputString: string = ''
  outputString = indices
    .reduce((accumulator, currentValue) => {
      accumulator[currentValue] = '*'
      return accumulator
    }, inputString.split(''))
    .join('')
  return outputString
}

export function checkValidArray(array?: unknown[]): boolean {
  return array ? Array.isArray(array) && array.length > 0 : false
}

export function getValidArray<T>(array?: T[]): T[] {
  return checkValidArray(array) ? array || [] : []
}

export function getCapitalizeArray(array?: string[]): string[] {
  return getValidArray(array).map((e) => capitalize(e))
}
export function setState<T>(state: T, setThisState: Dispatch<SetStateAction<T>>) {
  setThisState(state)
}

export function getDayOfWeek(day: number): string {
  const dayOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  return dayOfWeek[day] ?? ''
}

export function getDayOfWeekSundayFirst(day: number): string {
  const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  return dayOfWeek[day] ?? ''
}

// 0 = this week, 1 = previous week, ...
export function getStartAndEndOfWeek(forward: number, timezone: Timezone): string {
  const { startOfWeek, endOfWeek } = getStartAndEndOfWeekByTimezone(forward, timezone)
  return `${format(startOfWeek, 'MM/dd/yyyy')} - ${format(endOfWeek, 'MM/dd/yyyy')}`
}

export function getDateOfStartOfWeek(forward: number, timezone: Timezone): Date {
  const { startOfWeek } = getStartAndEndOfWeekByTimezone(forward, timezone)
  const today = getDateBasedOnTimeZone(timezone)
  return startOfWeek < today ? today : startOfWeek
}

export function getDateWithHour(date: Date, time: Date): Date {
  return dayjs(date.setHours(time.getHours())).minute(0).toDate()
}

export function getUniqueSpecialFormEvent(event: IEvent): ISpecial[] {
  const specials: ISpecial[] = map(getValidArray(event?.eventSpecials), 'special') as ISpecial[]
  const uniqueSpecials: ISpecial[] = uniqBy(specials, (e) =>
    JSON.stringify(omit(e, ['start_time', 'end_time', 'special_id', 'created_at', 'updated_at']))
  )
  uniqueSpecials.map((special: ISpecial) => {
    set(
      special,
      'days',
      specials
        .filter(
          (e: ISpecial) =>
            e.details === special.details &&
            e.price === special.price &&
            e.price_type_ref_id === special.price_type_ref_id &&
            e.deal_type_ref_id === special.deal_type_ref_id &&
            e.day === -1 &&
            e.dinein === 1
        )
        .map((e: ISpecial) => e.start_time)
        .map((e: Date) => dayjs(e).toDate())
    )
    return special
  })
  return uniqueSpecials
}

export function getValidEnding(amount: number, unit: string, isAllowZero?: boolean, isNoAmount?: boolean): string {
  const nullString: string = isAllowZero ? `0 ${unit}` : ''
  const unitWithEnding: string = `${unit}${amount > 1 ? 's' : ''}`
  return `${amount < 1 && !isNoAmount ? nullString : `${isNoAmount ? '' : amount} ${unitWithEnding}`}`
}

export function getDisplayPrice(price: number, price_type_ref_id: PriceType): string {
  switch (price_type_ref_id) {
    case PriceType.NORMAL:
      return `$${price}`
    case PriceType.OFF:
      return `${price}% Off`
    case PriceType.BOGO:
      return `BOGO`
    case PriceType.FREE:
      return `Free`
    default:
      return ''
  }
}
