import { useFormContext } from 'react-hook-form'
import { Input } from 'reactstrap'
import { Container, Row, Col } from 'reactstrap'
import Label from 'components/Label'
import ProvisionRow from './components/ProvisionRow'
import establishmentProvisions from './constant'
import styles from './establishmentFeatures.module.scss'

const EstablishmentFeatures = () => {
  const { register } = useFormContext()
  return (
    <Container>
      <Row className={styles.rowMarginBottom}>
        <Col className={styles.inputLeftCol} xs="12">
          <Label strong>Details</Label>
          <Input type="textarea" name="description" placeholder="Enter description" innerRef={register} />
        </Col>
      </Row>
      {establishmentProvisions.map((provision, index) => (
        <ProvisionRow key={`provision-${index}`} provision={provision} />
      ))}
    </Container>
  )
}

export default EstablishmentFeatures
