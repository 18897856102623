import { api, errorHandler } from 'Api'
import { IServerError } from 'Api/constants'
import { IBusinessInfo } from 'constants/interfaces'

export async function getGeneral(): Promise<IBusinessInfo | boolean> {
  try {
    const response = await api.get(`/information/general`)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function getTime(): Promise<IBusinessInfo | boolean> {
  try {
    const response = await api.get(`/information/time`)

    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function getFeatures(): Promise<IBusinessInfo | boolean> {
  try {
    const response = await api.get(`/information/features`)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function editGeneral(data: IBusinessInfo): Promise<any> {
  try {
    const response = await api.post(`/information/general`, data)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function editTime(data: IBusinessInfo): Promise<any> {
  try {
    const response = await api.post(`/information/time`, data)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function editFeatures(data: IBusinessInfo): Promise<any> {
  try {
    const response = await api.post(`/information/features`, data)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function getPresignedUrl(pictureName: string): Promise<any> {
  try {
    const response = await api.get(`/information/pre-signed/${pictureName}`)
    if (response.status === 200) {
      return response.data
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}

export async function postProfilePictureUrlOnS3(url: string, formData: any, type: string): Promise<any> {
  try {
    const response = await api.put(url, formData, {
      headers: {
        'Content-Type': type,
      },
    })

    if (response.status === 200) {
      return response
    }
    return false
  } catch (err) {
    const error: IServerError = err?.response?.data?.error ?? {}
    errorHandler(error)
    return false
  }
}
