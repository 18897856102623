export const IMAGE_SOURCE_URL = 'https://app.appyhourmobile.com/assets/img'

export enum WeekDayEnum {
  MONDAY = 'monday', // 1
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday', // 7 , 0
}
