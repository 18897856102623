import { useState } from 'react'
import cx from 'classnames'
import dayjs from 'dayjs'
import { useStore } from 'hooks/useStore'
import { deleteManySpecial } from 'Api/special'
import ConfirmModal from 'components/ConfirmModal'
import Icon from 'components/Icon'
import { getDealImage } from 'components/Pages/AnalyticsPage/utils'
import { IMAGE_SOURCE_URL } from 'constants/common'
import { PriceType } from 'constants/enums/special'
import { ISpecial } from 'constants/interfaces'
import { getValidArray } from 'utils/common'
import styles from './row.module.scss'

interface IRowProps {
  order: number
  special: ISpecial
  eventId?: number
}

function getDisplayPrice(price: number, price_type_ref_id: PriceType): string {
  switch (price_type_ref_id) {
    case PriceType.NORMAL:
      return `$${price}`
    case PriceType.OFF:
      return `${price}% Off`
    case PriceType.BOGO:
      return `BOGO`
    case PriceType.FREE:
      return `Free`
    default:
      return ''
  }
}

const Row = (props: IRowProps) => {
  const {
    special = {
      special_id: 0,
      price: 0,
      details: 0,
      price_type_ref_id: 0,
      deal_type_ref_id: 0,
      start_time: new Date(),
      end_time: new Date(),
      days: [],
    },
    eventId,
  } = props
  const { eventStore } = useStore()
  const { price, price_type_ref_id, details, deal_type_ref_id, start_time, end_time, days } = special
  const startTime = dayjs(start_time).toDate()
  const endTime = dayjs(end_time).toDate()
  const [isOpenModal, setIsOpenModal] = useState(false)

  async function handleDelete() {
    try {
      if (special?.special_id) {
        await deleteManySpecial(special.special_id)
        eventId && (await eventStore.fetchEventDetail(eventId))
        toggleModal()
      }
    } catch (err) {
      console.log(err)
    }
  }

  function toggleModal() {
    setIsOpenModal(!isOpenModal)
  }

  return (
    <>
      <div className={cx(styles.container, { [styles.inForm]: !!eventId })}>
        <div className={cx(styles.column, styles.dealType)}>
          <img className={styles.iconStyle} src={`${IMAGE_SOURCE_URL}/${getDealImage(deal_type_ref_id)}`} alt="logo" />
        </div>
        <div className={cx(styles.column)}>{getDisplayPrice(price, price_type_ref_id)}</div>
        <div className={cx(styles.column)}>{details}</div>
        <div className={cx(styles.column)}>
          {getValidArray(days)
            .map((e: Date) => dayjs(e).format('DD/MM'))
            .join(', ')}
        </div>
        <div className={cx(styles.column, styles.timeStyle)}>
          <div className={styles.timeBlock}>
            {`${dayjs(startTime).format('h:mm A')} - ${dayjs(endTime).format('h:mm A')}`}
          </div>
        </div>
        {!!eventId && (
          <div className={cx(styles.column)}>
            {<Icon onClick={toggleModal} icon="trash-alt" className={styles.trashIcon} />}
          </div>
        )}
      </div>
      <ConfirmModal
        isOpen={isOpenModal}
        onClickClose={toggleModal}
        onClickCancel={toggleModal}
        onClickAccept={handleDelete}
      />
    </>
  )
}

export default Row
