import cx from 'classnames'
import Button from 'components/Button'
import Icon, { IconGroupPrefix } from 'components/Icon'
import { EViewSwitcher } from '../../constant'
import styles from './styles.module.scss'

interface IViewSwitchProps {
  viewType: EViewSwitcher
  setViewType: React.Dispatch<React.SetStateAction<EViewSwitcher>>
}

const ViewSwitcher = (props: IViewSwitchProps) => {
  const { viewType, setViewType } = props

  return (
    <>
      <Button
        className={cx(styles.button, styles.gridViewButton, {
          [styles.highlightBorder]: viewType === EViewSwitcher.GRID_VIEW,
        })}
        onClick={() => setViewType(EViewSwitcher.GRID_VIEW)}
      >
        <Icon icon="border-all" group={IconGroupPrefix.LAICON} />
      </Button>
      <Button
        className={cx(styles.button, styles.tableViewButton, {
          [styles.highlightBorder]: viewType === EViewSwitcher.TABLE_VIEW,
        })}
        onClick={() => setViewType(EViewSwitcher.TABLE_VIEW)}
      >
        <Icon icon="th-list" group={IconGroupPrefix.LAICON} />
      </Button>
    </>
  )
}

export default ViewSwitcher
