const daysInWeek = [
  {
    content: 'Monday',
    openName: 'mon_open',
    closeName: 'mon_close',
  },
  {
    content: 'Tuesday',
    openName: 'tue_open',
    closeName: 'tue_close',
  },
  {
    content: 'Wednesday',
    openName: 'wed_open',
    closeName: 'wed_close',
  },
  {
    content: 'Thursday',
    openName: 'thu_open',
    closeName: 'thu_close',
  },
  {
    content: 'Friday',
    openName: 'fri_open',
    closeName: 'fri_close',
  },
  {
    content: 'Saturday',
    openName: 'sat_open',
    closeName: 'sat_close',
  },
  {
    content: 'Sunday',
    openName: 'sun_open',
    closeName: 'sun_close',
  },
]

export default daysInWeek
