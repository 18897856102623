import cx from 'classnames'
import { EStatusType } from 'components/Pages/CampaignPage/constant'
import styles from './styles.module.scss'

interface IDateContent {
  value: number
  percentChange: number
  error?: string
  status?: EStatusType
}

const DateContent = (props: IDateContent) => {
  const { value, percentChange, status } = props

  return (
    <div className={styles.valueRateGroup}>
      <span className={styles.value}>{status === EStatusType.PENDING_ACTIVATION ? value : '-'}</span>
      {status === EStatusType.PENDING_ACTIVATION && (
        <span
          className={cx(styles.rate, {
            [styles.increment]: percentChange >= 0,
            [styles.decrement]: percentChange < 0,
          })}
        >
          {percentChange >= 0 && '+'}
          {percentChange}%
        </span>
      )}
    </div>
  )
}

export default DateContent
