import cx from 'classnames'
import styles from './divider.module.scss'

interface IDividerProps {
  className?: string
}

const Divider = (props: IDividerProps) => {
  const { className = '' } = props
  return <div className={cx([styles.divider, className])} />
}

export default Divider
