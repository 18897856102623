import { useState } from 'react'
import cx from 'classnames'
import { Col, Row as RSRow } from 'reactstrap'
import Icon from 'components/Icon'
import Title from 'components/Title'
import { ISpecial } from 'constants/interfaces'
import { getValidArray } from 'utils/common'
import Row from './components/Row'
import SpecialForm from './components/SpecialForm'
import styles from './specialList.module.scss'

interface ISpecialListProps {
  specials?: ISpecial[]
  eventId?: number
}

const SpecialList = ({ specials, eventId }: ISpecialListProps) => {
  const [isOpenForm, openForm] = useState(false)
  function handleClick(e: React.MouseEvent) {
    e.preventDefault()
    openForm(true)
  }
  function toggleForm() {
    openForm(!isOpenForm)
  }
  return (
    <div>
      {!!!specials?.length && !!eventId && !isOpenForm && (
        <RSRow>
          <Col md={12}>
            <Title small>
              <span className={styles.subtext}>Would you like to add some specials to this event?</span>
              <span className={styles.link} onClick={handleClick}>
                Add special
              </span>
            </Title>
          </Col>
        </RSRow>
      )}
      {(!eventId || !!specials?.length) && (
        <RSRow>
          <Col md={12} className={cx(styles.specialWrapper, { [styles.noPading]: !!eventId })}>
            <RSRow>
              <Col md={12} className={styles.title}>
                <span>Special list</span>
              </Col>
            </RSRow>
            {getValidArray(specials).map((special: ISpecial, index: number) => (
              <Row eventId={eventId} order={index} special={special} key={special.special_id} />
            ))}
            {!specials?.length && <div className={styles.noSpecial}>'No specials'</div>}
          </Col>
        </RSRow>
      )}
      {!!specials?.length && !!eventId && !isOpenForm && (
        <RSRow>
          <Col md={12} className={styles.addMore}>
            <Title small onClick={toggleForm}>
              <Icon icon="plus" />
              Add more special
            </Title>
          </Col>
        </RSRow>
      )}
      {isOpenForm && <SpecialForm toggleForm={toggleForm} eventId={eventId} />}
    </div>
  )
}

export default SpecialList
